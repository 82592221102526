import { TTFunction } from 'tt-coms';
import { IActionInsert, IActionSelect, IActionUpdate, IAllTablesSelects, IBeaconInsert, IBeaconSelect, IBeaconUpdate, IDashActivityLogInsert, IDashStateLogInsert, IDeviceBehaviourInsert, IDeviceBehaviourSelect, IDeviceBehaviourUpdate, IDeviceSelect, IDeviceStateLogSelect, IEventInstanceLogSelect, IEventInstanceSelect, IEventSelect, ILinkOrganizationUserSelect, INotificationLogSelect, INotificationSelect, IOrganizationRoleSelect, IOrganizationRoleUserInviteSelect, IOrganizationSelect, IReportInsert, IReportSelect, IReportUpdate, ISiteInsert, ISiteSelect, ISiteUpdate, IUserSelect, IOrganizationRoleInsert, IDeviceActivityLogSelect, IDeviceInsert, IOrganizationRoleUpdate, IOrganizationRoleUserInviteInsert } from './beans_type';
import { QueryFilter } from './query_filter';

// Query types
export interface IPagination {
  page: number,
  count: number
}

export type SortOrder = 'ascending' | 'descending';

export interface IOrdering<T> {
  field: keyof T,
  direction: SortOrder
}

export interface IPaginatedResponse<T> {
  items: T[],
  pagination: {
    pages: number,
    count: number
  }
}

export type IDirectoryRole = IOrganizationRoleSelect & { userCount: number };
export type IDirectoryUser = IUserSelect & { roleCount: number };
export type IDirectoryUserInvite = IOrganizationRoleUserInviteSelect & { inviteType: string, inviteeContact: string };
export type IDirectorySite = ISiteSelect & { beaconCount: number, deviceBehaviourCount: number };
export type IDirectoryBeacon = IBeaconSelect & { siteCount: number };
export type IDirectoryDevice = IDeviceSelect & { deviceBehaviourCount: number };
export type IDirectoryDeviceBehaviour = IDeviceBehaviourSelect & { deviceCount: number, siteCount: number };
export type IDirectoryEvent = IEventSelect;
export type IDirectoryEventInstance = IEventInstanceSelect;
export type IDirectoryReport = IReportSelect;
export type IDirectoryAction = IActionSelect;
export type IDirectoryNotification = INotificationSelect & { userReceiveCount: number, userDismissedCount: number };
export type IDirectoryDeviceActivityLog = IDeviceActivityLogSelect & { user?: IUserSelect };

const excludedDeviceSyncResultTables = {
  linkOrganizationRoleUser: 'linkOrganizationRoleUser',
  deviceBehaviourInstances: 'deviceBehaviourInstances',
  deviceBehaviourInstanceLogs: 'deviceBehaviourInstanceLogs',
  deviceStockStatusLogs: 'deviceStockStatusLogs',
  eventInstances: 'eventInstances',
  eventInstanceLogs: 'eventInstanceLogs',
  organizations: 'organizations',
  linkDeviceUserLoggedInStates: 'linkDeviceUserLoggedInStates',
  linkOrganizationRoleUsers: 'linkOrganizationRoleUsers',
  notifications: 'notifications',
  notificationLogs: 'notificationLogs',
  organizationRoleUserInvite: 'organizationRoleUserInvite',
  dashActivityLogs: 'dashActivityLogs',
  dashStateLogs: 'dashStateLogs',
  dashSessions: 'dashSessions',
  deviceActivityLogs: 'deviceActivityLogs',
  deviceStateLogs: 'deviceStateLogs',
  authChallenges: 'authChallenges',
  actions: 'actions',
  reports: 'reports'
} as const;
export type TypeDeviceSyncResultExcludedTables = typeof excludedDeviceSyncResultTables[keyof typeof excludedDeviceSyncResultTables];
export const deviceSyncResultExcludedTables = Object.values(excludedDeviceSyncResultTables);
export type IDeviceSyncInput = IAllTablesSelects
export type IDeviceSyncResult = Omit<IAllTablesSelects, TypeDeviceSyncResultExcludedTables>

export type IPublicReport = IReportSelect & { events: (IEventSelect & { eventInstanceLogs: IEventInstanceLogSelect[] })[], users: IUserSelect[], sites: (ISiteSelect & { beacons: IBeaconSelect[] })[], devices: IDeviceSelect[], deviceActivityLogs: IDeviceActivityLogSelect[], deviceStateLogs: IDeviceStateLogSelect[] }

export type IProfileResult = {
  organizations: IOrganizationSelect[],
  organizationInvites: (IOrganizationRoleUserInviteSelect & { organization: IOrganizationSelect })[];
}

export type DeviceHistoryItem = {
  date: Date,
  chargeLevel: number,
  chargeState: "Charging" | "Discharging",
  lat: number,
  lng: number,
  acc: number,
}

export enum TimeFrame {
  Minute15 = '15m',
  Hour1 = '1h',
  Hour6 = '6h',
  Day1 = '1d',
  Day3 = '3d',
  Week1 = '1w',
  Week2 = '2w',
}

export type IDeviceUserPair = {
  device: IDeviceSelect,
  deviceStateLog: IDeviceStateLogSelect,
  user?: IUserSelect
}

export type IMapStateOrganization = {
  beacons?: IBeaconSelect[]
  devices?: IDeviceUserPair[]
}

export type IMapState = {
  organizations: IMapStateOrganization[]
}

export interface VigilContextClient {
  token: string,
}

function directoryFunction<T>() {
  return TTFunction<{ uuidOrganization: string, filters: QueryFilter<T>[], pagination: IPagination, ordering: IOrdering<T> }, IPaginatedResponse<T>>();
}

export class VigilFunctionsSchema {
  ping = TTFunction<{}, string>();

  // App Web Provider Functions
  providerOrganizationRoles = TTFunction<{ uuidOrganization: string }, IOrganizationRoleSelect[]>();
  providerUserRoles = TTFunction<{ uuidOrganization: string, uuidUser: string }, IOrganizationRoleSelect[]>();

  // Create Functions
  createOrganization = TTFunction<{ name: string }, IOrganizationSelect>();
  createOrganizationRole = TTFunction<{ uuidOrganization: string, data: IOrganizationRoleInsert }, IOrganizationRoleSelect>();
  createSite = TTFunction<{ uuidOrganization: string, data: ISiteInsert }, ISiteSelect>();
  createBeacon = TTFunction<{ uuidOrganization: string, data: IBeaconInsert }, IBeaconSelect>();
  createDevice = TTFunction<{ uuidOrganization: string, data: IDeviceInsert }, IDeviceSelect>();
  createDeviceBehaviour = TTFunction<{ uuidOrganization: string, data: IDeviceBehaviourInsert }, IDeviceBehaviourSelect>();
  createAction = TTFunction<{ uuidOrganization: string, data: IActionInsert }, IActionSelect>();
  createReport = TTFunction<{ uuidOrganization: string, data: IReportInsert }, IReportSelect>();
  createUserInvite = TTFunction<{ data: IOrganizationRoleUserInviteInsert }, IOrganizationRoleUserInviteSelect>();
  createDashStateLog = TTFunction<{ data: IDashStateLogInsert }, void>();
  createDashActivityLog = TTFunction<{ data: IDashActivityLogInsert }, void>();
  createDashSession = TTFunction<{ uuid: string, browserType: string, operatingSystem: string }, void>();

  // Link Functions
  linkSitesToBeacons = TTFunction<{ uuidOrganization: string, siteUuids: string[], beaconUuids: string[] }, void>();
  linkUsersToRoles = TTFunction<{ uuidOrganization: string, userUuids: string[], roleUuids: string[] }, void>();
  linkDeviceBehavioursToSites = TTFunction<{ uuidOrganization: string, deviceBehaviourUuids: string[], siteUuids: string[] }, void>();
  linkDeviceBehavioursToDevices = TTFunction<{ uuidOrganization: string, deviceBehaviourUuids: string[], deviceUuids: string[] }, void>();

  // Unlink Functions
  unlinkSitesFromBeacons = TTFunction<{ uuidOrganization: string, siteUuids: string[], beaconUuids: string[] }, void>();
  unlinkUsersFromRoles = TTFunction<{ uuidOrganization: string, userUuids: string[], roleUuids: string[] }, void>();
  unlinkDeviceBehavioursFromSites = TTFunction<{ uuidOrganization: string, deviceBehaviourUuids: string[], siteUuids: string[] }, void>();
  unlinkDeviceBehavioursFromDevices = TTFunction<{ uuidOrganization: string, deviceBehaviourUuids: string[], deviceUuids: string[] }, void>();

  // Find One Functions
  findOneOrganization = TTFunction<{ uuid: string }, IOrganizationSelect>();
  findOneUser = TTFunction<{ uuidOrganization: string, uuid: string }, IUserSelect>();
  findOneDevice = TTFunction<{ uuidOrganization: string, uuid: string }, IDeviceSelect>();
  findOneSite = TTFunction<{ uuidOrganization: string, uuid: string }, ISiteSelect>();
  findOneBeacon = TTFunction<{ uuidOrganization: string, uuid: string }, IBeaconSelect>();
  findOneEvent = TTFunction<{ uuidOrganization: string, uuid: string }, IEventSelect>();
  findOneAction = TTFunction<{ uuidOrganization: string, uuid: string }, IActionSelect>();
  findOneReport = TTFunction<{ uuidOrganization: string, uuid: string }, IReportSelect>();
  findOneDeviceBehaviour = TTFunction<{ uuidOrganization: string, uuid: string }, IDeviceBehaviourSelect>();
  findOneRole = TTFunction<{ uuidOrganization: string, uuid: string }, IOrganizationRoleSelect>();
  findOneNotification = TTFunction<{ uuidOrganization: string, uuid: string }, INotificationSelect>();

  // Find Many Functions
  findManyBeaconsLinkedToSite = TTFunction<{ uuidOrganization: string, uuidSite: string }, IBeaconSelect[]>();
  findManySitesLinkedToBeacon = TTFunction<{ uuidOrganization: string, uuidBeacon: string }, ISiteSelect[]>();
  findManyDeviceBehavioursLinkedToSite = TTFunction<{ uuidOrganization: string, uuidSite: string }, IDeviceBehaviourSelect[]>();
  findManyDeviceBehavioursLinkedToDevice = TTFunction<{ uuidOrganization: string, uuidDevice: string }, IDeviceBehaviourSelect[]>();
  findManyUsersLinkedToRole = TTFunction<{ uuidOrganization: string, uuidRole: string }, IUserSelect[]>();
  findManyRolesLinkedToUser = TTFunction<{ uuidOrganization: string, uuidUser: string }, IOrganizationRoleSelect[]>();
  findManyNotificationLogsLinkedToNotification = TTFunction<{ uuidOrganization: string, uuidNotification: string }, INotificationLogSelect[]>();

  findManyBeaconsUnlinkedFromSite = TTFunction<{ uuidOrganization: string, uuidSite: string }, IBeaconSelect[]>();
  findManySitesUnlinkedFromBeacon = TTFunction<{ uuidOrganization: string, uuidBeacon: string }, ISiteSelect[]>();
  findManyDeviceBehavioursUnlinkedFromSite = TTFunction<{ uuidOrganization: string, uuidSite: string }, IDeviceBehaviourSelect[]>();
  findManyDeviceBehavioursUnlinkedFromDevice = TTFunction<{ uuidOrganization: string, uuidDevice: string }, IDeviceBehaviourSelect[]>();
  findManyUsersUnlinkedFromRole = TTFunction<{ uuidOrganization: string, uuidRole: string }, IUserSelect[]>();
  findManyRolesUnlinkedFromUser = TTFunction<{ uuidOrganization: string, uuidUser: string }, IOrganizationRoleSelect[]>();

  findManyRoles = TTFunction<{ uuidOrganization: string }, IOrganizationRoleSelect[]>();
  findManyUsers = TTFunction<{ uuidOrganization: string }, IUserSelect[]>();
  findManySites = TTFunction<{ uuidOrganization: string }, ISiteSelect[]>();
  findManyDevices = TTFunction<{ uuidOrganization: string }, IDeviceSelect[]>();
  findManyBeaconsStartsWithName = TTFunction<{ uuidOrganization: string, nameStartsWith: string }, IBeaconSelect[]>();

  // Update Functions
  updateOrganizationRole = TTFunction<{ uuidOrganization: string, uuid: string, data: IOrganizationRoleUpdate }, IOrganizationRoleSelect>();
  updateSite = TTFunction<{ uuidOrganization: string, uuid: string, data: ISiteUpdate }, ISiteSelect>();
  updateBeacon = TTFunction<{ uuidOrganization: string, uuid: string, data: IBeaconUpdate }, IBeaconSelect>();
  updateAction = TTFunction<{ uuidOrganization: string, uuid: string, data: IActionUpdate }, IActionSelect>();
  updateReport = TTFunction<{ uuidOrganization: string, uuid: string, data: IReportUpdate }, IReportSelect>();
  updateDeviceBehaviour = TTFunction<{ uuidOrganization: string, uuid: string, data: IDeviceBehaviourUpdate }, IDeviceBehaviourSelect>();

  // Delete Functions
  deleteOrganizationRoles = TTFunction<{ uuidOrganization: string, uuids: string[] }, void>();
  deleteActions = TTFunction<{ uuidOrganization: string, uuids: string[] }, void>();
  deleteReports = TTFunction<{ uuidOrganization: string, uuids: string[] }, void>();
  deleteDeviceBehaviours = TTFunction<{ uuidOrganization: string, uuids: string[] }, void>();
  deleteSites = TTFunction<{ uuidOrganization: string, uuids: string[] }, void>();
  deleteBeacons = TTFunction<{ uuidOrganization: string, uuids: string[] }, void>();
  deleteDevices = TTFunction<{ uuidOrganization: string, uuids: string[] }, void>();
  deleteUserInvites = TTFunction<{ uuidOrganization: string, uuids: string[] }, void>();
  deleteUsers = TTFunction<{ uuidOrganization: string, uuids: string[] }, void>();

  // My User Functions
  userSignInRequest = TTFunction<{ target: { email?: string; mobile?: string } }, void>();
  userSignInConfirm = TTFunction<{ target: string, value: string }, { token: string, result: IUserSelect & { usersToOrganizations: (ILinkOrganizationUserSelect & { organization: IOrganizationSelect })[] } }>();
  userMobileRequest = TTFunction<{ mobile: string }, void>();
  userMobileConfirm = TTFunction<{ mobile: string; otp: string }, string>();
  userEmailRequest = TTFunction<{ email: string }, void>();
  userEmailConfirm = TTFunction<{ email: string; otp: string }, string>();
  userNameUpdate = TTFunction<{ firstName: string, lastName: string }, string>();
  userProfile = TTFunction<{}, IProfileResult>();
  userOrganizationRoleInviteAccept = TTFunction<{ uuidUserOrganizationRoleInvite: string }, void>();
  userOrganizationRoleInviteReject = TTFunction<{ uuidUserOrganizationRoleInvite: string }, void>();

  // Device Functions
  deviceRegistration = TTFunction<{ serial: string, imei0: string, imei1: string }, { token: string, device: IDeviceSelect }>();
  deviceUserLogin = TTFunction<{ uuidOrganization: string, idNumber: string }, IUserSelect>(); // TODO: In the future we can send back a token as well to have user permissions
  deviceUserCreate = TTFunction<{ uuidUser: string, firstName: string, lastName: string }, IUserSelect>();
  deviceSync = TTFunction<{ uuidOrganization: string, changes: IDeviceSyncInput, checkpointPullRequest: string }, { checkpointPullResponse: string, changes: IDeviceSyncResult }>();
  deviceStateLogs = TTFunction<{ filters: QueryFilter<IDeviceStateLogSelect>[], pagination: IPagination, ordering: IOrdering<IDeviceStateLogSelect> }, IPaginatedResponse<IDeviceStateLogSelect>>()
  deviceActivityLogs = TTFunction<{ filters: QueryFilter<IDirectoryDeviceActivityLog>[], pagination: IPagination, ordering: IOrdering<IDirectoryDeviceActivityLog> }, IPaginatedResponse<IDirectoryDeviceActivityLog>>()
  deviceHistory = TTFunction<{ uuidOrganization: string, uuidDevice: string, timeframe: TimeFrame }, { items: DeviceHistoryItem[] }>();

  // Notification Functions
  notificationsUnseen = TTFunction<{ uuidOrganization: string, uuidUser: string, createdAfter: Date }, (INotificationSelect & { notificationLogs: INotificationLogSelect[] })[]>();
  notificationsMarkReceived = TTFunction<{ uuidNotification: string, uuidUser: string }, void>();
  notificationsMarkDismissed = TTFunction<{ uuidNotification: string, uuidUser: string }, void>();

  // Overview - Map
  overviewMap = TTFunction<{ filter: { uuidsOrganizations: string[], beacons?: boolean, devices?: boolean, } }, IMapState>();

  // Directories
  directorySites = directoryFunction<IDirectorySite>();
  directoryBeacons = directoryFunction<IDirectoryBeacon>();
  directoryDevices = directoryFunction<IDirectoryDevice>();
  directoryDeviceBehaviours = directoryFunction<IDirectoryDeviceBehaviour>();
  directoryUsers = directoryFunction<IDirectoryUser>();
  directoryUserInvites = directoryFunction<IDirectoryUserInvite>();
  directoryRoles = directoryFunction<IDirectoryRole>();
  directoryEvents = directoryFunction<IDirectoryEvent>();
  directoryEventInstances = directoryFunction<IDirectoryEventInstance>();
  directoryReports = directoryFunction<IDirectoryReport>();
  directoryActions = directoryFunction<IDirectoryAction>();
  directoryNotifications = directoryFunction<IDirectoryNotification>();

  // Logs
  eventInstanceLogs = TTFunction<{ uuidEvent?: string, filters: QueryFilter<IEventInstanceLogSelect>[], pagination: IPagination, ordering: IOrdering<IEventInstanceLogSelect> }, IPaginatedResponse<IEventInstanceLogSelect>>()

  // Public
  publicReport = TTFunction<{ uuidReport: string, startDate: Date, endDate: Date }, IPublicReport>();
}

export const VigilFunctions = new VigilFunctionsSchema();