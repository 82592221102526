import React, { useContext, useState } from 'react';
import { ContextOrganization } from "../../providers/provider_organization";
import { IDirectorySite, Permissions } from 'vigil-datamodel';
import { ContextVigilClient } from '../../providers/provider_vigil_client';
import { IconPlusSolid, IconTrashSolid } from '../../components/icons';
import { useModal } from '../../hooks/use_modal';
import { ModalSiteCreate } from './func_site/modal_site_create';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../router/routes';
import { ContextRoles } from '../../providers/provider_roles';
import { TTActions } from 'tt-permissions';
import { SitesTable } from '../shared/sites_table';
import { useTableData } from '../../hooks/use_table_data';
import { ModalDeleteMany } from './modal_delete_many';

interface ScreenHomeSitesProps { }

export const ScreenHomeSites: React.FC<ScreenHomeSitesProps> = (props) => {
  const navigate = useNavigate()
  const organization = useContext(ContextOrganization);
  const contextRoles = useContext(ContextRoles);
  const vigil = useContext(ContextVigilClient);

  const { isOpen: isOpenModalCreate, toggle: toggleModalCreate } = useModal();
  const { isOpen: isOpenModalDelete, toggle: toggleModalDelete } = useModal();

  const [selectedSitesState, setSelectedSites] = useState<IDirectorySite[]>([]);

  const { data: sites, loading, error, paging, handleFiltersChange, handleSortingChange, handlePagingChange, forceRefetch } = useTableData<IDirectorySite>({
    fetchData: async ({ filters, sorting, paging }) => {
      if (!organization.data) return;
      const response = await vigil.functions.directorySites({
        uuidOrganization: organization.data.uuid,
        filters: filters,
        ordering: { field: sorting.id as any, direction: sorting.direction },
        pagination: { page: paging.currentPage, count: paging.itemsPerPage }
      });
      return { items: response.items, totalPages: response.pagination.pages, totalItems: response.pagination.count };
    },
    defaultSorting: { id: 'uuid', direction: 'descending' },
    refreshInterval: 5000,
    dependencies: [organization.data],
  });

  function navigateSite(selectedSite: IDirectorySite) {
    navigate(ROUTES.ROUTE_HOME_SITE.replace(':uuid', selectedSite.uuid))
  }

  function deleteSites(selectedSites: IDirectorySite[]) {
    setSelectedSites(selectedSites)
    toggleModalDelete();
  }

  async function _deleteSites(siteUuids: string[]) {
    if (!organization.data) return;
    await vigil.functions.deleteSites({
      uuidOrganization: organization.data.uuid,
      uuids: siteUuids
    })
  }

  return (
    <div className='p-4 h-full w-full'>
      <ModalSiteCreate isOpen={isOpenModalCreate} toggle={toggleModalCreate} onSubmit={forceRefetch} />
      <ModalDeleteMany
        isOpen={isOpenModalDelete}
        toggle={toggleModalDelete}
        type='sites'
        data={selectedSitesState.map(site => ({ uuid: site.uuid, label: site.name }))}
        onSubmit={forceRefetch}
        deleteCallback={async () => _deleteSites(selectedSitesState.map(site => site.uuid))}
        extraComponents={<li>Remove the site-beacon links, but not the beacons.</li>}
      />

      <SitesTable
        sites={sites}
        loading={loading}
        error={error}
        onSiteClick={navigateSite}
        onFiltersChange={handleFiltersChange}
        onSortingChange={handleSortingChange}
        tablePagingSelected={paging}
        onPagingChange={handlePagingChange}
        selectable={true}
        unSelectedActions={[
          {
            buttonChildren: <div className='flex justify-center items-center'><IconPlusSolid height='20px' className='mr-1' />add new site</div>,
            color: 'primary',
            disabled: !contextRoles.hasUserPermission(TTActions.C, Permissions.RSite),
            onClick: () => toggleModalCreate(),
          }
        ]}
        selectedActions={[
          {
            buttonChildren: <div className='flex justify-center items-center'><IconTrashSolid height='20px' />delete</div>,
            color: 'error',
            disabled: !contextRoles.hasUserPermission(TTActions.D, Permissions.RSite),
            onClick: (selectedItems) => deleteSites(selectedItems)
          }
        ]}
        hoverActions={[
          {
            buttonChildren: <div className='flex justify-center items-center'><IconTrashSolid height='20px' />delete</div>,
            color: 'error',
            disabled: !contextRoles.hasUserPermission(TTActions.D, Permissions.RSite),
            onClick: (selectedItems) => deleteSites(selectedItems)
          }
        ]}
      />
    </div>
  );
};