import React, { useContext, useState } from 'react';
import { ContextOrganization } from "../../providers/provider_organization";
import { IDirectoryRole, Permissions } from 'vigil-datamodel';
import { ContextVigilClient } from '../../providers/provider_vigil_client';
import { Table } from '../../components/table/table';
import { TextFilter } from '../../components/table/table_filters/table_filter_text';
import { TTuuid } from 'tt-uuid';
import { DateFilter } from '../../components/table/table_filters/table_filter_date';
import { IconPlusSolid, IconTrashSolid } from '../../components/icons';
import { useModal } from '../../hooks/use_modal';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../router/routes';
import { ModalRoleCreate } from './func_role/modal_role_create';
import { ContextRoles } from '../../providers/provider_roles';
import { TTActions } from 'tt-permissions';
import { useTableData } from '../../hooks/use_table_data';
import { ModalDeleteMany } from './modal_delete_many';


interface ScreenHomeRolesProps { }

export const ScreenHomeRoles: React.FC<ScreenHomeRolesProps> = (props) => {
  const organization = useContext(ContextOrganization);
  const vigil = useContext(ContextVigilClient);
  const contextRoles = useContext(ContextRoles);
  const navigate = useNavigate()

  const { isOpen: isOpenModalCreate, toggle: toggleModalCreate } = useModal();
  const { isOpen: isOpenModalDelete, toggle: toggleModalDelete } = useModal();

  const [selectedRolesState, setSelectedRoles] = useState<IDirectoryRole[]>([]);

  const { data: roles, loading, error, filters, sorting, paging, handleFiltersChange, handleSortingChange, handlePagingChange, forceRefetch } = useTableData<IDirectoryRole>({
    fetchData: async ({ filters, sorting, paging }) => {
      if (!organization.data) return;
      const response = await vigil.functions.directoryRoles({
        uuidOrganization: organization.data.uuid,
        filters: filters,
        ordering: { field: sorting.id as any, direction: sorting.direction },
        pagination: { page: paging.currentPage, count: paging.itemsPerPage }
      });
      return { items: response.items, totalPages: response.pagination.pages, totalItems: response.pagination.count };
    },
    defaultSorting: { id: 'uuid', direction: 'descending' },
    refreshInterval: 5000,
    dependencies: [organization.data],
  });

  function navigateRole(selectedRole: IDirectoryRole) {
    navigate(ROUTES.ROUTE_HOME_ROLE.replace(':uuid', selectedRole.uuid))
  }

  function deleteRoles(selectedRoles: IDirectoryRole[]) {
    setSelectedRoles(selectedRoles)
    toggleModalDelete();
  }

  async function _deleteRoles(roleUuids: string[]) {
    if (!organization.data) return;
    await vigil.functions.deleteOrganizationRoles({
      uuidOrganization: organization.data.uuid,
      uuids: roleUuids
    })
  }

  return (
    <div className='p-4 h-full w-full'>
      <ModalRoleCreate isOpen={isOpenModalCreate} toggle={toggleModalCreate} onSubmit={forceRefetch} />
      <ModalDeleteMany
        isOpen={isOpenModalDelete}
        toggle={toggleModalDelete}
        type='organizationRoles'
        data={selectedRolesState.map(role => ({ uuid: role.uuid, label: role.name }))}
        onSubmit={forceRefetch}
        extraComponents={<li>Remove the role-user links, but not the users.</li>}
        deleteCallback={async () => _deleteRoles(selectedRolesState.map(role => role.uuid))}
      />

      <Table<IDirectoryRole>
        className='h-full flex flex-col drop-shadow'
        name='Roles'
        data={roles}
        selectable={true}
        loading={loading}
        error={error}
        columns={[
          {
            id: 'name',
            header: 'Name',
            sortable: true,
            value(item) {
              return (
                <div className='hover:underline cursor-pointer text-blue-500' onClick={() => navigateRole(item)}>
                  {item.name}
                </div>
              );
            },
          },
          {
            id: 'userCount',
            header: 'Users Linked',
            sortable: true,
            value(item) {
              return <div>{item.userCount}</div>;
            },
          },
          {
            id: 'uuid',
            header: 'Date Created',
            sortable: true,
            value(item) {
              const decodedUuid = TTuuid.decodeCuuid(item.uuid);
              return <div>{decodedUuid.time.toLocaleDateString() + " " + decodedUuid.time.toLocaleTimeString()}</div>;
            },
          },
          {
            id: 'changeStamp',
            header: 'Last Updated',
            sortable: true,
            value(item) {
              const decodedUuid = TTuuid.decodeCuuid(item.changeStamp);
              return <div>{decodedUuid.time.toLocaleDateString() + " " + decodedUuid.time.toLocaleTimeString()}</div>;
            },
          },
        ]}
        filtersOptions={[
          new TextFilter('name', 'Name', 'contains'),
          new DateFilter('uuid', 'Date Created', 'between'),
          new DateFilter('changeStamp', 'Last Updated', 'between'),
        ]}
        filtersSelected={filters}
        onFiltersChange={handleFiltersChange}

        sortingOptions={null}
        sortingSelected={sorting}
        onSortingChange={handleSortingChange}

        pagingOptions={null}
        pagingSelected={paging}
        onPagingChange={handlePagingChange}

        unSelectedActions={[
          {
            buttonChildren: <div className='flex justify-center items-center'><IconPlusSolid height='20px' className='mr-1' />add new role</div>,
            color: 'primary',
            disabled: !contextRoles.hasUserPermission(TTActions.C, Permissions.RRole),
            onClick: () => toggleModalCreate(),
          }
        ]}
        selectedActions={[
          {
            buttonChildren: <div className='flex justify-center items-center'><IconTrashSolid height='20px' />delete</div>,
            color: 'error',
            disabled: !contextRoles.hasUserPermission(TTActions.D, Permissions.RRole),
            onClick: (selectedItems) => deleteRoles(selectedItems)
          }
        ]}
        hoverActions={[
          {
            buttonChildren: <div className='flex justify-center items-center'><IconTrashSolid height='20px' />delete</div>,
            color: 'error',
            disabled: !contextRoles.hasUserPermission(TTActions.D, Permissions.RRole),
            onClick: (selectedItems) => deleteRoles(selectedItems)
          }
        ]}
      />
    </div >
  );
};
