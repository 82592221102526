import React, { useContext, useState } from 'react';
import { ContextOrganization } from "../../providers/provider_organization";
import { IDirectoryBeacon, Permissions } from 'vigil-datamodel';
import { ContextVigilClient } from '../../providers/provider_vigil_client';
import { Table } from '../../components/table/table';
import { TextFilter } from '../../components/table/table_filters/table_filter_text';
import { TTuuid } from 'tt-uuid';
import { DateFilter } from '../../components/table/table_filters/table_filter_date';
import { IconPlusSolid, IconTrashSolid } from '../../components/icons';
import { ModalBeaconCreate } from './func_beacon/modal_beacon_create';
import { useModal } from '../../hooks/use_modal';
import { useNavigate } from 'react-router-dom';
import { ContextRoles } from '../../providers/provider_roles';
import { TTActions } from 'tt-permissions';
import { useTableData } from '../../hooks/use_table_data';
import { ROUTES } from '../../router/routes';
import { ModalDeleteMany } from './modal_delete_many';

interface ScreenHomeBeaconsProps { }

export const ScreenHomeBeacons: React.FC<ScreenHomeBeaconsProps> = (props) => {
  const navigate = useNavigate()
  const organization = useContext(ContextOrganization);
  const contextRoles = useContext(ContextRoles);
  const vigil = useContext(ContextVigilClient);

  const { isOpen: isOpenModalCreate, toggle: toggleModalCreate } = useModal();
  const { isOpen: isOpenModalDelete, toggle: toggleModalDelete } = useModal();

  const [selectedBeaconsState, setSelectedBeacons] = useState<IDirectoryBeacon[]>([]);

  const { data: beacons, loading, error, filters, sorting, paging, handleFiltersChange, handleSortingChange, handlePagingChange, forceRefetch } = useTableData<IDirectoryBeacon>({
    fetchData: async ({ filters, sorting, paging }) => {
      if (!organization.data) return;
      const response = await vigil.functions.directoryBeacons({
        uuidOrganization: organization.data.uuid,
        filters: filters,
        ordering: { field: sorting.id as any, direction: sorting.direction },
        pagination: { page: paging.currentPage, count: paging.itemsPerPage }
      });
      return { items: response.items, totalPages: response.pagination.pages, totalItems: response.pagination.count };
    },
    defaultSorting: { id: 'uuid', direction: 'descending' },
    refreshInterval: 5000,
    dependencies: [organization.data],
  });

  function navigateBeacon(selectedBeacon: IDirectoryBeacon) {
    navigate(ROUTES.ROUTE_HOME_BEACON.replace(':uuid', selectedBeacon.uuid))
  }

  function deleteBeacons(selectedBeacons: IDirectoryBeacon[]) {
    setSelectedBeacons(selectedBeacons)
    toggleModalDelete();
  }

  async function _deleteBeacons(beaconUuids: string[]) {
    if (!organization.data) return;
    await vigil.functions.deleteBeacons({
      uuidOrganization: organization.data.uuid,
      uuids: beaconUuids
    })
  }

  return (
    <div className='p-4 h-full w-full'>
      <ModalBeaconCreate isOpen={isOpenModalCreate} toggle={toggleModalCreate} onSubmit={forceRefetch} />
      <ModalDeleteMany
        isOpen={isOpenModalDelete}
        toggle={toggleModalDelete}
        type='beacons'
        data={selectedBeaconsState.map(beacon => ({ uuid: beacon.uuid, label: beacon.name }))}
        onSubmit={forceRefetch}
        extraComponents={<li>Remove the beacon-site links, but not the sites.</li>}
        deleteCallback={async () => _deleteBeacons(selectedBeaconsState.map(beacon => beacon.uuid))}
      />

      <Table<IDirectoryBeacon>
        className='h-full flex flex-col drop-shadow'
        name='Beacons'
        data={beacons}
        selectable={true}
        loading={loading}
        error={error}
        columns={[
          {
            id: 'name',
            header: 'Name',
            sortable: true,
            value(item) {
              return (
                <div className='hover:underline cursor-pointer text-blue-500' onClick={() => navigateBeacon(item)}>
                  {item.name}
                </div>
              );
            },
          },
          {
            id: 'siteCount',
            header: 'Sites Linked',
            sortable: true,
            value(item) {
              return <div>{item.siteCount}</div>;
            },
          },
          {
            id: 'type',
            header: 'Type',
            sortable: true,
            value(item) {
              return <div>{item.type}</div>;
            },
          },
          {
            id: 'uuid',
            header: 'Date Created',
            sortable: true,
            value(item) {
              const decodedUuid = TTuuid.decodeCuuid(item.uuid);
              return <div>{decodedUuid.time.toLocaleDateString() + " " + decodedUuid.time.toLocaleTimeString()}</div>;
            },
          },
          {
            id: 'changeStamp',
            header: 'Last Updated',
            sortable: true,
            value(item) {
              const decodedUuid = TTuuid.decodeCuuid(item.changeStamp);
              return <div>{decodedUuid.time.toLocaleDateString() + " " + decodedUuid.time.toLocaleTimeString()}</div>;
            },
          },
        ]}
        filtersOptions={[
          new TextFilter('name', 'Name', 'contains'),
          new DateFilter('uuid', 'Date Created', 'between'),
          new DateFilter('changeStamp', 'Last Updated', 'between'),
        ]}
        filtersSelected={filters}
        onFiltersChange={handleFiltersChange}

        sortingOptions={null}
        sortingSelected={sorting}
        onSortingChange={handleSortingChange}

        pagingOptions={null}
        pagingSelected={paging}
        onPagingChange={handlePagingChange}

        unSelectedActions={[
          {
            buttonChildren: <div className='flex justify-center items-center'><IconPlusSolid height='20px' className='mr-1' />add new beacon</div>,
            color: 'primary',
            disabled: !contextRoles.hasUserPermission(TTActions.C, Permissions.RBeacon),
            onClick: () => toggleModalCreate(),
          }
        ]}
        selectedActions={[
          {
            buttonChildren: <div className='flex justify-center items-center'><IconTrashSolid height='20px' />delete</div>,
            color: 'error',
            disabled: !contextRoles.hasUserPermission(TTActions.D, Permissions.RBeacon),
            onClick: (selectedItems) => deleteBeacons(selectedItems)
          }
        ]}
        hoverActions={[
          {
            buttonChildren: <div className='flex justify-center items-center'><IconTrashSolid height='20px' />delete</div>,
            color: 'error',
            disabled: !contextRoles.hasUserPermission(TTActions.D, Permissions.RBeacon),
            onClick: (selectedItems) => deleteBeacons(selectedItems)
          }
        ]}
      />
    </div >
  );
};
