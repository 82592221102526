import { useContext, useEffect, useState } from "react";
import { InputButton } from "../../../components/input_button";
import { ContextVigilClient } from "../../../providers/provider_vigil_client";
import { Modal, ModalProps } from "../../../components/modal";
import { IOrganizationRoleSelect, IUserSelect } from "vigil-datamodel";
import { StatusAlert } from "../../../components/status_alert";
import { ContextOrganization } from "../../../providers/provider_organization";
import { IconXCircleSolid } from "../../../components/icons";
import { InputSearchDropdown, OptionSearchDropdown } from "../../../components/input_search_dropdown";
import { StatusLoading } from "../../../components/status_loading";

interface ModalUserRolesCreateProps extends ModalProps {
  uuidUser: string;
  onSubmit?: () => Promise<void>;
}

export const ModalUserRolesCreate: React.FC<ModalUserRolesCreateProps> = (props) => {
  const organization = useContext(ContextOrganization);
  const vigil = useContext(ContextVigilClient);

  const [stateError, setError] = useState<string>('');
  const [stateUserLoading, setUserLoading] = useState(true);
  const [stateUser, setUser] = useState<IUserSelect>();

  const [stateRolesLoading, setRolesLoading] = useState(true);
  const [stateLinkedRoles, setLinkedRoles] = useState<IOrganizationRoleSelect[]>([]);
  const [stateUnlinkedRoles, setUnlinkedRoles] = useState<IOrganizationRoleSelect[]>([]);
  const [stateNewRoles, setNewRoles] = useState<IOrganizationRoleSelect[]>([]);
  const [stateRoleOptions, setRoleOptions] = useState<OptionSearchDropdown[]>([]);

  useEffect(() => { fetchUser(props.uuidUser); }, [props.uuidUser]);
  useEffect(() => { resetCalls() }, [props.isOpen]);

  /* Functions */
  function resetCalls() {
    setNewRoles([]);
    fetchLinkedRoles();
    fetchUnlinkedRoles();
  }

  function clearNewRole(role: IOrganizationRoleSelect) {
    const newRoles = stateNewRoles.filter((_role) => { return _role.uuid != role.uuid });
    setNewRoles(newRoles);

    const unlinkedRoles = stateUnlinkedRoles;
    unlinkedRoles.push(role);
    setUnlinkedRoles(unlinkedRoles);

    const roleOptions = stateRoleOptions;
    roleOptions.push({ label: role.name, value: role.uuid });
    setRoleOptions(roleOptions);
  }

  function addNewRole(uuidRole: string) {
    const role: IOrganizationRoleSelect = stateUnlinkedRoles.filter((_role) => { return _role.uuid == uuidRole })[0];

    const newRoles = stateNewRoles;
    newRoles.push(role);
    setNewRoles(newRoles);

    const unlinkedRoles = stateUnlinkedRoles.filter((_role) => { return _role.uuid != uuidRole });
    setUnlinkedRoles(unlinkedRoles);

    const roleOptions = stateRoleOptions.filter((option) => { return option.value != uuidRole });
    setRoleOptions(roleOptions);
  }

  /* DB Functions */
  async function fetchUser(uuid: string) {
    try {
      if (!organization.data) return;
      setUserLoading(true);
      const user = await vigil.functions.findOneUser({ uuidOrganization: organization.data.uuid, uuid: uuid });
      setUser(user);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setUserLoading(false);
    }
  }

  async function fetchLinkedRoles() {
    try {
      if (!organization.data) return;
      setRolesLoading(true);
      const roles = await vigil.functions.findManyRolesLinkedToUser({ uuidOrganization: organization.data.uuid, uuidUser: props.uuidUser });
      setLinkedRoles(roles);
      setRolesLoading(false);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setRolesLoading(false);
    }
  }

  async function fetchUnlinkedRoles() {
    try {
      if (!organization.data) return;
      setRolesLoading(true);
      const unlinkedRoles = await vigil.functions.findManyRolesUnlinkedFromUser({ uuidOrganization: organization.data.uuid, uuidUser: props.uuidUser });
      setUnlinkedRoles(unlinkedRoles);
      const roleOptions: OptionSearchDropdown[] = unlinkedRoles.map((role) => {
        return { label: role.name, value: role.uuid }
      });
      setRoleOptions(roleOptions);
      setRolesLoading(false);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setRolesLoading(false);
    }
  }

  async function linkRolesToUser() {
    try {
      if (!organization.data || !stateUser) return;
      setRolesLoading(true);
      const uuidRoles = stateNewRoles.map((role) => { return role.uuid });
      await vigil.functions.linkUsersToRoles({ uuidOrganization: organization.data.uuid, userUuids: [stateUser.uuid], roleUuids: uuidRoles });
      props.onSubmit && await props.onSubmit();
      props.toggle();
      resetCalls();
    } catch (error: any) {
      setError(error.message);
    } finally {
      setRolesLoading(false);
    }
  }

  if (stateUserLoading || stateRolesLoading || !stateUser) {
    return (
      <Modal isOpen={props.isOpen} toggle={props.toggle} closeOnBackgroundClick={false} className="w-92">
        <StatusLoading />
      </Modal>
    )
  }

  if (stateUnlinkedRoles.length == 0 && stateNewRoles.length == 0) {
    return (
      <Modal isOpen={props.isOpen} toggle={props.toggle} closeOnBackgroundClick={false} className="w-92">
        <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2" onClick={() => props.toggle()}>✕</button>
        <h3 className="font-bold text-lg pb-2">Need to add roles first</h3>
        <div>Please add roles to link them to this user</div>
      </Modal>
    )
  }

  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle} closeOnBackgroundClick={false} className="w-192">
      <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2" onClick={() => props.toggle()}>✕</button>
      <h3 className="font-bold text-lg pb-2">Link roles to {`${stateUser.firstName} ${stateUser.lastName}`}</h3>
      <div className="flex flex-wrap">
        {stateLinkedRoles.map((role, index) => {
          return (
            <div key={index} className='flex items-center justify-center bg-primary-content rounded-3xl h-8 text-sm px-4 mr-2 mb-1'>
              {role.name}
            </div>
          )
        })}
        {stateNewRoles.map((role, index) => {
          return (
            <div key={index} className='flex items-center justify-center bg-secondary-content rounded-3xl h-8 text-sm px-4 mr-2 mb-1'>
              <div>{role.name}</div>
              <button onClick={() => { clearNewRole(role) }}>
                <IconXCircleSolid className='ml-2' style={{ height: '18px' }} />
              </button>
            </div>
          )
        })}
      </div>
      <div className="flex">
        <InputSearchDropdown options={stateRoleOptions} labelText={"Link new Role"} errors={[]} onSelect={(val) => addNewRole(val.toString())} ></InputSearchDropdown>
      </div>
      {stateError && <StatusAlert type='alert-error' message={stateError} />}
      <div className="flex justify-end pt-4">
        <InputButton text='Link Roles' loading={false} disabled={stateNewRoles.length == 0} type='btn-primary' onClick={async () => {
          await linkRolesToUser();
        }}></InputButton>
      </div>
    </Modal>
  )
}