import { useContext, useState } from "react";
import { InputButton } from "../../../components/input_button";
import { ContextVigilClient } from "../../../providers/provider_vigil_client";
import { Modal, ModalProps } from "../../../components/modal";
import { ContextOrganization } from "../../../providers/provider_organization";
import { StatusAlert } from "../../../components/status_alert";

interface ModalUserRolesDeleteProps extends ModalProps {
  uuidUser: string;
  uuidRoles: string[];
  onSubmit?: () => Promise<void>;
}

export const ModalUserRolesDelete: React.FC<ModalUserRolesDeleteProps> = (props) => {
  const organization = useContext(ContextOrganization);
  const vigil = useContext(ContextVigilClient);

  const [stateError, setError] = useState<string>('');

  /* DB Functions */
  async function deleteLinks() {
    try {
      if (!organization.data) return;
      await vigil.functions.unlinkUsersFromRoles({ uuidOrganization: organization.data.uuid, userUuids: [props.uuidUser], roleUuids: props.uuidRoles });
      props.onSubmit && await props.onSubmit();
      props.toggle();
    } catch (error: any) {
      setError(error.message);
    }
  }

  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle} closeOnBackgroundClick={false} className="w-128">
      <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2" onClick={() => props.toggle()}>✕</button>
      <h3 className="font-bold text-lg pb-2">{props.uuidRoles.length > 1 ? 'Unlink Roles' : 'Unlink Role'}</h3>
      <div>Are you sure you want to unlink the selected {props.uuidRoles.length > 1 ? 'roles' : 'role'}? This will:</div>
      <ul className="list-disc list-inside">
        <li>Remove the user-role links, but not the roles or user.</li>
      </ul>
      {stateError && <StatusAlert type='alert-error' message={stateError} />}
      <div className="flex justify-end pt-4">
        <InputButton text='Unlink' loading={false} disabled={false} type='btn-error' onClick={async () => await deleteLinks()}></InputButton>
      </div>
    </Modal>
  )
}