import { Behaviour } from "../base/config_sdk_behaviour";

export const BehaviourSignOut: Behaviour = {
  config: {
    id: "user_sign_out_at",
    name: "User Sign Out At",
    description: "Behaviour to sign a user out, if signed in at a certain time",
    parameters: [
      {
        id: "start_cron",
        name: "Start Time",
        description: "From when should the user be signed out",
        required: true,
        type: "cron"
      },
      {
        id: "duration_hours",
        name: "Duration (hours)",
        description: "For how long will we check if the user is signed in",
        required: true,
        type: "numberInt"
      },
    ],
    screens: [],
    canLinkTo: ["device", "site"]
  },
  device: {
    tick: async (context) => {
      // Destructure context
      const { vigil, device, behaviour } = context;

      // If user is signed out, skip tick
      if (!await device.getUser()) return

      // If user should not be signed in, skip tick
      const now = new Date();
      const start_cron = behaviour.getParameterValue<string>("start_cron")!;
      const duration_hours = behaviour.getParameterValue<number>("duration_hours")!;
      const timeslot = vigil.getTimeslotFromCron(start_cron, duration_hours * 60 * 60 * 1000);
      const behaviourInstance = await behaviour.getInstance(timeslot.start, timeslot.end, now)

      // If there is no active instance and there should not be one, exit early
      if (!behaviourInstance) return

      // If user is signed in, sign out
      if (await device.getUser()) {
        await device.signOutUser()

        // Create log
        await behaviourInstance.createLog({ msg: "Sign out" }, ["sign_out"])
      }
    },
  },
  server: {
    tick: async (context) => {
      // Destructure context
      const { vigil, device, behaviour } = context;

      // If user is signed out, skip tick
      if (!await device.getUser()) return

      // If user should not be signed in, skip tick
      const now = new Date();
      const start_cron = behaviour.getParameterValue<string>("start_cron")!;
      const duration_hours = behaviour.getParameterValue<number>("duration_hours")!;
      const timeslot = vigil.getTimeslotFromCron(start_cron, duration_hours * 60 * 60 * 1000);
      const behaviourInstance = await behaviour.getInstance(timeslot.start, timeslot.end, now)

      // If there is no active instance and there should not be one, exit early
      if (!behaviourInstance) return

      // If user has not signed out for 10 minutes, send notification
      const lastLog = await behaviourInstance.getServerLastCreatedLog(["sign_out"])
      if (now > new Date(timeslot.start.valueOf() + 10 * 60 * 1000) && (!lastLog || now > new Date(lastLog.datetime.valueOf() + 10 * 60 * 1000))) {
        // Create log
        await behaviourInstance.createLog({ msg: "Sign out missed" }, ["sign_out"])
      }
    }
  }
}
