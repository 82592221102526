import { lazy } from "react";
import { ROUTES } from "../routes";
import { FullPageError } from "../../components/full_page_error";
import { FullPageSuspend } from "../../components/full_page_suspend";
import { RouteObject } from "react-router-dom";

const ScreenPublicBeacons = lazy(() => import('../../screens/public/public_beacons'));

export const RouterPublicBeacons: RouteObject[] = [{
  path: ROUTES.ROUTE_PUBLIC_BEACONS,
  errorElement: <FullPageError />,
  element: <FullPageSuspend><ScreenPublicBeacons /></FullPageSuspend>
}];