import React, { useContext, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { IBeaconSelect, ISiteSelect } from 'vigil-datamodel';
import { ContextVigilClient } from '../../providers/provider_vigil_client';
import { ROUTES } from '../../router/routes';
import { InputButton } from '../../components/input_button';
import { IconPlusSolid, IconLinkSolid } from '../../components/icons';
import { useModal } from '../../hooks/use_modal';
import { ContextOrganization } from '../../providers/provider_organization';
import { StatusAlert } from '../../components/status_alert';
import { FullPageLoader } from '../../components/full_page_loader';
import { TTuuid } from 'tt-uuid';
import { useCaller } from '../../hooks/use_caller';
import { ModalDeviceBehaviourCreate } from './func_device_behaviour/modal_device_behaviour_create';
import { ModalSiteDeviceBehavioursCreate } from './func_device_behaviour_site/modal_site_device_behaviours_create';
import { ModalSiteDeviceBehavioursDelete } from './func_device_behaviour_site/modal_site_device_behaviours_delete';

export const ScreenHomeSiteDeviceBehaviours: React.FC = () => {
  const navigate = useNavigate()
  const organization = useContext(ContextOrganization);
  const vigil = useContext(ContextVigilClient);

  const [selectedBehaviourToUnlink, setSelectedBehaviourToUnlink] = useState<string[]>([]);

  const { site, fetchSite, beacons, fetchBeacons } = useOutletContext<{
    site: ISiteSelect,
    fetchSite: (uuid: string) => void,
    beacons: IBeaconSelect[],
    fetchBeacons: (uuidSite: string) => void
  }>();

  const { isOpen: isOpenModalCreate, toggle: toggleModalCreate } = useModal();
  const { isOpen: isOpenModalLink, toggle: toggleModalLink } = useModal();
  const { isOpen: isOpenModalUnlink, toggle: toggleModalUnlink } = useModal();

  const { result: behaviours, loading, error, call } = useCaller({
    callback: async () => {
      if (!organization.data) return;
      return await vigil.functions.findManyDeviceBehavioursLinkedToSite({
        uuidOrganization: organization.data.uuid,
        uuidSite: site.uuid
      });
    },
    dependencies: { normal: [organization.data], background: [] }
  });

  if (loading || !behaviours) return <FullPageLoader />;
  if (error) return <div className='px-8 py-4 h-full w-full'><StatusAlert message={error} type='alert-error' /></div>;

  return (
    <div className='pt-2 w-full flex flex-col h-full'>
      <ModalDeviceBehaviourCreate
        isOpen={isOpenModalCreate}
        toggle={toggleModalCreate}
        onSubmit={() => call()}
        uuidSite={site.uuid}
      />
      <ModalSiteDeviceBehavioursCreate
        isOpen={isOpenModalLink}
        toggle={toggleModalLink}
        uuidSite={site.uuid}
        onSubmit={() => call()}
      />
      <ModalSiteDeviceBehavioursDelete
        isOpen={isOpenModalUnlink}
        toggle={toggleModalUnlink}
        uuidSite={site.uuid}
        uuidDeviceBehaviours={selectedBehaviourToUnlink}
        onSubmit={() => call()}
      />

      <div className='rounded-t-xl bg-base-300 p-2 overflow-x-auto'>
        <div className='flex flex-col md:flex-row md:items-center space-y-2 md:space-y-0'>
          <div className='font-semibold flex flex-grow'>Device Behaviours</div>
          <div className='flex space-x-2'>
            <InputButton
              text='Create Device Behaviour'
              before={<IconPlusSolid className='h-5 mr-1' />}
              type='btn-primary'
              size='btn-sm'
              className='flex-1 md:flex-none'
              onClick={toggleModalCreate}
            />
            <InputButton
              text='Link Device Behaviour'
              before={<IconLinkSolid className='h-5 mr-1' />}
              type='btn-secondary'
              size='btn-sm'
              className='flex-1 md:flex-none'
              onClick={toggleModalLink}
            />
          </div>
        </div>
      </div>

      <div className='rounded-b-xl bg-base-200 p-2 flex-grow'>
        <div className="overflow-y-auto" style={{ maxHeight: "calc(100vh - 230px)" }}>
          {behaviours.map((behaviour) => (
            <div key={behaviour.uuid} className="card bg-base-100 mb-2 p-3 drop-shadow hover:cursor-pointer hover:bg-base-300" onClick={() => navigate(`${ROUTES.ROUTE_HOME_DEVICE_BEHAVIOUR.replace(':uuid', behaviour.uuid)}`)}>
              <div className="flex flex-col md:flex-row md:items-center space-y-2 md:space-y-0 ">
                <div className="flex flex-col flex-grow" >
                  <div className="font-semibold text-lg">{behaviour.name}</div>
                  <div className="text-sm opacity-70">ID: {behaviour.id}</div>
                </div>

                <div className="flex items-center space-x-4">
                  <div className="flex flex-col text-sm md:text-right">
                    <div>Created: {TTuuid.decodeCuuid(behaviour.uuid).time.toLocaleDateString() + " " + TTuuid.decodeCuuid(behaviour.uuid).time.toLocaleTimeString()}</div>
                    <div>Updated: {TTuuid.decodeCuuid(behaviour.changeStamp).time.toLocaleDateString() + " " + TTuuid.decodeCuuid(behaviour.changeStamp).time.toLocaleTimeString()}</div>
                  </div>
                  <button
                    className="btn btn-sm btn-warning"
                    onClick={(e) => {
                      setSelectedBehaviourToUnlink([behaviour.uuid]);
                      e.stopPropagation();
                      toggleModalUnlink();
                    }}
                  >
                    Unlink
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>

        {behaviours && behaviours.length === 0 && (
          <div className="text-center p-4 opacity-50">
            No device behaviours linked
          </div>
        )}
      </div>
    </div>
  );
};