import { useEffect, useRef } from 'react';

/**
 * Custom hook to set up an interval that calls a callback function periodically.
 * 
 * @param callback - The function to be called at each interval
 * @param delay - The interval delay in milliseconds between each callback invocation, or null to pause
 * @param immediate - Whether to call the callback immediately on mount before the first interval
 * 
 * The hook handles:
 * - Storing the latest callback reference to avoid stale closures
 * - Setting up and cleaning up the interval timer
 * - Optional immediate execution on mount
 * - Proper cleanup on unmount
 * - Pausing when delay is null
 */
export function useInterval(callback: () => void, delay: number | null, immediate?: boolean) {
  const savedCallback = useRef<() => void>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (delay === null || delay <= 0) return;
    if (immediate) savedCallback.current?.();

    function tick() {
      savedCallback.current?.();
    }
    const id = setInterval(tick, delay);
    return () => clearInterval(id);
  }, [delay, immediate]);
}
