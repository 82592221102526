import { useContext, useState } from "react";
import { VALIDATORS, validate } from "../../../validation";
import { InputText } from "../../../components/input_text";
import { ContextVigilClient } from "../../../providers/provider_vigil_client";
import { ContextOrganization } from "../../../providers/provider_organization";
import { Modal, ModalProps } from "../../../components/modal";
import { StatusAlert } from "../../../components/status_alert";
import { InputSelect } from "../../../components/input_select";
import { IDeviceBehaviourSelect } from "vigil-datamodel";
import { Behaviour, ConfigDefault } from "vigil-config";
import { InputButton } from "../../../components/input_button";
import { BehaviourParameterWithValues, BooleanInput, CronInput, NumberFloatInput, NumberIntInput, StringInput } from "./behaviour_inputs";

interface ModalDeviceBehaviourCreateProps extends ModalProps {
  uuidDevice?: string;
  uuidSite?: string;
  onSubmit?: (deviceBehaviour: IDeviceBehaviourSelect) => Promise<void>;
}

export const ModalDeviceBehaviourCreate: React.FC<ModalDeviceBehaviourCreateProps> = (props) => {
  const allBehaviours = ConfigDefault.behaviours;
  const behaviourOptions = allBehaviours.map((behaviour) => ({ value: behaviour.config.id, label: behaviour.config.name }));

  const organization = useContext(ContextOrganization);
  const vigil = useContext(ContextVigilClient);

  const [stateError, setError] = useState<string>('');
  const [stateSubmitLoading, setSubmitLoading] = useState(false);

  const [stateName, setName] = useState<string>('');
  const [stateBehaviour, setBehaviour] = useState<Behaviour>(allBehaviours[0]);
  const [stateBehaviourParameters, setBehaviourParameters] = useState<BehaviourParameterWithValues[]>(allBehaviours[0].config.parameters.map((parameter) => {
    let value: any = undefined;
    if (parameter.type == 'numberInt') value = 1;
    if (parameter.type == 'numberFloat') value = 1.0;
    if (parameter.type == 'boolean') value = false;
    if (parameter.type == 'string') value = '';
    if (parameter.type == 'cron') value = '0 12 * * *';
    return { ...parameter, value }
  }));

  /* DB Functions */
  async function createDeviceBehaviour() {
    if (!organization.data) return;
    try {
      setError('');
      setSubmitLoading(true);
      const deviceBehaviour = await vigil.functions.createDeviceBehaviour({
        uuidOrganization: organization.data.uuid,
        data: {
          uuidOrganization: organization.data.uuid,
          name: stateName,
          id: stateBehaviour.config.id,
          parameterValues: Object.fromEntries(stateBehaviourParameters.map((parameter) =>
            [parameter.id, parameter.value.toString()]
          ))
        }
      });
      props.uuidDevice && await vigil.functions.linkDeviceBehavioursToDevices({ uuidOrganization: organization.data.uuid, deviceUuids: [props.uuidDevice], deviceBehaviourUuids: [deviceBehaviour.uuid] });
      props.uuidSite && await vigil.functions.linkDeviceBehavioursToSites({ uuidOrganization: organization.data.uuid, siteUuids: [props.uuidSite], deviceBehaviourUuids: [deviceBehaviour.uuid] });
      props.onSubmit && await props.onSubmit(deviceBehaviour);
      props.toggle();
      resetState();
    } catch (error: any) {
      setError(error.message);
    } finally {
      setSubmitLoading(false);
    }
  }

  /* Validation */
  function validateName() {
    if (!stateName) return [];
    return validate(stateName, [VALIDATORS.length('Device behaviour name', 2, 30)]);
  }

  function validateForm() {
    const required = [];
    if (!stateName) { required.push('Device behaviour name is required') }
    return [
      ...required,
      ...validateName(),
    ];
  }

  /* Functions */
  function resetState() {
    setName('');
    setBehaviour(allBehaviours[0]);
    setBehaviourParameters(allBehaviours[0].config.parameters.map((parameter) => {
      let value: any = undefined;
      if (parameter.type == 'numberInt') value = 1;
      if (parameter.type == 'numberFloat') value = 1.0;
      if (parameter.type == 'boolean') value = false;
      if (parameter.type == 'string') value = '';
      if (parameter.type == 'cron') value = '0 12 * * *';
      return { ...parameter, value }
    }));
  }

  /* UI Updates */
  function onChangeName(event: React.ChangeEvent<HTMLInputElement>) {
    setError('');
    setName(event.target.value);
  }

  function onChangeBehaviour(event: React.ChangeEvent<HTMLSelectElement>) {
    setError('');
    const behaviour = allBehaviours.find(behaviour => behaviour.config.id === event.target.value);
    if (behaviour) setBehaviour(behaviour);
  }

  return (
    <Modal className='w-192' isOpen={props.isOpen} toggle={props.toggle} closeOnBackgroundClick={false}>
      <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2" onClick={() => props.toggle()}>✕</button>
      <h3 className="font-bold text-lg pb-2">Create new Device Behaviour</h3>
      <div className="flex flex-wrap">
        <InputText className='w-60 mr-2' labelText='Name' value={stateName} onChange={onChangeName} errors={validateName()} > </InputText>
        <InputSelect className='w-60 mr-2' labelText='Behaviour ID' value={stateBehaviour.config.id} onChange={onChangeBehaviour} options={behaviourOptions} errors={[]} />
      </div>
      <div className="overflow-y-auto">
        <div className="font-bold text-m mb-2">Description</div>
        <div className="text-sm mb-4">{stateBehaviour.config.description}</div>
        <div className="font-bold text-m mb-2">Parameters</div>
        {stateBehaviourParameters.map((parameter) => {
          if (parameter.type == 'string') return StringInput({ parameter, stateBehaviourParameters, setBehaviourParameters }, 'bg-base-200 p-2 rounded-lg mb-2 mr-2')
          if (parameter.type == 'cron') return CronInput({ parameter, stateBehaviourParameters, setBehaviourParameters }, 'bg-base-200 p-2 rounded-lg mb-2 mr-2')
          if (parameter.type == 'numberInt') return NumberIntInput({ parameter, stateBehaviourParameters, setBehaviourParameters }, 'bg-base-200 p-2 rounded-lg mb-2 mr-2')
          if (parameter.type == 'numberFloat') return NumberFloatInput({ parameter, stateBehaviourParameters, setBehaviourParameters }, 'bg-base-200 p-2 rounded-lg mb-2 mr-2')
          if (parameter.type == 'boolean') return BooleanInput({ parameter, stateBehaviourParameters, setBehaviourParameters }, 'bg-base-200 p-2 rounded-lg mb-2 mr-2')
          return null
        })}
      </div>
      {stateError && <StatusAlert message={stateError} type={"alert-error"} />}
      <div className="flex justify-end pt-2">
        <InputButton text='Confirm' loading={stateSubmitLoading} disabled={validateForm().length > 0} type='btn-primary' onClick={async () => await createDeviceBehaviour()}></InputButton>
      </div>
    </Modal>
  )
}