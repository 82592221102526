import { ReactNode, createContext, useContext } from "react";
import { ContextVigilClient } from "./provider_vigil_client";
import { Preferences } from "../preference_keys";
import { FullPageLoader } from "../components/full_page_loader";
import { useCaller } from "../hooks/use_caller";
import { FullPageError } from "../components/full_page_error";
import { IGenericContext } from "./type_generic_context";
import { IOrganizationSelect } from "vigil-datamodel";

export const ContextOrganization = createContext(null as any as IGenericContext<IOrganizationSelect>);

interface ProviderOrganizationProps {
  children: ReactNode
}
export const ProviderOrganization: React.FC<ProviderOrganizationProps> = (props: ProviderOrganizationProps) => {
  /* Dependancies */
  const vigil = useContext(ContextVigilClient);

  /* State */
  const organization = useCaller({
    callback: async () => {
      const selectedOrg = localStorage.getItem(Preferences.SELECTED_ORGANIZATION);
      if (!selectedOrg) return null;
      return await vigil.functions.findOneOrganization({ uuid: selectedOrg });
    },
    dependencies: { background: [vigil], normal: [] },
  });

  if (organization.loading) return <FullPageLoader location={"organization"} />
  if (organization.error) return <FullPageError error={organization.error} />

  return (
    <ContextOrganization.Provider value={{
      data: organization.result,
      loading: organization.loading,
      error: organization.error,
      refresh: organization.call,
    }}>
      {props.children}
    </ContextOrganization.Provider>
  )
}
