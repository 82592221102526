import { useContext, useEffect, useState } from "react";
import { InputButton } from "../../../components/input_button";
import { ContextVigilClient } from "../../../providers/provider_vigil_client";
import { Modal, ModalProps } from "../../../components/modal";
import { IDeviceBehaviourSelect, IDeviceSelect } from "vigil-datamodel";
import { StatusAlert } from "../../../components/status_alert";
import { ContextOrganization } from "../../../providers/provider_organization";
import { IconXCircleSolid } from "../../../components/icons";
import { InputSearchDropdown, OptionSearchDropdown } from "../../../components/input_search_dropdown";
import { StatusLoading } from "../../../components/status_loading";

interface ModalDeviceDeviceBehavioursCreateProps extends ModalProps {
  uuidDevice: string;
  onSubmit?: () => Promise<void>;
}

export const ModalDeviceDeviceBehavioursCreate: React.FC<ModalDeviceDeviceBehavioursCreateProps> = (props) => {
  const organization = useContext(ContextOrganization);
  const vigil = useContext(ContextVigilClient);

  const [stateError, setError] = useState<string>('');
  const [stateDeviceLoading, setDeviceLoading] = useState(true);
  const [stateDevice, setDevice] = useState<IDeviceSelect>();

  const [stateDeviceBehavioursLoading, setDeviceBehavioursLoading] = useState(true);
  const [stateLinkedDeviceBehaviours, setLinkedDeviceBehaviours] = useState<IDeviceBehaviourSelect[]>([]);
  const [stateUnlinkedDeviceBehaviours, setUnlinkedDeviceBehaviours] = useState<IDeviceBehaviourSelect[]>([]);
  const [stateNewDeviceBehaviours, setNewDeviceBehaviours] = useState<IDeviceBehaviourSelect[]>([]);
  const [statedeviceBehaviourOptions, setDeviceBehaviourOptions] = useState<OptionSearchDropdown[]>([]);

  useEffect(() => { fetchDevice(props.uuidDevice); }, [props.uuidDevice]);
  useEffect(() => { resetCalls() }, [props.isOpen]);

  /* Functions */
  function resetCalls() {
    setNewDeviceBehaviours([]);
    fetchLinkedDeviceBehaviours();
    fetchUnlinkedDeviceBehaviours();
  }

  function clearNewDeviceBehaviour(deviceBehaviour: IDeviceBehaviourSelect) {
    const newDevices = stateNewDeviceBehaviours.filter((_deviceBehaviour) => { return _deviceBehaviour.uuid != deviceBehaviour.uuid });
    setNewDeviceBehaviours(newDevices);

    const unlinkedDeviceBehaviours = stateUnlinkedDeviceBehaviours;
    unlinkedDeviceBehaviours.push(deviceBehaviour);
    setUnlinkedDeviceBehaviours(unlinkedDeviceBehaviours);

    const deviceBehaviourOptions = statedeviceBehaviourOptions;
    deviceBehaviourOptions.push({ label: deviceBehaviour.name, value: deviceBehaviour.uuid });
    setDeviceBehaviourOptions(deviceBehaviourOptions);
  }

  function addNewDeviceBehaviour(uuidDeviceBehaviour: string) {
    const deviceBehaviour: IDeviceBehaviourSelect = stateUnlinkedDeviceBehaviours.filter((_deviceBehaviour) => { return _deviceBehaviour.uuid == uuidDeviceBehaviour })[0];

    const newDeviceBehaviours = stateNewDeviceBehaviours;
    newDeviceBehaviours.push(deviceBehaviour);
    setNewDeviceBehaviours(newDeviceBehaviours);

    const unlinkedDeviceBehaviours = stateUnlinkedDeviceBehaviours.filter((_deviceBehaviour) => { return _deviceBehaviour.uuid != uuidDeviceBehaviour });
    setUnlinkedDeviceBehaviours(unlinkedDeviceBehaviours);

    const deviceBehaviourOptions = statedeviceBehaviourOptions.filter((option) => { return option.value != uuidDeviceBehaviour });
    setDeviceBehaviourOptions(deviceBehaviourOptions);
  }

  /* DB Functions */
  async function fetchDevice(uuid: string) {
    try {
      if (!organization.data) return;
      setDeviceLoading(true);
      const device = await vigil.functions.findOneDevice({ uuidOrganization: organization.data.uuid, uuid: uuid });
      setDevice(device);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setDeviceLoading(false);
    }
  }

  async function fetchLinkedDeviceBehaviours() {
    try {
      if (!organization.data) return;
      setDeviceBehavioursLoading(true);
      const deviceBehaviours = await vigil.functions.findManyDeviceBehavioursLinkedToDevice({ uuidOrganization: organization.data.uuid, uuidDevice: props.uuidDevice });
      setLinkedDeviceBehaviours(deviceBehaviours);
      setDeviceBehavioursLoading(false);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setDeviceBehavioursLoading(false);
    }
  }

  async function fetchUnlinkedDeviceBehaviours() {
    try {
      if (!organization.data) return;
      setDeviceBehavioursLoading(true);
      const unlinkedDeviceBehaviours = await vigil.functions.findManyDeviceBehavioursUnlinkedFromDevice({ uuidOrganization: organization.data.uuid, uuidDevice: props.uuidDevice });
      setUnlinkedDeviceBehaviours(unlinkedDeviceBehaviours);
      const deviceBehaviourOptions: OptionSearchDropdown[] = unlinkedDeviceBehaviours.map((deviceBehaviour) => {
        return { label: deviceBehaviour.name, value: deviceBehaviour.uuid }
      });
      setDeviceBehaviourOptions(deviceBehaviourOptions);
      setDeviceBehavioursLoading(false);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setDeviceBehavioursLoading(false);
    }
  }

  async function linkDeviceBehavioursToDevice() {
    try {
      if (!stateDevice || !organization.data) return;
      setDeviceBehavioursLoading(true);
      const uuidDeviceBehaviours = stateNewDeviceBehaviours.map((deviceBehaviour) => { return deviceBehaviour.uuid });
      await vigil.functions.linkDeviceBehavioursToDevices({ uuidOrganization: organization.data.uuid, deviceUuids: [stateDevice.uuid], deviceBehaviourUuids: uuidDeviceBehaviours });
      props.onSubmit && await props.onSubmit();
      props.toggle();
      resetCalls();
    } catch (error: any) {
      setError(error.message);
    } finally {
      setDeviceBehavioursLoading(false);
    }
  }

  if (stateDeviceLoading || stateDeviceBehavioursLoading || !stateDevice) {
    return (
      <Modal isOpen={props.isOpen} toggle={props.toggle} closeOnBackgroundClick={false} className="w-92">
        <StatusLoading />
      </Modal>
    )
  }

  if (stateUnlinkedDeviceBehaviours.length == 0 && stateNewDeviceBehaviours.length == 0) {
    return (
      <Modal isOpen={props.isOpen} toggle={props.toggle} closeOnBackgroundClick={false} className="w-92">
        <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2" onClick={() => props.toggle()}>✕</button>
        <h3 className="font-bold text-lg pb-2">Need to add device-behaviours first</h3>
        <div>Please add device-behaviours to link them to this device</div>
      </Modal>
    )
  }

  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle} closeOnBackgroundClick={false} className="w-192">
      <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2" onClick={() => props.toggle()}>✕</button>
      <h3 className="font-bold text-lg pb-2">Link device-behaviours to device uuid: {stateDevice.uuid}</h3>
      <div className="flex flex-wrap">
        {stateLinkedDeviceBehaviours.map((deviceBehaviour, index) => {
          return (
            <div key={index} className='flex items-center justify-center bg-primary-content rounded-3xl h-8 text-sm px-4 mr-2 mb-1'>
              {deviceBehaviour.name}
            </div>
          )
        })}
        {stateNewDeviceBehaviours.map((deviceBehaviour, index) => {
          return (
            <div key={index} className='flex items-center justify-center bg-secondary-content rounded-3xl h-8 text-sm px-4 mr-2 mb-1'>
              <div>{deviceBehaviour.name}</div>
              <button onClick={() => { clearNewDeviceBehaviour(deviceBehaviour) }}>
                <IconXCircleSolid className='ml-2' style={{ height: '18px' }} />
              </button>
            </div>
          )
        })}
      </div>
      <div className="flex">
        <InputSearchDropdown options={statedeviceBehaviourOptions} labelText={"Link new device-behaviour"} errors={[]} onSelect={(val) => addNewDeviceBehaviour(val.toString())} ></InputSearchDropdown>
      </div>
      {stateError && <StatusAlert type='alert-error' message={stateError} />}
      <div className="flex justify-end pt-4">
        <InputButton text='Link device-behaviours' loading={false} disabled={stateNewDeviceBehaviours.length == 0} type='btn-primary' onClick={async () => { await linkDeviceBehavioursToDevice() }}></InputButton>
      </div>
    </Modal>
  )
}