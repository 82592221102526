import content from 'vigil-map/dist/index.html?raw';
import { useEffect, useRef, useState } from 'react';
import { VIGIL_MAP_MSG_NAME, VigilMapMessage, VigilMapMessageId, VigilMapV2Props } from 'vigil-map';

export interface VigilMapController {
  sendVigilMessage: (msg: VigilMapMessage) => void
}

export interface MapWrapperProps {
  state: VigilMapV2Props
  setMapController: (controller: VigilMapController) => void
  children?: React.ReactNode
  loading?: boolean
  error?: string
}

export const VigilMap: React.FC<MapWrapperProps> = (props) => {
  const elementIframe = useRef(null as HTMLIFrameElement | null);
  const [stateController, setController] = useState(null as VigilMapController | null);
  const [blobUrl, setBlobUrl] = useState<string>('');

  useEffect(() => {
    const injection = `window.vigil_props = ${JSON.stringify(props.state)}`
    const blob = new Blob([content.replace('/*VIGIL_PROPS*/', injection)], { type: 'text/html' })
    const url = URL.createObjectURL(blob)
    setBlobUrl(url)
    return () => { URL.revokeObjectURL(url) }
  }, []);

  useEffect(() => {
    if (!stateController) return
    props.setMapController(stateController);
  }, [stateController])

  useEffect(() => {
    if (!stateController) return
    stateController.sendVigilMessage({
      id: VigilMapMessageId.SET_STATE,
      state: props.state,
    })
  }, [stateController, props.state])

  useEffect(() => {
    const messageReceived = (event: any) => {
      try {
        const json = JSON.parse(event.data);

        if (!json['id']) {
          throw new Error('No id field found on message')
        }

        if (!Object.keys(VigilMapMessageId).includes(json['id'])) {
          throw new Error('Id field does not match vigil message ids')
        }

        if (json['id'] == VigilMapMessageId.LOADED) {
          const controller: VigilMapController = {
            sendVigilMessage: (msg) => {
              elementIframe.current?.contentWindow?.postMessage(JSON.stringify(msg), "*");
            }
          }
          setController(controller);
        }
      } catch (error) {
        console.warn("Vigil map received an unknown message. Error below..")
        console.warn(error)
      }
    };

    window.addEventListener(VIGIL_MAP_MSG_NAME, messageReceived);
    return () => {
      window.removeEventListener(VIGIL_MAP_MSG_NAME, messageReceived);
    };
  }, [elementIframe]);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <div style={{ position: "relative", width: "100%", height: "100%" }}>
        <iframe ref={elementIframe} src={blobUrl} height={'100%'} width={'100%'} frameBorder="0" />
        <div className='absolute left-0 top-0 w-full h-full pointer-events-none'>
          {props.children}
        </div>
      </div>
    </div>
  )
}
