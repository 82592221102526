import React, { useContext } from 'react';
import { Table } from '../../components/table/table';
import { TTuuid } from 'tt-uuid';
import { IEventInstanceSelect } from 'vigil-datamodel';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../router/routes';
import { TextFilter } from '../../components/table/table_filters/table_filter_text';
import { DateFilter } from '../../components/table/table_filters/table_filter_date';
import { ContextVigilClient } from '../../providers/provider_vigil_client';
import { ContextOrganization } from '../../providers/provider_organization';
import { useTableData } from '../../hooks/use_table_data';

interface ScreenHomeOverviewEventsProps { }

export const ScreenHomeOverviewEvents: React.FC<ScreenHomeOverviewEventsProps> = (props) => {
  const navigate = useNavigate();

  const vigil = useContext(ContextVigilClient);
  const organization = useContext(ContextOrganization);

  const { data, loading, error, paging, filters, sorting, handleFiltersChange, handleSortingChange, handlePagingChange } = useTableData<IEventInstanceSelect>({
    fetchData: async ({ filters, sorting, paging }) => {
      if (!organization.data) return;
      const response = await vigil.functions.directoryEventInstances({
        uuidOrganization: organization.data.uuid,
        filters: [
          ...filters, 
          { id: 'dateTimeStart', query: 'greaterThan', value: new Date() },
          { id: 'dateTimeEnd', query: 'lessThan', value: new Date() },
        ],
        ordering: { field: sorting.id as any, direction: sorting.direction },
        pagination: { page: paging.currentPage, count: paging.itemsPerPage }
      });
      return { items: response.items, totalPages: response.pagination.pages, totalItems: response.pagination.count };
    },
    defaultSorting: { id: 'uuid', direction: 'descending' },
    refreshInterval: 5000,
    dependencies: [organization.data],
  });

  function navigateEvent(selectedEvent: IEventInstanceSelect) {
    navigate(ROUTES.ROUTE_HOME_EVENT_INSTANCE.replace(':uuid', selectedEvent.uuid))
  }

  return (
    <div className='h-full w-full'>
      <Table<IEventInstanceSelect>
        className='h-full flex flex-col drop-shadow'
        name='Active Events'
        data={data}
        selectable={false}
        loading={loading}
        error={error}
        columns={[
          {
            id: 'event_name',
            header: 'Event Name',
            sortable: true,
            value(item) {
              return (
                <div className='hover:underline cursor-pointer text-blue-500' onClick={() => navigateEvent(item)}>
                  {item.eventSnapshot.name}
                </div>
              );
            },
          },
          {
            id: 'date_created',
            header: 'Date Created',
            sortable: true,
            value(item) {
              const decodedUuid = TTuuid.decodeCuuid(item.uuid);
              return <div>{decodedUuid.time.toLocaleDateString() + " " + decodedUuid.time.toLocaleTimeString()}</div>;
            },
          },
          {
            id: 'last_updated',
            header: 'Last Updated',
            sortable: true,
            value(item) {
              const decodedUuid = TTuuid.decodeCuuid(item.changeStamp);
              return <div>{decodedUuid.time.toLocaleDateString() + " " + decodedUuid.time.toLocaleTimeString()}</div>;
            },
          },
        ]}

        filtersOptions={[
          new TextFilter('event_name', 'Event Name', 'contains'),
          new DateFilter('date_created', 'Date Created', 'between'),
          new DateFilter('last_updated', 'Last Updated', 'between'),
        ]}
        filtersSelected={filters}
        onFiltersChange={handleFiltersChange}

        sortingOptions={null}
        sortingSelected={sorting}
        onSortingChange={handleSortingChange}

        pagingOptions={null}
        pagingSelected={paging}
        onPagingChange={handlePagingChange}

        unSelectedActions={[
        ]}
        selectedActions={[
        ]}
        hoverActions={[
        ]}
      />
    </div>
  );
};
