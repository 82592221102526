import qrcode from 'qrcode';

export type JSONValue = 
 | string
 | number
 | boolean
 | null
 | JSONValue[]
 | {[key: string]: JSONValue}

export interface JSONObject {
  [k: string]: JSONValue
}
export interface JSONArray extends Array<JSONValue> {}

export class VigilQRCode<T extends JSONObject> {
  constructor(public readonly id: string) { }

  public toString(input: T) {
    // Type data
    const data = input as unknown as JSONObject;

    // Get JSON
    data['id'] = this.id;

    // JSON to string
    const dataQR = JSON.stringify(data);

    return dataQR;
  }

  public toSVGDataURL(input: T) {
    const dataQR = this.toString(input);

    return new Promise<string>((resolve, reject) => {
      // Make SVG
      qrcode.toString(dataQR, {
        errorCorrectionLevel: 'M',
        type: 'svg'
      }, (error: any, data: any) => {
        // Check for errors
        if (error) throw reject(error);

        // Make DataURL
        const result = `data:image/svg+xml;utf8,${encodeURIComponent(data)}`

        // Return result
        resolve(result);
      });
    });
  }
}