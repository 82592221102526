import { Config } from "../base/config_sdk";
import { BehaviourSignIn } from "./behaviour_sign_in";
import { BehaviourSignOut } from "./behaviour_sign_out";
import { EventPatrolSequenceBeacons } from "./event_patrol_sequence_beacons";

export const ConfigDefault: Config = {
  id: "default",
  behaviours: [
    BehaviourSignIn,
    BehaviourSignOut,
  ],
  events: [
    EventPatrolSequenceBeacons,    
  ]
}
