import { useState, useEffect, useRef } from 'react';
import { useInterval } from './use_interval';

type Callback<T> = () => Promise<T>;

interface UseCallerResult<T> {
  result: T | null;
  loading: boolean;
  error: string;
  call: (background?: boolean) => Promise<void>;
}


/**
 * Custom hook to handle asynchronous calls with support for background execution,
 * dependency-based execution, and interval-based execution.
 *
 * @template T - The type of the result returned by the callback.
 * @param {Object} params - Parameters for the hook.
 * @param {Callback<T>} params.callback - The callback function to be called.
 * @param {Object} [params.dependencies] - Dependencies for the hook.
 * @param {any[]} [params.dependencies.background] - Dependencies for background execution.
 * @param {any[]} [params.dependencies.normal] - Dependencies for normal execution.
 * @param {boolean} [params.doNotRunImmediate] - Flag indicating if the call should run immediately.
 * @param {number} [params.intervalTime] - Interval time in milliseconds for interval-based execution.
 * @param {() => void} [params.onCalled] - Callback function to be called after the main callback is executed.
 * @returns {UseCallerResult<T>} - The result of the hook containing the result, loading state, error message, and call function.
 */
export const useCaller = <T>(params: {
  callback: Callback<T>,
  dependencies?: { background: any[], normal: any[] },
  doNotRunImmediate?: boolean,
  intervalTime?: number,
  onCalled?: () => void
}): UseCallerResult<T> => {
  const isInitialMount = useRef(true);
  const [result, setResult] = useState<T | null>(null);
  const [loading, setLoading] = useState<boolean>(!params.doNotRunImmediate);
  const [error, setError] = useState<string>("");

  const callBackground = async () => {
    try {
      setError("");
      const data = await params.callback();
      setResult(data);
      if (params.onCalled) params.onCalled();
    } catch (error: any) {
      setError(error.message);
      throw error;
    }
  }

  const call = async (background?: boolean) => {
    try {
      if (!background) setLoading(true);
      await callBackground();
    } finally {
      setLoading(false);
    }
  };

  // Effect for normal (non-background) execution
  // Runs on mount or when normal dependencies change, unless it's a mutation then it only runs when dependencies change
  if (!params.doNotRunImmediate || (params.dependencies && params.dependencies.normal.length > 0)) {
    useEffect(() => { call() }, params.dependencies?.normal ?? []);
  }

  // Effect for background execution
  // Runs when background dependencies change, skipping the initial mount
  if (params.dependencies && params.dependencies.background.length > 0) {
    useEffect(() => {
      if (isInitialMount.current) {
        isInitialMount.current = false;
      } else {
        callBackground();
      }
    }, params.dependencies.background);
  }

  // Effect for interval-based execution
  if (params.intervalTime) useInterval(callBackground, params.intervalTime, false);

  return { result, loading, error, call };
};