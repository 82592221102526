import { useContext, useState } from "react";
import { VALIDATORS, validate } from "../../../validation";
import { InputText } from "../../../components/input_text";
import { InputButton } from "../../../components/input_button";
import { ContextVigilClient } from "../../../providers/provider_vigil_client";
import { ContextOrganization } from "../../../providers/provider_organization";
import { ModalProps } from "../../../components/modal";
import { StatusAlert } from "../../../components/status_alert";
import { IOrganizationRoleSelect, IOrganizationRoleUserInviteInsert } from "vigil-datamodel";
import { InputSelect } from "../../../components/input_select";

interface UserInviteCreateEmailProps extends ModalProps {
  organizationRoles: IOrganizationRoleSelect[];
  lowestRole: IOrganizationRoleSelect;
  onSubmit?: (inviteInfo: IOrganizationRoleUserInviteInsert) => Promise<void>;
}

export const UserInviteCreateEmail: React.FC<UserInviteCreateEmailProps> = (props) => {
  const organization = useContext(ContextOrganization);
  const vigil = useContext(ContextVigilClient);

  const [stateError, setError] = useState<string>('');
  const [stateSubmitLoading, setSubmitLoading] = useState(false);

  const [stateUserInviteEmail, setUserInviteEmail] = useState('');
  const [stateSelectedRole, setSelectedRole] = useState<IOrganizationRoleSelect>(props.lowestRole);

  /* DB Functions */
  async function userInviteEmailCreate() {
    if (!organization.data) return;
    try {
      setError('');
      setSubmitLoading(true);
      const userInvite = await vigil.functions.createUserInvite({
        data: {
          uuidOrganization: organization.data.uuid,
          inviteUserEmail: stateUserInviteEmail,
          uuidOrganizationRole: stateSelectedRole.uuid,
        }
      });
      props.onSubmit && await props.onSubmit(userInvite);
      props.toggle();
      setUserInviteEmail('');
    } catch (error: any) {
      setError(error.message);
    } finally {
      setSubmitLoading(false);
    }
  }

  /* Validation */
  function validateUserEmail() {
    if (!stateUserInviteEmail) return [];
    return validate(stateUserInviteEmail, [VALIDATORS.email()]);
  }

  function validateForm() {
    const required = [];
    if (!stateUserInviteEmail) { required.push('Email is required') }
    return [
      ...required,
      ...validateUserEmail(),
    ];
  }

  /* UI Updates */
  function onChangeUserEmail(event: React.ChangeEvent<HTMLInputElement>) {
    setError('');
    setUserInviteEmail(event.target.value);
  }

  function onChangeSelectedRole(event: React.ChangeEvent<HTMLSelectElement>) {
    const role = props.organizationRoles.find((role) => role.uuid == event.target.value);
    if (role) {
      setSelectedRole(role);
    }
  }

  return (
    <div className="pt-2">
      <InputText labelText='Email' value={stateUserInviteEmail} onChange={onChangeUserEmail} errors={validateUserEmail()} > </InputText>
      <InputSelect errors={[]} labelText="Role" value={stateSelectedRole.uuid} options={props.organizationRoles.map((role) => ({ label: role.name, value: role.uuid }))} onChange={onChangeSelectedRole}> </InputSelect>
      {stateError && <StatusAlert message={stateError} type={"alert-error"} />}
      <div className="flex justify-end pt-2">
        <InputButton text='Confirm' loading={stateSubmitLoading} disabled={validateForm().length > 0} type='btn-primary' onClick={async () => await userInviteEmailCreate()}></InputButton>
      </div>
    </div>
  )
}