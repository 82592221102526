import { ReactNode, createContext, useContext } from "react";
import { FullPageLoader } from "../components/full_page_loader";
import { ContextVigilClient } from "./provider_vigil_client";
import { IUserSelect, tokenDecoder } from "vigil-datamodel";
import { IGenericContext } from "./type_generic_context";
import { useCaller } from "../hooks/use_caller";
import { FullPageError } from "../components/full_page_error";
import { Preferences } from "../preference_keys";

export const ContextUser = createContext(null as any as IGenericContext<IUserSelect>);

interface ProviderUserProps {
  children: ReactNode
}
export const ProviderUser: React.FC<ProviderUserProps> = (props: ProviderUserProps) => {
  /* Dependancies */
  const vigil = useContext(ContextVigilClient);

  /* State */
  const user = useCaller({
    callback: async () => {
      const token = localStorage.getItem(Preferences.TOKEN);
      if (!token) return null
      return tokenDecoder(token, 'user').user;
    },
    dependencies: { background: [vigil], normal: [] },
  });

  if (user.loading) return <FullPageLoader location={"user"} />
  if (user.error) return <FullPageError error={user.error} />

  return (
    <ContextUser.Provider value={{
      data: user.result,
      loading: user.loading,
      error: user.error,
      refresh: user.call,
    }}>
      {props.children}
    </ContextUser.Provider>
  )
}
