import { useState, useEffect } from 'react';
import { QueryFilter } from '../components/table/table_filters/table_filter_types';
import { Paging, Sorting } from '../components/table/table_types';
import { useInterval } from '../hooks/use_interval';

interface UseTableDataProps<T> {
  fetchData: (params: {
    filters: QueryFilter<T>[];
    sorting: Sorting;
    paging: Paging;
  }) => Promise<{
    items: T[];
    totalPages: number;
    totalItems: number;
  } | undefined>;
  defaultSorting: Sorting;
  refreshInterval?: number;
  dependencies?: any[];
}

export function useTableData<T>({
  fetchData,
  defaultSorting,
  refreshInterval,
  dependencies = []
}: UseTableDataProps<T>) {
  const [data, setData] = useState<T[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [filters, setFilters] = useState<QueryFilter<T>[]>([]);
  const [sorting, setSorting] = useState<Sorting>(defaultSorting);
  const [paging, setPaging] = useState<Paging>({ currentPage: 1, totalItems: 0, itemsPerPage: 10, totalPages: 1 });

  const fetchTableData = async (isBackground = false) => {
    if (!isBackground) setLoading(true);
    setError("");

    try {
      const response = await fetchData({ filters, sorting, paging });
      if (!response) return;

      if (JSON.stringify(response.items) != JSON.stringify(data)) {
        setData(response.items);
        setPaging(prevPaging => ({
          ...prevPaging,
          totalPages: response.totalPages,
          totalItems: response.totalItems
        }));
      }
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => { fetchTableData() }, [filters, sorting, paging.currentPage, paging.itemsPerPage, ...dependencies]);

  if (refreshInterval) useInterval(() => fetchTableData(true), refreshInterval, false);

  const handleFiltersChange = (newFilters: QueryFilter<T>[]) => {
    setFilters(newFilters);
    setPaging(prevPaging => ({ ...prevPaging, currentPage: 1 }));
  }

  const handleSortingChange = (newSorting: Sorting) => setSorting(newSorting)

  const handlePagingChange = (newPaging: Paging) => setPaging(newPaging)

  const forceRefetch = () => fetchTableData();

  return {
    data,
    loading,
    error,
    filters,
    sorting,
    paging,
    handleFiltersChange,
    handleSortingChange,
    handlePagingChange,
    forceRefetch
  };
}