import { useContext, useState } from "react";
import { VALIDATORS, validate } from "../../../validation";
import { InputText } from "../../../components/input_text";
import { InputButton } from "../../../components/input_button";
import { EventConfig, EventConfigType, EventTriggerType, EventTriggerSchedule, IEventSelect } from "vigil-datamodel";
import { Modal, ModalProps } from "../../../components/modal";
import { InputTime, TimeState } from "../../../components/input_time";
import { DurationState, InputDuration } from "../../../components/input_duration";
import { InputBase } from "../../../components/input_base";
import { StatusAlert } from "../../../components/status_alert";
import { ContextVigilClient } from "../../../providers/provider_vigil_client";
import { EventPatrolUpdate } from "../func_event_patrol/event_patrol_update";
import { Occurance, getOccurance, getDurationState, getDuration, getCronExpression, getTimeState } from "../helper_cron";
import { ContextOrganization } from "../../../providers/provider_organization";

interface ModalEventUpdateProps extends ModalProps {
  event: IEventSelect;
  onSubmit?: () => Promise<void>;
}

export const ModalEventUpdate: React.FC<ModalEventUpdateProps> = (props) => {
  const organization = useContext(ContextOrganization);
  const vigil = useContext(ContextVigilClient);

  const [stateError, setError] = useState<string>('');
  const [stateSubmitLoading, setSubmitLoading] = useState(false);

  // Event State
  const [stateName, setName] = useState<string>(props.event.name);
  const [stateOccurance, setOccurance] = useState<Occurance>(getOccurance((props.event.triggers[0] as EventTriggerSchedule).eventCron));
  const [stateDurationPicker, setDurationPicker] = useState<DurationState>(getDurationState(props.event.triggers[0].eventLifetimeMS / 1000));
  const [stateTimePicker, setTimePicker] = useState<TimeState>(getTimeState((props.event.triggers[0] as EventTriggerSchedule).eventCron));

  // Event Config Patrol State
  const [statePatrolConfigRefetchData, setPatrolConfigRefetchData] = useState(false);
  const [statePatrolConfig, setPatrolConfig] = useState<EventConfig & { type: EventConfigType.Patrol }>(props.event.configs.find(config => config.type === EventConfigType.Patrol) as EventConfig & { type: EventConfigType.Patrol });
  const [statePatrolConfigValidation, setPatrolConfigValidation] = useState<string[]>([]);

  /* UI Updates */
  function onChangeName(event: React.ChangeEvent<HTMLInputElement>) {
    setError('');
    setName(event.target.value);
  }

  function onChangeTime(newTime: TimeState) {
    setError('');
    setTimePicker(newTime);
  }

  function onChangeDuration(newDuration: DurationState) {
    setError('');
    setDurationPicker(newDuration);
  }

  function toggleOccurance(day: keyof Occurance) {
    setError('');
    setOccurance({ ...stateOccurance, [day]: !stateOccurance[day] });
  }

  function onChangePatrolConfig(config: EventConfig & { type: EventConfigType.Patrol }) {
    setPatrolConfig(config);
  }

  function onChangePatrolConfigValidation(validation: string[]) {
    setPatrolConfigValidation(validation);
  }

  /* Validation */
  function validateName() {
    if (!stateName) return [];
    return validate(stateName, [VALIDATORS.length('Event name', 2, 30)]);
  }

  function validateOccurance() {
    if (!stateOccurance.MON && !stateOccurance.TUE && !stateOccurance.WED && !stateOccurance.THU && !stateOccurance.FRI && !stateOccurance.SAT && !stateOccurance.SUN) return ['Need to select atleast one'];
    return [];
  }

  function validateDuration() {
    return validate(getDuration(stateDurationPicker), [VALIDATORS.numberBetween('Duration', 60, 86400)]);
  }

  function validateForm() {
    const required = [];
    if (!stateName) { required.push('Event name is required') }
    return [
      ...required,
      ...validateName(),
      ...validateOccurance(),
      ...validateDuration(),
      ...statePatrolConfigValidation
    ];
  }

  /* Functions */
  async function updateEvent() {
    try {
      if (!organization.data) return;
      setError('');
      setSubmitLoading(true);
      // TODO: FIX
      // await vigil.functions.updateOneOnOrganization({
      //   type: "events",
      //   uuidOrganization: organization.data.uuid,
      //   uuid: props.event.uuid,
      //   data: {
        //   name: stateName,
        //   triggers: [{
        //     type: EventTriggerType.Scheduled,
        //     enabled: true,
        //     creationCron: getCronExpression(stateTimePicker, stateOccurance),
        //     creationLifetimeMS: getDuration(stateDurationPicker) * 1000,
        //     eventCron: getCronExpression(stateTimePicker, stateOccurance),
        //     eventLifetimeMS: getDuration(stateDurationPicker) * 1000,
        //   }],
        //   configs: [statePatrolConfig]
        // }
      // });
      props.onSubmit && await props.onSubmit();
      setPatrolConfigRefetchData(!statePatrolConfigRefetchData);
      props.toggle();
    } catch (error: any) {
      setError(error.message);
    } finally {
      setSubmitLoading(false);
    }
  }

  return (
    <Modal className="w-192 overflow-y-auto" isOpen={props.isOpen} toggle={props.toggle} closeOnBackgroundClick={false}>
      <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2" onClick={() => props.toggle()}>✕</button>
      <h3 className="font-bold text-lg pb-2">Create new Event</h3>
      <InputText className='w-60 mr-2' value={stateName} labelText={"Event name"} onChange={onChangeName} errors={validateName()}></InputText>
      <div className="bg-base-200 p-1 rounded-xl">
        <div className="text-sm italic">The start time, duration and occurance of the event</div>
        <div className="flex flex-wrap">
          <InputTime className='mr-2' labelText='Start Time' value={stateTimePicker} onChange={onChangeTime} errors={[]} > </InputTime>
          <InputDuration className='mr-2' labelText='Duration ⓘ' labelTooltip="HOURS:MINUTES" errors={validateDuration()} value={stateDurationPicker} onChange={onChangeDuration} > </InputDuration>
          <InputBase labelText='Occurance' errors={validateOccurance()}>
            <div className="w-80 flex flex-wrap">
              {Object.keys(stateOccurance).map((day, index) => {
                return (
                  <label className="p-0 mr-1 mb-1 label cursor-pointer" key={index}>
                    <input type="checkbox" checked={stateOccurance[day as keyof Occurance]} onChange={() => toggleOccurance(day as keyof Occurance)} className="checkbox checkbox-sm" />
                    <div className="mx-0.5"></div>
                    <span className="label-text">{day}</span>
                  </label>
                )
              })}
            </div>
          </InputBase>
        </div>
      </div>
      {/* TODO: Need to insert a input dropdown */}
      {/* TODO: Insert infinite event */}
      <div className="p-1" />
      <EventPatrolUpdate refetchData={statePatrolConfigRefetchData} patrolConfig={statePatrolConfig} onPatrolConfigChange={onChangePatrolConfig} patrolConfigValidation={statePatrolConfigValidation} onPatrolConfigValidationChange={onChangePatrolConfigValidation} />
      {stateError && <StatusAlert message={stateError} type={"alert-error"} />}
      <div className="flex justify-end pt-2">
        <InputButton text='Confirm' loading={stateSubmitLoading} disabled={validateForm().length > 0} type='btn-primary' onClick={async () => { await updateEvent() }}></InputButton>
      </div>
    </Modal>
  )
}