import React, { useContext, useState } from 'react';
import { ContextOrganization } from "../../providers/provider_organization";
import { IDirectoryNotification, INotificationPanic, NotificationType } from 'vigil-datamodel';
import { ContextVigilClient } from '../../providers/provider_vigil_client';
import { Table } from '../../components/table/table';
import { TextFilter } from '../../components/table/table_filters/table_filter_text';
import { TTuuid } from 'tt-uuid';
import { DateFilter } from '../../components/table/table_filters/table_filter_date';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../router/routes';
import { useTableData } from '../../hooks/use_table_data';

interface ScreenHomeNotificationsProps { }

export const ScreenHomeNotifications: React.FC<ScreenHomeNotificationsProps> = (props) => {
  const navigate = useNavigate()
  const organization = useContext(ContextOrganization);
  const vigil = useContext(ContextVigilClient);

  const { data: notifications, loading, error, filters, sorting, paging, handleFiltersChange, handleSortingChange, handlePagingChange } = useTableData<IDirectoryNotification>({
    fetchData: async ({ filters, sorting, paging }) => {
      if (!organization.data) return;
      const response = await vigil.functions.directoryNotifications({
        uuidOrganization: organization.data.uuid,
        filters: filters,
        ordering: { field: sorting.id as any, direction: sorting.direction },
        pagination: { page: paging.currentPage, count: paging.itemsPerPage }
      });
      return { items: response.items, totalPages: response.pagination.pages, totalItems: response.pagination.count };
    },
    defaultSorting: { id: 'uuid', direction: 'descending' },
    refreshInterval: 5000,
    dependencies: [organization.data],
  });

  function navigateNotification(selectedNotification: IDirectoryNotification) {
    navigate(ROUTES.ROUTE_HOME_NOTIFICATION.replace(':uuid', selectedNotification.uuid))
  }

  function navigateSite(uuid: string) {
    navigate(ROUTES.ROUTE_HOME_SITE.replace(':uuid', uuid))
  }

  function navigateGoogleMaps(lat: number, lng: number) {
    window.open(`https://www.google.com/maps?q=${lat},${lng}`, '_blank');
  }

  function ClickableText(props: { text: string, onClick: () => void }) {
    return <div className='hover:underline cursor-pointer text-blue-500' onClick={props.onClick}>{props.text}</div>
  }

  return (
    <div className='p-4 h-full w-full'>
      <Table<IDirectoryNotification>
        className='h-full flex flex-col drop-shadow'
        name='Notifications'
        data={notifications}
        selectable={false}
        loading={loading}
        error={error}
        columns={[
          {
            id: 'id',
            header: 'Type',
            sortable: true,
            value(item) {
              let type = '';
              if (item.type == NotificationType.Panic) { type = 'Panic' }
              return (
                <div className='hover:underline cursor-pointer text-blue-500' onClick={() => navigateNotification(item)}>
                  {type}
                </div>
              );
            },
          },
          {
            id: 'userReceiveCount',
            header: 'Users Received',
            sortable: true,
            value(item) {
              return <div>{item.userReceiveCount}</div>;
            },
          },
          {
            id: 'userDismissedCount',
            header: 'Users Dismissed',
            sortable: true,
            value(item) {
              return <div>{item.userDismissedCount}</div>;
            },
          },
          {
            id: 'uuid',
            header: 'Date Created',
            sortable: true,
            value(item) {
              const decodedUuid = TTuuid.decodeCuuid(item.uuid);
              return <div>{decodedUuid.time.toLocaleDateString() + " " + decodedUuid.time.toLocaleTimeString()}</div>;
            },
          },
          {
            id: 'data',
            header: 'Data',
            sortable: false,
            value(item) {
              if (item.type === NotificationType.Panic) {
                const panic = item.data as INotificationPanic;
                return (
                  <ClickableText text='User Location' onClick={() => navigateGoogleMaps(panic.lat, panic.lng)} />
                )
              }
              return <div></div>
            },
          }
        ]}
        filtersOptions={[
          new TextFilter('id', 'Type', 'contains'),
          new DateFilter('uuid', 'Date Created', 'between'),
        ]}
        filtersSelected={filters}
        onFiltersChange={handleFiltersChange}

        sortingOptions={null}
        sortingSelected={sorting}
        onSortingChange={handleSortingChange}

        pagingOptions={null}
        pagingSelected={paging}
        onPagingChange={handlePagingChange}
      />
    </div >
  );
};
