import { useState } from 'react';

type Callback<T, P extends any[]> = (...args: P) => Promise<T>;

interface UseStateCallbackResult<T, P extends any[]> {
  result: T | null;
  loading: boolean;
  error: string;
  call: (...args: P) => Promise<void>;
}

/**
 * Custom hook to manage state for asynchronous operations with loading, error, and result states.
 * Similar to useFetch, but only executes when explicitly called rather than on dependencies changes.
 * 
 * Useful for handling user-triggered async actions like form submissions or data mutations.
 *
 * @template T - The type of the result returned by the callback
 * @template P - The tuple type of parameters accepted by the callback
 * @param {Callback<T, P>} callback - The async function to execute when called
 * @param {() => void} [onCalled] - Optional callback executed after successful completion
 * @returns {UseStateCallbackResult<T, P>} Object containing:
 *   - result: The data returned from the last successful callback execution, or null
 *   - loading: Boolean indicating if an operation is in progress
 *   - error: Error message if the last operation failed, empty string otherwise
 *   - call: Function to execute the callback with parameters
 */
export const useStateCallback = <T, P extends any[]>(callback: Callback<T, P>, onCalled?: () => void): UseStateCallbackResult<T, P> => {
  const [result, setResult] = useState<T | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  async function call(...args: P) {
    try {
      setError("");
      setLoading(true);
      const data = await callback(...args);
      setResult(data);
      onCalled?.();
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return { result, loading, error, call };
};