import { ICreator } from "./type_creator";
import { sqliteTable, text } from "drizzle-orm/sqlite-core";
import { customJsonColumn, schemaBase } from "./bean_base";
import { deviceBehaviourInstances } from "./bean_device_behaviour_instance";
import { relations } from "drizzle-orm";

export const deviceBehaviourInstanceLogs = sqliteTable('device_behaviour_instance_logs', {
  ...schemaBase(),
  uuidDeviceBehaviourInstance: text('uuid_device_behaviour_instance').notNull().references(() => deviceBehaviourInstances.uuid),
  creator: customJsonColumn<ICreator>('creator').notNull(),
  tags: customJsonColumn<string[]>('tags').notNull(),
  data: customJsonColumn<Record<string, any>>('data').notNull(),
});

export const deviceBehaviourInstanceLogsRelations = relations(deviceBehaviourInstanceLogs, ({ one }) => ({
  deviceBehaviourInstance: one(deviceBehaviourInstances, {
    fields: [deviceBehaviourInstanceLogs.uuidDeviceBehaviourInstance],
    references: [deviceBehaviourInstances.uuid],
  }),
}));
