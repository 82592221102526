import { FC, useEffect, useState, useCallback } from 'react';
import { InputBase } from './input_base';
import { TimeState, InputTime } from './input_time';
import { Occurance, getOccurance,  getTimeState, getCronExpression } from './helper_cron';

interface InputCronProps {
  cron: string;
  validationErrors: string[];
  onChangeCron?: (cron: string) => void;
  onValidationErrors?: (errors: string[]) => void;
}

export const InputCron: FC<InputCronProps> = ({
  cron,
  validationErrors,
  onChangeCron,
  onValidationErrors
}) => {
  const [occurance, setOccurance] = useState<Occurance>(() => getOccurance(cron));
  const [timePicker, setTimePicker] = useState<TimeState>(() => getTimeState(cron));

  useEffect(() => {
    const newCron = getCronExpression(timePicker, occurance);
    onChangeCron?.(newCron);
    
    const newErrors = [...validateOccurance()];
    onValidationErrors?.(newErrors);
  }, [occurance, timePicker]);

  const handleTimeChange = useCallback((newTime: TimeState) => {
    setTimePicker(newTime);
  }, []);

  const toggleOccurance = useCallback((day: keyof Occurance) => {
    setOccurance(prev => ({ ...prev, [day]: !prev[day] }));
  }, []);

  const validateOccurance = useCallback(() => {
    return Object.values(occurance).some(Boolean) ? [] : ['Need to select at least one day'];
  }, [occurance]);

  return (
    <div className="flex flex-wrap">
      <InputTime 
        className='mr-2' 
        labelText='Start Time' 
        value={timePicker} 
        onChange={handleTimeChange} 
        errors={[]}
      />
      <InputBase labelText='Occurance' errors={validateOccurance()}>
        <div className="w-80 flex flex-wrap">
          {(Object.keys(occurance) as Array<keyof Occurance>).map((day) => (
            <label className="p-0 mr-1 mb-1 label cursor-pointer" key={day}>
              <input 
                type="checkbox" 
                checked={occurance[day]} 
                onChange={() => toggleOccurance(day)} 
                className="checkbox checkbox-sm" 
              />
              <div className="mx-0.5" />
              <span className="label-text">{day}</span>
            </label>
          ))}
        </div>
      </InputBase>
    </div>
  );
};