import { sql } from 'drizzle-orm';
import { text, integer, blob } from 'drizzle-orm/sqlite-core';
import { TTuuid } from 'tt-uuid';

export enum StatusType {
  SYNCED = 'SYNCED',
  UPDATED = 'UPDATED',
}

function isServer(): boolean {
  return (typeof global !== "undefined" && (global as any)?.DATAMODEL_SYNCED_BY_DEFAULT) ||
    (typeof window !== "undefined" && (window as any)?.DATAMODEL_SYNCED_BY_DEFAULT);
}

export const schemaBase = () => {
  return {
    uuid: text('uuid').primaryKey().$defaultFn(() => TTuuid.getCuuid()),
    changeStamp: text('changestamp').notNull().$onUpdateFn(() => TTuuid.getCuuid()),
    serverStamp: text('serverstamp').notNull().$defaultFn(() => TTuuid.getCuuid()).$onUpdateFn(() => {
      if (isServer()) return TTuuid.getCuuid();
      return sql`serverstamp`;
    }), // IMPORTANT: DO NOT REMOVE $defaultFn otherwise sql`serverstamp` does not work
    status: text('status', { enum: Object.values(StatusType) as [StatusType] }).notNull().$onUpdateFn(() => {
      if (isServer()) return StatusType.SYNCED;
      return StatusType.UPDATED;
    }),
    deleted: integer('deleted').notNull().default(0),
  }
};

export const customJsonColumn = <T>(columnName: string) => blob(columnName, { mode: 'json' }).$type<T>();