import { useContext, useEffect, useState } from "react";
import { InputButton } from "../../../components/input_button";
import { ContextVigilClient } from "../../../providers/provider_vigil_client";
import { Modal, ModalProps } from "../../../components/modal";
import { IOrganizationRoleSelect, IUserSelect } from "vigil-datamodel";
import { StatusAlert } from "../../../components/status_alert";
import { ContextOrganization } from "../../../providers/provider_organization";
import { IconXCircleSolid } from "../../../components/icons";
import { InputSearchDropdown, OptionSearchDropdown } from "../../../components/input_search_dropdown";
import { StatusLoading } from "../../../components/status_loading";

interface ModalRoleUsersCreateProps extends ModalProps {
  uuidRole: string;
  onSubmit?: () => Promise<void>;
}

export const ModalRoleUsersCreate: React.FC<ModalRoleUsersCreateProps> = (props) => {
  const organization = useContext(ContextOrganization);
  const vigil = useContext(ContextVigilClient);

  const [stateError, setError] = useState<string>('');
  const [stateRoleLoading, setRoleLoading] = useState(true);
  const [stateRole, setRole] = useState<IOrganizationRoleSelect>();

  const [stateUsersLoading, setUsersLoading] = useState(true);
  const [stateLinkedUsers, setLinkedUsers] = useState<IUserSelect[]>([]);
  const [stateUnlinkedUsers, setUnlinkedUsers] = useState<IUserSelect[]>([]);
  const [stateNewUsers, setNewUsers] = useState<IUserSelect[]>([]);
  const [stateUserOptions, setUserOptions] = useState<OptionSearchDropdown[]>([]);

  useEffect(() => { fetchRole(props.uuidRole); }, [props.uuidRole]);
  useEffect(() => { resetCalls() }, [props.isOpen]);

  /* Functions */
  function resetCalls() {
    setNewUsers([]);
    fetchLinkedUsers();
    fetchUnlinkedUsers();
  }

  function clearNewUser(user: IUserSelect) {
    const newRoles = stateNewUsers.filter((_user) => { return _user.uuid != user.uuid });
    setNewUsers(newRoles);

    const unlinkedUsers = stateUnlinkedUsers;
    unlinkedUsers.push(user);
    setUnlinkedUsers(unlinkedUsers);

    const userOptions = stateUserOptions;
    userOptions.push({ label: `${user.firstName} ${user.lastName}`, value: user.uuid });
    setUserOptions(userOptions);
  }

  function addNewUser(uuidUser: string) {
    const user: IUserSelect = stateUnlinkedUsers.filter((_user) => { return _user.uuid == uuidUser })[0];

    const newUsers = stateNewUsers;
    newUsers.push(user);
    setNewUsers(newUsers);

    const unlinkedUsers = stateUnlinkedUsers.filter((_user) => { return _user.uuid != uuidUser });
    setUnlinkedUsers(unlinkedUsers);

    const userOptions = stateUserOptions.filter((option) => { return option.value != uuidUser });
    setUserOptions(userOptions);
  }

  /* DB Functions */
  async function fetchRole(uuid: string) {
    try {
      if (!organization.data) return;
      setRoleLoading(true);
      const role = await vigil.functions.findOneRole({ uuidOrganization: organization.data.uuid, uuid: uuid });
      setRole(role);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setRoleLoading(false);
    }
  }

  async function fetchLinkedUsers() {
    try {
      if (!organization.data) return;
      setUsersLoading(true);
      const users = await vigil.functions.findManyUsersLinkedToRole({ uuidOrganization: organization.data.uuid, uuidRole: props.uuidRole });
      setLinkedUsers(users);
      setUsersLoading(false);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setUsersLoading(false);
    }
  }

  async function fetchUnlinkedUsers() {
    try {
      if (!organization.data) return;
      setUsersLoading(true);
      const unlinkedUsers = await vigil.functions.findManyUsersUnlinkedFromRole({ uuidOrganization: organization.data.uuid, uuidRole: props.uuidRole });
      setUnlinkedUsers(unlinkedUsers);
      const userOptions: OptionSearchDropdown[] = unlinkedUsers.map((user) => {
        return { label: `${user.firstName} ${user.lastName}`, value: user.uuid }
      });
      setUserOptions(userOptions);
      setUsersLoading(false);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setUsersLoading(false);
    }
  }

  async function linkUsersToRole() {
    try {
      if (!stateRole || !organization.data) return;
      setUsersLoading(true);
      const uuidUsers = stateNewUsers.map((user) => { return user.uuid });
      await vigil.functions.linkUsersToRoles({ uuidOrganization: organization.data.uuid, userUuids: uuidUsers, roleUuids: [stateRole.uuid] });
      props.onSubmit && await props.onSubmit();
      props.toggle();
      resetCalls();
    } catch (error: any) {
      setError(error.message);
    } finally {
      setUsersLoading(false);
    }
  }

  if (stateRoleLoading || stateUsersLoading || !stateRole) {
    return (
      <Modal isOpen={props.isOpen} toggle={props.toggle} closeOnBackgroundClick={false} className="w-92">
        <StatusLoading />
      </Modal>
    )
  }

  if (stateUnlinkedUsers.length == 0 && stateNewUsers.length == 0) {
    return (
      <Modal isOpen={props.isOpen} toggle={props.toggle} closeOnBackgroundClick={false} className="w-92">
        <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2" onClick={() => props.toggle()}>✕</button>
        <h3 className="font-bold text-lg pb-2">Need to add users first</h3>
        <div>Please add users to link them to this role</div>
      </Modal>
    )
  }

  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle} closeOnBackgroundClick={false} className="w-192">
      <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2" onClick={() => props.toggle()}>✕</button>
      <h3 className="font-bold text-lg pb-2">Link users to {stateRole.name}</h3>
      <div className="flex flex-wrap">
        {stateLinkedUsers.map((user, index) => {
          return (
            <div key={index} className='flex items-center justify-center bg-primary-content rounded-3xl h-8 text-sm px-4 mr-2 mb-1'>
              {`${user.firstName} ${user.lastName}`}
            </div>
          )
        })}
        {stateNewUsers.map((user, index) => {
          return (
            <div key={index} className='flex items-center justify-center bg-secondary-content rounded-3xl h-8 text-sm px-4 mr-2 mb-1'>
              <div>{`${user.firstName} ${user.lastName}`}</div>
              <button onClick={() => { clearNewUser(user) }}>
                <IconXCircleSolid className='ml-2' style={{ height: '18px' }} />
              </button>
            </div>
          )
        })}
      </div>
      <div className="flex">
        <InputSearchDropdown options={stateUserOptions} labelText={"Link new User"} errors={[]} onSelect={(val) => addNewUser(val.toString())} ></InputSearchDropdown>
      </div>
      {stateError && <StatusAlert type='alert-error' message={stateError} />}
      <div className="flex justify-end pt-4">
        <InputButton text='Link Users' loading={false} disabled={stateNewUsers.length == 0} type='btn-primary' onClick={async () => {
          await linkUsersToRole();
        }}></InputButton>
      </div>
    </Modal>
  )
}