import useBreakpoints from '../../hooks/use_breakpoints';
import React, { useContext } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Navbar } from '../../components/navbar/navbar';
import { ContextUser } from '../../providers/provider_user';
import { IconBuildings, IconSwatch, IconUser } from '../../components/icons';
import { ROUTES } from '../../router/routes';
import { Tabs } from '../../components/tabs';

interface ScreenProfileProps { }

export const ScreenProfile: React.FC<ScreenProfileProps> = (props) => {
  const breakpoints = useBreakpoints();
  const navigate = useNavigate();
  const location = useLocation();
  const user = useContext(ContextUser);

  const menuItems = [
    {
      id: 'item_info',
      text: 'Basic Info',
      icon: <IconUser />,
      route: ROUTES.ROUTE_PROFILE_BASIC_INFO
    },
    {
      id: 'item_appearance',
      text: 'Appearance',
      icon: <IconSwatch />,
      route: ROUTES.ROUTE_PROFILE_APPEARANCE
    },
    {
      id: 'item_organizations',
      text: 'Organizations',
      icon: <IconBuildings />,
      route: ROUTES.ROUTE_PROFILE_ORGANIZATIONS
    }
  ]

  function navigateTab(pageId: string) {
    navigate({
      pathname: ROUTES.ROUTE_PROFILE + '/' + pageId,
    })
  }

  function getSelectedId() {
    return location.pathname.split('/').pop() || '';
  }

  return (
    <div className='h-full flex flex-col'>
      <div><Navbar /></div>
      <div className='h-full flex overflow-y-auto'>
        <div className="w-full md:container md:mx-auto px-4">
          <div className='flex m-4'>
            <div className='mr-4'>
              <img src={`https://ui-avatars.com/api/?name=${user.data?.firstName[0]}&background=random`} className="w-16 h-16 rounded-full" alt="" />
            </div>
            <div>
              <div className='text-xl'> {user.data?.firstName + ' ' + user.data?.lastName} </div>
              <div className='text-md'> Your personal account </div>
            </div>
          </div>

          <Tabs selectedId={getSelectedId()} onSelect={navigateTab} items={[
            {
              id: 'basic_info',
              text: 'Basic Info',
              icon: IconUser
            },
            {
              id: 'appearance',
              text: 'Appearance',
              icon: IconSwatch
            },
            {
              id: 'organizations',
              text: 'Organizations',
              icon: IconBuildings
            }
          ]} />

          <div className='w-full flex-grow'>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ScreenProfile
