import React, { useContext, useState } from 'react';
import { useModal } from '../../hooks/use_modal';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { InputButton } from '../../components/input_button';
import { IconPencilSquareSolid, IconTrashSolid } from '../../components/icons';
import { ROUTES } from '../../router/routes';
import { IReportSelect } from 'vigil-datamodel';
import { ModalReportUpdate } from './func_report/modal_report_update';
import { BaseReportConfig, ReportConfigType } from 'vigil-datamodel/src/bean_report';
import { ReportPatrol } from './func_report/report_patrol';
import { InputText } from '../../components/input_text';
import { VALIDATORS, validate } from '../../validation';
import { InputSelect, OptionSelect } from '../../components/input_select';
import { reportConfigOptions } from './func_report/helper_report';
import { ContextVigilClient } from '../../providers/provider_vigil_client';
import { ContextOrganization } from '../../providers/provider_organization';
import { ModalDeleteMany } from './modal_delete_many';

interface ScreenHomeReportOVerviewProps { }

export const ScreenHomeReportOVerview: React.FC<ScreenHomeReportOVerviewProps> = (props) => {
  const navigate = useNavigate()
  const vigil = useContext(ContextVigilClient);
  const organization = useContext(ContextOrganization);

  const { reportParent, fetchReportParent } = useOutletContext<{
    reportParent: IReportSelect,
    fetchReportParent: () => void
  }>();

  const [stateReportModify, setReportModify] = useState<IReportSelect>(reportParent);
  const [stateValidateFormChild, setValidateFormChild] = useState<string[]>([]);

  const [stateSelectedConfig, setSelectedConfig] = useState<OptionSelect>(
    reportConfigOptions.filter((config) => config.value == reportParent.config.type)[0]
  );

  const { isOpen: isOpenModalDeleteReport, toggle: toggleModalDeleteReport } = useModal();
  const { isOpen: isOpenModalUpdateReport, toggle: toggleModalUpdateReport } = useModal();

  function onChangeName(event: React.ChangeEvent<HTMLInputElement>) {
    setReportModify({
      ...stateReportModify,
      name: event.target.value
    })
  }

  function onChangeSelectedConfig(event: React.ChangeEvent<HTMLSelectElement>) {
    const config = reportConfigOptions.find((config) => config.value == parseInt(event.target.value));
    if (config) {
      setSelectedConfig(config);
    }
  }

  function onChangeReportConfig(config: BaseReportConfig) {
    setReportModify({
      ...stateReportModify,
      config: config as any
    })
  }

  /* Validation */
  function validateName() {
    if (!stateReportModify.name) return [];
    return validate(stateReportModify.name, [VALIDATORS.length('Report name', 2, 30)]);
  }

  function validateForm() {
    const required = [];
    if (JSON.stringify(stateReportModify) === JSON.stringify(reportParent)) { required.push('No changes detected') }
    return [
      ...required,
      ...validateName(),
      ...stateValidateFormChild,
    ];
  }

  async function _deleteReport() {
    if (!organization.data) return;
    await vigil.functions.deleteReports({
      uuidOrganization: organization.data.uuid,
      uuids: [reportParent.uuid]
    })
  }

  return (
    <div className='py-2'>
      <ModalDeleteMany
        isOpen={isOpenModalDeleteReport}
        toggle={toggleModalDeleteReport}
        type='reports'
        data={[{ uuid: reportParent.uuid, label: reportParent.name }]}
        onSubmit={async () => navigate(ROUTES.ROUTE_HOME_REPORTS)}
        deleteCallback={_deleteReport}
      />
      <ModalReportUpdate isOpen={isOpenModalUpdateReport} toggle={toggleModalUpdateReport} report={stateReportModify} onSubmit={async () => { fetchReportParent(); }} />
      <div className='rounded-t-xl bg-base-300 p-2'>
        <div className='flex items-center'>
          <div className='font-semibold flex flex-grow'>Overview</div>
          <InputButton text='Save' before={<IconPencilSquareSolid className='h-5 mr-1' />} type='btn-primary' size='btn-sm' disabled={validateForm().length > 0} onClick={toggleModalUpdateReport} />
          <div className='w-2'></div>
          <InputButton text='Delete' before={<IconTrashSolid className='h-5 mr-1' />} type='btn-error' size='btn-sm' loading={false} onClick={toggleModalDeleteReport} />
        </div>
      </div>

      <div className='rounded-b-xl bg-base-200 p-2 flex-grow'>
        <div className="flex flex-wrap">
          <InputText className="w-60 mb-2 mr-4" labelText='Report name' value={stateReportModify.name} onChange={onChangeName} errors={validateName()} > </InputText>
          <InputSelect className="w-48 mb-2 mr-4" errors={[]} labelText="Report Config" value={stateSelectedConfig.value} options={reportConfigOptions} onChange={onChangeSelectedConfig}> </InputSelect>
        </div>
        {reportParent.config.type == ReportConfigType.Patrol && <ReportPatrol config={stateReportModify.config} setConfig={onChangeReportConfig} setValidateForm={setValidateFormChild} />}
      </div>
    </div>
  )
};
