import React, { useContext, useEffect, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { IBeaconSelect, ISiteSelect } from 'vigil-datamodel';
import { ContextVigilClient } from '../../providers/provider_vigil_client';
import { ROUTES } from '../../router/routes';
import { IconOutlineInformationCircle, IconDeviceMobile } from '../../components/icons';
import { ContextOrganization } from '../../providers/provider_organization';
import { StatusAlert } from '../../components/status_alert';
import { FullPageLoader } from '../../components/full_page_loader';
import { Breadcrumbs } from '../../components/breadcrumbs';
import { Tabs } from '../../components/tabs';

export const ScreenHomeSite: React.FC = () => {
  const navigate = useNavigate();
  const organization = useContext(ContextOrganization);
  const vigil = useContext(ContextVigilClient);

  const [stateError, setError] = useState<string>('');
  const [stateSiteLoading, setSiteLoading] = useState(true);
  const [stateSite, setSite] = useState<ISiteSelect>();
  const [stateBeaconsLoading, setBeaconsLoading] = useState(true);
  const [stateBeacons, setBeacons] = useState<IBeaconSelect[]>();

  const params = useParams();
  const uuidSite = params['uuid'] || '';

  const isLoading = stateSiteLoading || stateBeaconsLoading || !stateSite;

  /* State */
  useEffect(() => {
    fetchSite(uuidSite);
    fetchBeacons(uuidSite);
  }, [uuidSite]);

  /* Functions */
  async function fetchSite(uuid: string) {
    try {
      if (!organization.data) throw new Error('No organization data');
      setSiteLoading(true);
      const site = await vigil.functions.findOneSite({ uuidOrganization: organization.data.uuid, uuid: uuid });
      setSite(site);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setSiteLoading(false);
    }
  }

  async function fetchBeacons(uuidSite: string) {
    try {
      if (!organization.data) throw new Error('No organization data');
      setBeaconsLoading(true);
      const beacons = await vigil.functions.findManyBeaconsLinkedToSite({ uuidOrganization: organization.data.uuid, uuidSite });
      setBeacons(beacons);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setBeaconsLoading(false);
    }
  }

  function navigateTab(id: string) {
    navigate(ROUTES.ROUTE_HOME_SITE.replace(':uuid', uuidSite) + '/' + id)
  }

  function getSelectedId() {
    return location.pathname.split('/').pop() || '';
  }

  if (isLoading) return <FullPageLoader />;
  if (stateError) return <div className='px-8 py-4 h-full w-full'><StatusAlert message={stateError} type='alert-error' /></div>;

  return (
    <div className='px-4 pb-2 w-full flex flex-col h-full'>
      <Breadcrumbs crumbs={[
        { id: 'sites', onClick: () => navigate(ROUTES.ROUTE_HOME_SITES), text: 'Sites' },
        { id: uuidSite, onClick: () => { }, text: stateSite?.name || '' }
      ]} />

      <div className="mb-2">
        <div className="border-b border-base-300"></div>
      </div>

      <Tabs selectedId={getSelectedId()} onSelect={navigateTab} items={[
        {
          icon: IconOutlineInformationCircle,
          id: 'overview',
          text: 'Overview'
        },
        {
          icon: IconDeviceMobile,
          id: 'device_behaviours',
          text: 'Device Behaviours'
        },
      ]} />

      <div className='w-full flex-grow'>
        <Outlet context={{ site: stateSite, fetchSite, beacons: stateBeacons, fetchBeacons }} />
      </div>
    </div>
  );
};