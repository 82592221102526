import { FC, useContext, useEffect, useState } from 'react';
import { ChartLineDateTime } from '../../components/charts/chart_line_datetime';
import { ContextVigilClient } from '../../providers/provider_vigil_client';
import { useParams } from 'react-router-dom';
import { TimeFrame } from 'vigil-datamodel';
import { InputSelect } from '../../components/input_select';
import { VigilMap, VigilMapController, Coordinate, VigilMapMessageId, VigilMapV2Props, VigilMapV2ThemeId, getBoundingBox } from 'vigil-map';
import { ContextTheme } from '../../providers/provider_theme';
import { useCaller } from '../../hooks/use_caller';
import { ContextOrganization } from '../../providers/provider_organization';

interface ScreenHomeDeviceHistoryStatisticsProps { }


export const ScreenHomeDeviceHistoryStatistics: FC<ScreenHomeDeviceHistoryStatisticsProps> = (props) => {
  const organization = useContext(ContextOrganization);
  const vigil = useContext(ContextVigilClient);
  const params = useParams();
  const uuidDevice = params['uuid'] || '';
  const theme = useContext(ContextTheme);

  const [stateTimeFrame, setTimeFrame] = useState(TimeFrame.Hour1);
  const [stateMapController, setMapController] = useState(null as null | VigilMapController);
  const [stateMapProps, setMapProps] = useState({} as VigilMapV2Props);
  const [stateSnapped, setSnapped] = useState(false);

  function getHistoryCharge() {
    const result = [];
    for (const item of stateDeviceHistory.result || []) {
      result.push({
        date: item.date,
        value: item.chargeLevel
      })
    }
    return result;
  }

  // Live state for getting server representation of device history
  const stateDeviceHistory = useCaller({
    callback: async () => {
      if (!organization.data) return [];
      const result = await vigil.functions.deviceHistory({ uuidOrganization: organization.data.uuid, uuidDevice: uuidDevice, timeframe: stateTimeFrame });
      return result.items;
    },
    dependencies: { background: [], normal: [stateTimeFrame] },
    intervalTime: 5000
  });

  // Fit bounds
  useEffect(() => {
    const mapProps: VigilMapV2Props = {};

    if (stateDeviceHistory.result && stateDeviceHistory.result.length > 0) {
      const newest = stateDeviceHistory.result[stateDeviceHistory.result.length - 1];
      mapProps.devices = [];
      mapProps.devices.push({
        uuid: uuidDevice,
        lat: newest.lat,
        lng: newest.lng,
        lastSeen: newest.date,
        chargeLevel: newest.chargeLevel,
        chargeState: newest.chargeState,
        history: stateDeviceHistory.result.map((history) => { return { lat: history.lat, lng: history.lng, acc: history.acc } })
      });
    }

    if (theme.data == 'dark') {
      mapProps.theme = VigilMapV2ThemeId.DARK_MATTER;
    }

    setMapProps(mapProps);
  }, [stateDeviceHistory.result]);

  // Fit bounds
  useEffect(() => {
    const points: Coordinate[] = [];
    stateMapProps.beacons?.forEach((beacon) => {
      points.push({ lat: beacon.lat, lng: beacon.lng });
    });
    stateMapProps.devices?.forEach((device) => {
      points.push({ lat: device.lat, lng: device.lng });
      device.history?.forEach((history) => {
        points.push({ lat: history.lat, lng: history.lng });
      })
    });
    if (stateMapController && !stateSnapped && points.length > 0) {
      setSnapped(true);
      const bounds = getBoundingBox(points);
      stateMapController?.sendVigilMessage({
        id: VigilMapMessageId.FIT_BOUNDS,
        bounds: bounds,
        options: {
          maxZoom: 15,
          padding: {
            left: 150,
            right: 150,
            top: 150,
            bottom: 150
          }
        }
      })
    }
  }, [stateMapProps]);

  useEffect(() => {
    // Fit bounds
    const points: Coordinate[] = [];
    stateMapProps.beacons?.forEach((beacon) => {
      points.push({ lat: beacon.lat, lng: beacon.lng });
    });
    stateMapProps.devices?.forEach((device) => {
      points.push({ lat: device.lat, lng: device.lng });
      device.history?.forEach((history) => {
        points.push({ lat: history.lat, lng: history.lng });
      })
    });
    if (stateMapController && !stateSnapped && points.length > 0) {
      setSnapped(true);
      const bounds = getBoundingBox(points);
      stateMapController?.sendVigilMessage({
        id: VigilMapMessageId.FIT_BOUNDS,
        bounds: bounds,
        options: {
          maxZoom: 15,
          padding: {
            left: 150,
            right: 150,
            top: 150,
            bottom: 150
          }
        }
      })
    }
  }, [stateMapProps, stateMapController]);

  function getMinTime() {
    // TODO: Clean this up and move to the datamodal so it can be reused
    switch (stateTimeFrame) {
      case (TimeFrame.Minute15):
        return new Date(new Date().getTime() - 1000 * 60 * 15)
      case (TimeFrame.Hour1):
        return new Date(new Date().getTime() - 1000 * 60 * 60)
      case (TimeFrame.Hour6):
        return new Date(new Date().getTime() - 1000 * 60 * 60 * 6)
      case (TimeFrame.Day1):
        return new Date(new Date().getTime() - 1000 * 60 * 60 * 24)
      case (TimeFrame.Day3):
        return new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 3)
      case (TimeFrame.Week1):
        return new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 7)
      case (TimeFrame.Week2):
        return new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 7 * 2)
      default:
        return undefined
    }
  }

  return (
    <div className='h-full w-full flex flex-col'>
      <div className='relative h-full'>
        <div className='absolute w-full h-full overflow-y-scroll px-1 m-2'>
          <InputSelect
            labelText='Time Frame'
            errors={[]}
            value={stateTimeFrame}
            onChange={(value) => { setTimeFrame(value.target.value as TimeFrame) }}
            options={[
              {
                label: TimeFrame.Hour1,
                value: TimeFrame.Hour1
              },
              {
                label: TimeFrame.Hour6,
                value: TimeFrame.Hour6
              },
              {
                label: TimeFrame.Day1,
                value: TimeFrame.Day1
              },
              {
                label: TimeFrame.Day3,
                value: TimeFrame.Day3
              },
              {
                label: TimeFrame.Week1,
                value: TimeFrame.Week1
              },
              {
                label: TimeFrame.Week2,
                value: TimeFrame.Week2
              }
            ]}
          />

          <div className='my-4'>
            <div className='my-2'></div>
            <ChartLineDateTime values={getHistoryCharge()} label='Charge Level' minValue={0} maxValue={100} loading={stateDeviceHistory.loading} minTime={getMinTime()} maxTime={new Date()} />
          </div>

          <div className='my-4 rounded-xl overflow-hidden h-128'>
            <VigilMap setMapController={setMapController} state={stateMapProps} loading={stateDeviceHistory.loading} />
          </div>
        </div>
      </div>
    </div>
  );
};
