import React from 'react'
console.log(typeof React)
import { Behaviour } from '../base/config_sdk_behaviour'

const SOUND_ID = "sign_in_sound"
const SCREEN_ID = "user_sign_in_at|modal"

interface SignInLog {
  msg: string;
}

export const BehaviourSignIn: Behaviour = {
  config: {
    id: "user_sign_in_at",
    name: "User Sign In At",
    description: "Behaviour to alert user that they should sign in",
    parameters: [
      {
        id: "start_cron",
        name: "Start Time",
        description: "From when should the user be signed in",
        required: true,
        type: "cron"
      },
      {
        id: "duration_hours",
        name: "Duration (hours)",
        description: "For how long should the user be signed in",
        required: true,
        type: "numberInt"
      }
    ],
    screens: [
      {
        id: SCREEN_ID,
        code: (context) => {
          const c = context.components;
          return (<>
            <c.Modal isOpen={true} onClose={() => { }}>
              <c.Text variant="h3" margin={2} color="black" center={true}>Sign In Required</c.Text>
              <c.Text variant="p" margin={6} color="black" center={true}>User sign in is required in order to stop the alarm.</c.Text>
              <c.Button
                text="OK"
                variant="btn-primary"
                margin={4}
                onPress={async () => {
                  context.device.setAudio(null)
                  context.device.setScreen(null)
                }}
              />
            </c.Modal>
          </>)
        }
      }
    ],
    canLinkTo: ["device", "site"]
  },
  device: {
    tick: async (context) => {
      // Destructure context
      const { vigil, device, behaviour } = context;

      // If user is signed in, skip tick
      if (await device.getUser()) return

      // If user should not be signed in, skip tick
      const now = new Date();
      const start_cron = behaviour.getParameterValue<string>("start_cron")!;
      const duration_hours = behaviour.getParameterValue<number>("duration_hours")!;
      const timeslot = vigil.getTimeslotFromCron(start_cron, duration_hours * 60 * 60 * 1000);
      const behaviourInstance = await behaviour.getInstance(timeslot.start, timeslot.end, now)

      // If there is no active instance and there should not be one, exit early
      if (!behaviourInstance) return

      // Get current screen
      const screen = device.getScreen();

      // If there is no modal, or a different model, show modal
      if (!screen || screen.id != SCREEN_ID) {
        device.setAudio({ id: SOUND_ID, base64Audio: context.vigil.audioBase64.alert_1, loop: 100 });
        device.setScreen({ id: SCREEN_ID });
      }

      // Create log
      const lastLog = await behaviourInstance.getDeviceLastCreatedLog<SignInLog>(["sign_in"], [(await device.getDevice()).uuid])

      // Only create log if it has been more than 10 minutes since the last log
      if (!lastLog || now > new Date(lastLog.datetime.valueOf() + 10 * 60 * 1000)) {
        await behaviourInstance.createLog<SignInLog>({ msg: "Sign in alert" }, ["sign_in"])
      }
    },
  },
  server: {
    tick: async (context) => {
      // Destructure context
      const { vigil, device, behaviour } = context;

      // If user is signed in, skip tick
      if (await device.getUser()) return

      // If user should not be signed in, skip tick
      const now = new Date();
      const start_cron = behaviour.getParameterValue<string>("start_cron")!;
      const duration_hours = behaviour.getParameterValue<number>("duration_hours")!;
      const timeslot = vigil.getTimeslotFromCron(start_cron, duration_hours * 60 * 60 * 1000);
      const behaviourInstance = await behaviour.getInstance(timeslot.start, timeslot.end, now)

      // If there is no active instance and there should not be one, exit early
      if (!behaviourInstance) return

      // If user has not signed in for 10 minutes, send notification
      const lastLog = await behaviourInstance.getServerLastCreatedLog<SignInLog>(["sign_in"])
      if (now > new Date(timeslot.start.valueOf() + 10 * 60 * 1000) && (!lastLog || now > new Date(lastLog.datetime.valueOf() + 10 * 60 * 1000))) {
        // Create log
        await behaviourInstance.createLog<SignInLog>({ msg: "Sign in missed" }, ["sign_in"])
      }
    }
  }
}
