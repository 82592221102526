import { ReactNode, createContext } from "react";
import { VigilClient } from "vigil-client";
import { FullPageLoader } from "../components/full_page_loader";
import { FullPageError } from "../components/full_page_error";
import { Environment } from "../environment";
import { useCaller } from "../hooks/use_caller";
import { Preferences } from "../preference_keys";

export const ContextVigilClient = createContext(null as unknown as VigilClient);

interface ProviderVigilClientProps {
  children: ReactNode
}
export const ProviderVigilClient: React.FC<ProviderVigilClientProps> = (props: ProviderVigilClientProps) => {
  /* State */
  const client = useCaller({
    callback: async () => {
      return new VigilClient({ 
        functionsURL: Environment.SERVICE_BACKEND_HOST_URL + '/fn',
        tokenCallback: () => localStorage.getItem(Preferences.TOKEN),
      });
    },
  });

  if (client.loading || !client.result) return <FullPageLoader location={"vigil client"}/>
  if (client.error) return <FullPageError error={client.error} />

  return (
    <ContextVigilClient.Provider value={client.result}>
      {props.children}
    </ContextVigilClient.Provider>
  )
}
