import { useContext, useEffect, useState } from "react";
import { InputButton } from "../../../components/input_button";
import { ContextVigilClient } from "../../../providers/provider_vigil_client";
import { Modal, ModalProps } from "../../../components/modal";
import { IBeaconSelect, ISiteSelect } from "vigil-datamodel";
import { StatusAlert } from "../../../components/status_alert";
import { ContextOrganization } from "../../../providers/provider_organization";
import { IconXCircleSolid } from "../../../components/icons";
import { InputSearchDropdown, OptionSearchDropdown } from "../../../components/input_search_dropdown";
import { StatusLoading } from "../../../components/status_loading";

interface ModalBeaconSitesCreateProps extends ModalProps {
  uuidBeacon: string;
  onSubmit?: () => Promise<void>;
}

export const ModalBeaconSitesCreate: React.FC<ModalBeaconSitesCreateProps> = (props) => {
  const organization = useContext(ContextOrganization);
  const vigil = useContext(ContextVigilClient);

  const [stateError, setError] = useState<string>('');
  const [stateBeaconLoading, setBeaconLoading] = useState(true);
  const [stateBeacon, setBeacon] = useState<IBeaconSelect>();

  const [stateSitesLoading, setSitesLoading] = useState(true);
  const [stateLinkedSites, setLinkedSites] = useState<ISiteSelect[]>([]);
  const [stateUnlinkedSites, setUnlinkedSites] = useState<ISiteSelect[]>([]);
  const [stateNewSites, setNewSites] = useState<ISiteSelect[]>([]);
  const [stateSiteOptions, setSiteOptions] = useState<OptionSearchDropdown[]>([]);

  useEffect(() => { fetchBeacon(props.uuidBeacon); }, [props.uuidBeacon]);
  useEffect(() => { resetCalls() }, [props.isOpen]);

  /* Functions */
  function resetCalls() {
    setNewSites([]);
    fetchLinkedSites();
    fetchUnlinkedSites();
  }

  function clearNewSite(site: ISiteSelect) {
    const newBeacons = stateNewSites.filter((_site) => { return _site.uuid != site.uuid });
    setNewSites(newBeacons);

    const unlinkedSites = stateUnlinkedSites;
    unlinkedSites.push(site);
    setUnlinkedSites(unlinkedSites);

    const siteOptions = stateSiteOptions;
    siteOptions.push({ label: site.name, value: site.uuid });
    setSiteOptions(siteOptions);
  }

  function addNewSite(uuidSite: string) {
    const site: ISiteSelect = stateUnlinkedSites.filter((_site) => { return _site.uuid == uuidSite })[0];

    const newSites = stateNewSites;
    newSites.push(site);
    setNewSites(newSites);

    const unlinkedSites = stateUnlinkedSites.filter((_site) => { return _site.uuid != uuidSite });
    setUnlinkedSites(unlinkedSites);

    const siteOptions = stateSiteOptions.filter((option) => { return option.value != uuidSite });
    setSiteOptions(siteOptions);
  }

  /* DB Functions */
  async function fetchBeacon(uuid: string) {
    try {
      if (!organization.data) return;
      setBeaconLoading(true);
      const beacon = await vigil.functions.findOneBeacon({ uuidOrganization: organization.data.uuid, uuid });
      setBeacon(beacon);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setBeaconLoading(false);
    }
  }

  async function fetchLinkedSites() {
    try {
      if (!organization.data) return;
      setSitesLoading(true);
      const sites = await vigil.functions.findManySitesLinkedToBeacon({ uuidOrganization: organization.data.uuid, uuidBeacon: props.uuidBeacon });
      setLinkedSites(sites);
      setSitesLoading(false);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setSitesLoading(false);
    }
  }

  async function fetchUnlinkedSites() {
    try {
      if (!organization.data) return;
      setSitesLoading(true);
      const unlinkedSites = await vigil.functions.findManySitesUnlinkedFromBeacon({ uuidOrganization: organization.data.uuid, uuidBeacon: props.uuidBeacon });
      setUnlinkedSites(unlinkedSites);
      const siteOptions: OptionSearchDropdown[] = unlinkedSites.map((site) => {
        return { label: site.name, value: site.uuid }
      });
      setSiteOptions(siteOptions);
      setSitesLoading(false);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setSitesLoading(false);
    }
  }

  async function linkSitesToBeacon() {
    try {
      if (!stateBeacon || !organization.data) return;
      setSitesLoading(true);
      const uuidSites = stateNewSites.map((site) => { return site.uuid });
      await vigil.functions.linkSitesToBeacons({ uuidOrganization: organization.data.uuid, siteUuids: uuidSites, beaconUuids: [stateBeacon.uuid] });
      props.onSubmit && await props.onSubmit();
      props.toggle();
      resetCalls();
    } catch (error: any) {
      setError(error.message);
    } finally {
      setSitesLoading(false);
    }
  }

  if (stateBeaconLoading || stateSitesLoading || !stateBeacon) {
    return (
      <Modal isOpen={props.isOpen} toggle={props.toggle} closeOnBackgroundClick={false} className="w-92">
        <StatusLoading />
      </Modal>
    )
  }

  if (stateUnlinkedSites.length == 0 && stateNewSites.length == 0) {
    return (
      <Modal isOpen={props.isOpen} toggle={props.toggle} closeOnBackgroundClick={false} className="w-92">
        <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2" onClick={() => props.toggle()}>✕</button>
        <h3 className="font-bold text-lg pb-2">Need to add sites first</h3>
        <div>Please add sites to link them to this beacon</div>
      </Modal>
    )
  }

  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle} closeOnBackgroundClick={false} className="w-192">
      <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2" onClick={() => props.toggle()}>✕</button>
      <h3 className="font-bold text-lg pb-2">Link sites to {stateBeacon.name}</h3>
      <div className="flex flex-wrap">
        {stateLinkedSites.map((site, index) => {
          return (
            <div key={index} className='flex items-center justify-center bg-primary-content rounded-3xl h-8 text-sm px-4 mr-2 mb-1'>
              {site.name}
            </div>
          )
        })}
        {stateNewSites.map((site, index) => {
          return (
            <div key={index} className='flex items-center justify-center bg-secondary-content rounded-3xl h-8 text-sm px-4 mr-2 mb-1'>
              <div>{site.name}</div>
              <button onClick={() => { clearNewSite(site) }}>
                <IconXCircleSolid className='ml-2' style={{ height: '18px' }} />
              </button>
            </div>
          )
        })}
      </div>
      <div className="flex">
        <InputSearchDropdown options={stateSiteOptions} labelText={"Link new Site"} errors={[]} onSelect={(val) => addNewSite(val.toString())} ></InputSearchDropdown>
      </div>
      {stateError && <StatusAlert type='alert-error' message={stateError} />}
      <div className="flex justify-end pt-4">
        <InputButton text='Link Sites' loading={false} disabled={stateNewSites.length == 0} type='btn-primary' onClick={async () => { await linkSitesToBeacon() }}></InputButton>
      </div>
    </Modal>
  )
}