import React, { useState } from 'react';
import { TabDevices } from './home_devices_devices';
import { TabDeviceBehaviours } from './home_device_behaviours';

interface ScreenHomeDevicesDirectoryProps { }

export const ScreenHomeDevicesDirectory: React.FC<ScreenHomeDevicesDirectoryProps> = (props) => {
  const [stateActiveTabId, setActiveTabId] = useState<'devices' | 'behaviours'>('devices');

  function tabClass(tabId: 'devices' | 'behaviours') {
    let style = "tab tab-lifted tab-md ";
    if (stateActiveTabId == tabId) {
      style += 'tab-active'
    }
    return style;
  }

  return (
    <div className='p-4 h-full w-full flex flex-col'>
      <div className="tabs flex">
        <a onClick={() => setActiveTabId('devices')} className={tabClass('devices')}>Devices</a>
        <a onClick={() => setActiveTabId('behaviours')} className={tabClass('behaviours')}>Device Behaviours</a>
      </div>
      {stateActiveTabId == 'devices' && <TabDevices />}
      {stateActiveTabId == 'behaviours' && <TabDeviceBehaviours />}
    </div>
  );
};