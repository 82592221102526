import { integer, real, sqliteTable, text } from "drizzle-orm/sqlite-core";
import { dashSessions } from "./bean_dash_session";
import { schemaBase } from "./bean_base";
import { relations } from "drizzle-orm";

export const dashStateLogs = sqliteTable('dash_state_logs', {
  ...schemaBase(),
  uuidSession: text('uuid_session').notNull().references(() => dashSessions.uuid),
  effectiveType: text('effective_type'),
  downlink: real('downlink'),
  roundTripTime: real('round_trip_time'),
  isOnline: integer('is_online').notNull(),
});

export const dashStateLogsRelations = relations(dashStateLogs, ({ one }) => ({
  session: one(dashSessions, {
    fields: [dashStateLogs.uuidSession],
    references: [dashSessions.uuid],
  }),
}));