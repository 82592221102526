import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IOrganizationRoleSelect, IUserSelect, Permissions } from 'vigil-datamodel';
import { FullPageLoader } from '../../components/full_page_loader';
import { ContextVigilClient } from '../../providers/provider_vigil_client';
import { Breadcrumbs } from '../../components/breadcrumbs';
import { ROUTES } from '../../router/routes';
import { InputButton } from '../../components/input_button';
import { IconBrokenLinkOutline, IconChevronRightSolid, IconLinkSolid, IconPencilSquareSolid, IconPlusSolid, IconTrashSolid } from '../../components/icons';
import { TTuuid } from 'tt-uuid';
import { useModal } from '../../hooks/use_modal';
import { StatusAlert } from '../../components/status_alert';
import { ModalRoleUsersCreate } from './func_role_user/modal_role_users_create';
import { ModalRoleUsersDelete } from './func_role_user/modal_role_users_delete';
import { ContextOrganization } from '../../providers/provider_organization';
import { UIPermission, UiPermissions } from './func_role/helper_role';
import { TTActions, TTPermissionSet } from 'tt-permissions';
import { ModalRoleUpdate } from './func_role/modal_role_update';
import { ContextRoles } from '../../providers/provider_roles';
import { ModalUserInviteCreate } from './func_user_invite/modal_user_invite_create';
import { ModalDeleteMany } from './modal_delete_many';

interface ScreenHomeRoleProps { }

export const ScreenHomeRole: React.FC<ScreenHomeRoleProps> = (props) => {
  /* State */
  const navigate = useNavigate()
  const organization = useContext(ContextOrganization);
  const vigil = useContext(ContextVigilClient);
  const contextRoles = useContext(ContextRoles);

  const [stateError, setError] = useState<string>('');

  const [stateRoleLoading, setRoleLoading] = useState(true);
  const [stateRole, setRole] = useState<IOrganizationRoleSelect>();

  const [stateUsersLoading, setUsersLoading] = useState(true);
  const [stateUsers, setUsers] = useState<IUserSelect[]>();

  const [stateSelectedUser, setSelectedUser] = useState<IUserSelect>();

  const [statePermissions, setPermissions] = useState<UIPermission[]>(UiPermissions);

  const { isOpen: isOpenModalUpdateRole, toggle: toggleModalUpdateRole } = useModal();
  const { isOpen: isOpenModalDeleteRole, toggle: toggleModalDeleteRole } = useModal();
  const { isOpen: isOpenModalInviteUser, toggle: toggleModalInviteUser } = useModal();
  const { isOpen: isOpenModalDeleteRoleUsers, toggle: toggleModalDeleteRoleUsers } = useModal();
  const { isOpen: isOpenModalCreateRoleUsers, toggle: toggleModalCreateRoleUsers } = useModal();

  const params = useParams();
  const uuidRole = params['uuid'] || '';

  useEffect(() => { fetchRole(uuidRole); fetchUsers(uuidRole); }, [uuidRole]);

  async function fetchRole(uuid: string) {
    try {
      if (!organization.data) return;
      setRoleLoading(true);
      const role = await vigil.functions.findOneRole({ uuidOrganization: organization.data.uuid, uuid: uuid });
      if (!role) throw new Error('Role not found');
      setRole(role);

      const permissionsSet = new TTPermissionSet(Permissions.schemaOrganization);
      permissionsSet.FromString(role.permissionString);
      const permissions = UiPermissions;
      for (const permission of permissions) {
        if (permission.parentPermission) {
          permission.parentPermission.selected = permissionsSet.Can(permission.parentPermission.action, permission.parentPermission.resource);
        }
        for (const childPermission of permission.childPermissions) {
          childPermission.selected = permissionsSet.Can(childPermission.action, childPermission.resource);
        }
      }
      setPermissions(permissions);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setRoleLoading(false);
    }
  }

  async function fetchUsers(uuidRole: string) {
    if (!organization.data) return;
    try {
      setUsersLoading(true);
      const users = await vigil.functions.findManyUsersLinkedToRole({ uuidOrganization: organization.data.uuid, uuidRole: uuidRole });
      setUsers(users);
      setUsersLoading(false);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setUsersLoading(false);
    }
  }

  function toggleSelectedUser(user: IUserSelect) {
    if (stateSelectedUser == user) {
      setSelectedUser(undefined);
    } else {
      setSelectedUser(user);
    }
  }

  async function _deleteRole() {
    if (!organization.data) return;
    await vigil.functions.deleteOrganizationRoles({
      uuidOrganization: organization.data.uuid,
      uuids: [uuidRole]
    })
  }

  if (stateRoleLoading || !uuidRole || !stateRole || stateUsersLoading) return <FullPageLoader />;
  if (stateError) return <div className='px-8 py-4 h-full w-full'><StatusAlert message={stateError} type='alert-error' /></div>;

  return (
    <div className='px-4 pb-2 w-full h-full flex flex-col'>
      <ModalRoleUpdate isOpen={isOpenModalUpdateRole} toggle={toggleModalUpdateRole} onSubmit={async () => await fetchRole(uuidRole)} uuidRole={uuidRole} />
      <ModalDeleteMany
        isOpen={isOpenModalDeleteRole}
        toggle={toggleModalDeleteRole}
        type='organizationRoles'
        data={[{ uuid: uuidRole, label: stateRole.name }]}
        extraComponents={<li>Remove the role-user links, but not the users.</li>}
        onSubmit={async () => navigate(ROUTES.ROUTE_HOME_ROLES)}
        deleteCallback={async () => _deleteRole()}
      />
      <ModalUserInviteCreate isOpen={isOpenModalInviteUser} toggle={toggleModalInviteUser} defaultRole={stateRole} />
      <ModalRoleUsersCreate isOpen={isOpenModalCreateRoleUsers} toggle={toggleModalCreateRoleUsers} uuidRole={uuidRole} onSubmit={async () => await fetchUsers(uuidRole)} />
      <ModalRoleUsersDelete isOpen={isOpenModalDeleteRoleUsers} toggle={toggleModalDeleteRoleUsers} uuidRole={uuidRole} uuidUsers={stateSelectedUser ? [stateSelectedUser.uuid] : []} onSubmit={async () => await fetchUsers(uuidRole)} />

      <Breadcrumbs crumbs={[{ id: 'roles', onClick: () => navigate(ROUTES.ROUTE_HOME_ROLES), text: 'Roles' }, { id: uuidRole, onClick: () => { }, text: stateRole.name }]} />

      <div className="mb-2">
        <div className="border-b border-base-300"></div>
      </div>

      <div className='flex items-center'>
        <div className='flex flex-grow items-end'>
          <div className="flex items-center mr-6">
            <span className='font-semibold'>Date Created:</span>
            <span className="text-sm ml-2">{TTuuid.decodeCuuid(stateRole.uuid).time.toLocaleDateString() + " " + TTuuid.decodeCuuid(stateRole.uuid).time.toLocaleTimeString()}</span>
          </div>
          <div className="flex items-center mr-6">
            <span className='font-semibold'>Last Updated:</span>
            <span className="text-sm ml-2">{TTuuid.decodeCuuid(stateRole.changeStamp).time.toLocaleDateString() + " " + TTuuid.decodeCuuid(stateRole.changeStamp).time.toLocaleTimeString()}</span>
          </div>
        </div>

        <div className='mx-4'>
          <InputButton text='Edit Role' before={<IconPencilSquareSolid className='h-5 mr-2' />} type='btn-primary' size='btn-sm' loading={false} disabled={!contextRoles.hasUserPermission(TTActions.U, Permissions.RRole)} onClick={() => toggleModalUpdateRole()} />
        </div>
        <InputButton text='Delete Role' before={<IconTrashSolid className='h-5 mr-2' />} type='btn-error' size='btn-sm' loading={false} disabled={!contextRoles.hasUserPermission(TTActions.D, Permissions.RRole)} onClick={() => toggleModalDeleteRole()} />
      </div>

      <div className='flex flex-grow py-2'>
        <div className="card w-80 mr-2 z-[1]">
          <div className='rounded-t-xl bg-base-300'>
            <div className='flex justify-center font-bold mt-2'>Linked Users</div>
            <div className='p-2 flex flex-row'>
              <InputButton text='Invite User' before={<IconPlusSolid className='h-5 mr-2' />} type='btn-primary' size='btn-sm' loading={false} disabled={!contextRoles.hasUserPermission(TTActions.C, Permissions.RUserInvite)} onClick={() => toggleModalInviteUser()} />
              <div className='mx-2'></div>
              <InputButton text='Link User' before={<IconLinkSolid className='h-5 mr-2' />} type='btn-secondary' size='btn-sm' loading={false} disabled={!contextRoles.hasUserPermission(TTActions.C, Permissions.RUserRoleLink)} onClick={() => toggleModalCreateRoleUsers()} />
            </div>
          </div>

          <div className='rounded-b-xl bg-base-200 p-2 h-full'>
            {stateUsers && stateUsers.map((user) => {
              const decodedUuid = TTuuid.decodeCuuid(user.uuid);
              const dateCreated = decodedUuid.time.toLocaleDateString() + " " + decodedUuid.time.toLocaleTimeString();
              const decodedChangeStamp = TTuuid.decodeCuuid(user.changeStamp);
              const dateUpdated = decodedChangeStamp.time.toLocaleDateString() + " " + decodedChangeStamp.time.toLocaleTimeString();
              return (
                <div key={user.uuid} onClick={() => toggleSelectedUser(user)} className={`card dropdown dropdown-right w-full hover:cursor-pointer hover:bg-base-200 flex flex-row items-center p-2 drop-shadow-xl mb-2 ${stateSelectedUser == user ? 'bg-base-200 dropdown-open' : 'bg-base-100 dropdown-close'}`}>
                  <div className='flex flex-col flex-grow text-left'>
                    <span className='text-md font-semibold'>{`${user.firstName} ${user.lastName}`}</span>
                  </div>
                  <IconChevronRightSolid className={`h-5 transform transition-transform duration-300 ${stateSelectedUser == user ? 'rotate-180' : ''}`} />

                  <div onClick={(event) => event.stopPropagation()} className={`cursor-auto ml-3 p-2 shadow dropdown-content bg-base-100 rounded-box w-64 outline outline-1 outline-neutral-content ${stateSelectedUser == user ? '' : 'hidden'}`}>
                    <button className="btn btn-sm btn-circle btn-ghost absolute right-1 top-1" onClick={() => toggleSelectedUser(user)}>✕</button>
                    <div className='text-lg font-semibold mb-2 text-center'>{`${user.firstName} ${user.lastName}`}</div>
                    <div className='text-left'>
                      <div className='text-xs'>UUID: {user.uuid}</div>
                      <div className='text-xs'>Date created: {dateCreated}</div>
                      <div className='text-xs mb-4'>Last updated: {dateUpdated}</div>
                      <InputButton text='Edit User' before={<IconPencilSquareSolid className='h-5 mr-2' />} type='btn-primary' size='btn-sm' loading={false} disabled={false} onClick={() => navigate(ROUTES.ROUTE_HOME_USER.replace(':uuid', user.uuid))} />
                      <div className='mt-2' />
                      <InputButton text='Unlink User' before={<IconBrokenLinkOutline className='h-4 mr-2 fill-warning-content' />} type='btn-warning' size='btn-sm' loading={false} disabled={!contextRoles.hasUserPermission(TTActions.D, Permissions.RUserRoleLink)} onClick={() => toggleModalDeleteRoleUsers()} />
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>

        {/* Create the UI to represent all the permissions of this specific role */}
        <div className="card flex-grow">
          <div className='rounded-t-xl bg-base-300 p-2 flex flex-row'>
            <div className='flex flex-grow items-center'>
              <span className='font-semibold'>Permissions</span>
            </div>
          </div>

          <div className='rounded-b-xl bg-base-200 h-full relative'>
            <div className='p-2 absolute w-full h-full overflow-y-scroll'>
              {statePermissions.map((permission, indexP) => {
                return (
                  <div key={indexP} className="mb-2">
                    <div className="flex items-center mb-1">
                      <div className="text-sm font-semibold pl-2">{permission.parentPermission.name}</div>
                    </div>
                    <div className="flex flex-wrap">
                      {permission.childPermissions.map((childPermission, indexC) => {
                        return (
                          <div key={indexC} className="flex items-center mb-2 mr-4">
                            <div className="text-sm pl-2">{childPermission.name}</div>
                            <input type="checkbox" checked={childPermission.selected} disabled={true} className="ml-2" />
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
