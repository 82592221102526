import { sqliteTable, text } from "drizzle-orm/sqlite-core";
import { schemaBase } from "./bean_base";
import { devices } from "./bean_device";
import { relations } from "drizzle-orm";

export const deviceStockStatusLogs = sqliteTable('device_stock_status_logs', {
  ...schemaBase(),
  uuidDevice: text('uuid_device').notNull().references(() => devices.uuid),
  condition: text('condition', { enum: ["working", "broken", "lost", "stolen", "repaired"] }).notNull(),
  location: text('location'),
  notes: text('notes'),
});

export const deviceStockStatusLogsRelations = relations(deviceStockStatusLogs, ({ one }) => ({
  device: one(devices, {
    fields: [deviceStockStatusLogs.uuidDevice],
    references: [devices.uuid],
  }),
}));
