import React, { useContext } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { ContextVigilClient } from '../../providers/provider_vigil_client';
import { Breadcrumbs } from '../../components/breadcrumbs';
import { FullPageLoader } from '../../components/full_page_loader';
import { StatusAlert } from '../../components/status_alert';
import { IconBars4, IconCalendar } from '../../components/icons';
import { useModal } from '../../hooks/use_modal';
import { ModalEventUpdate } from './func_event/modal_event_update';
import { Tabs } from '../../components/tabs';
import { ContextOrganization } from '../../providers/provider_organization';
import { useCaller } from '../../hooks/use_caller';
import { ROUTES } from '../../router/routes';

interface ScreenHomeEventProps { }

export const ScreenHomeEvent: React.FC<ScreenHomeEventProps> = (props) => {
  /* State */
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const vigil = useContext(ContextVigilClient);
  const organization = useContext(ContextOrganization);
  const uuidEvent = params['uuid'] || '';

  const { isOpen: isOpenModalUpdatePatrol, toggle: toggleModalUpdatePatrol } = useModal();
  const { isOpen: isOpenModalDeletePatrol, toggle: toggleModalDeletePatrol } = useModal();

  const { result, loading, error, call } = useCaller({
    callback: async () => {
      if (!organization.data) throw new Error('Organization data is not available');
      // TODO: add fetch event
      // return await vigil.functions.findOneOnOrganization({ 
      //   type: "events", 
      //   uuidOrganization: organization.data.uuid, 
      //   uuid: uuidEvent 
      // });
    },
    dependencies: { 
      normal: [uuidEvent, organization.data],
      background: []
    }
  });

  function navigateTab(pageId: string) {
    navigate({
      pathname: ROUTES.ROUTE_HOME_EVENT.replace(':uuid', uuidEvent) + '/' + pageId,
    })
  }

  function getSelectedId() {
    return location.pathname.split('/').pop() || '';
  }

  if (loading || !result) return <FullPageLoader />;
  if (error) return <div className='px-8 py-4 h-full w-full'><StatusAlert message={error} type='alert-error' /></div>;

  return (
    <div className='px-4 pb-2 flex w-full h-full flex-col'>
      <ModalEventUpdate isOpen={isOpenModalUpdatePatrol} toggle={toggleModalUpdatePatrol} event={result} onSubmit={call} />
      {/* TODO: Add delete event */}
      {/* <ModalDeleteMany
        isOpen={isOpenModalDeletePatrol}
        toggle={toggleModalDeletePatrol}
        type='events'
        data={[{ uuid: result.uuid, label: result.name }]}
        onSubmit={async () => navigate(ROUTES.ROUTE_HOME_EVENTS)}
      /> */}

      {/* <Breadcrumbs crumbs={[{ id: 'events', onClick: () => navigate(ROUTES.ROUTE_HOME_EVENTS), text: 'Events' }, { id: uuidEvent, onClick: () => { }, text: result.name }]} /> */}

      <div className="mb-2">
        <div className="border-b border-base-300"></div>
      </div>

      <Tabs selectedId={getSelectedId()} onSelect={navigateTab} items={[
        {
          icon: IconCalendar,
          id: 'overview',
          text: 'Overview'
        },
        {
          icon: IconBars4,
          id: 'raw_logs',
          text: 'Raw Logs'
        },
      ]} />

      <div className='w-full flex-grow'>
        <Outlet />
      </div>
    </div>
  )
};
