import { InputButton } from "../../components/input_button";
import { ModalProps, Modal } from "../../components/modal";
import { StatusAlert } from "../../components/status_alert";
import { useStateCallback } from "../../hooks/use_state_callback";

interface ModalUpdateOneProps extends ModalProps {
  updateCallback: () => Promise<void>;
  onSubmit?: () => Promise<void>;
}

export const ModalUpdateOne: React.FC<ModalUpdateOneProps> = (props) => {
  const { error, loading, call } = useStateCallback(async () => { 
    await props.updateCallback();
    if (props.onSubmit) await props.onSubmit();
    props.toggle();
  });

  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle} closeOnBackgroundClick={false} className="w-128">
      <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2" onClick={() => props.toggle()}>✕</button>
      <h3 className="font-bold text-lg pb-2">Update</h3>
      <div>Are you sure you want to save your changes?</div>
      {error && <StatusAlert type='alert-error' message={error} />}
      <div className="flex justify-end pt-4">
        <InputButton text='Save' loading={loading} disabled={false} type='btn-primary' onClick={call}></InputButton>
      </div>
    </Modal>
  )
}