import React, { useContext, useState } from 'react';
import { InputText } from '../../components/input_text';
import { InputButton } from '../../components/input_button';
import { ContextUser } from '../../providers/provider_user';
import { InputForm } from '../../components/input_form';
import { ContextVigilClient } from '../../providers/provider_vigil_client';
import { VALIDATORS, validate } from '../../validation';
import { StatusAlert } from '../../components/status_alert';
import { InputTextWithButton } from '../../components/input_text_with_button';
import { ModalUpdateEmail } from './modal_update_email';
import { ModalUpdateMobile } from './modal_update_mobile';
import { useModal } from '../../hooks/use_modal';
import { Preferences } from '../../preference_keys';

interface ScreenProfileBasicInfoProps { }

export const ScreenProfileBasicInfo: React.FC<ScreenProfileBasicInfoProps> = (props) => {
  const user = useContext(ContextUser);
  const vigil = useContext(ContextVigilClient);

  const modalEmailUpdate = useModal();
  const modalMobileUpdate = useModal();

  const [stateLoading, setLoading] = useState(false);
  const [stateError, setError] = useState("");
  const [stateFirstName, setFirstName] = useState(user.data?.firstName || '');
  const [stateLastName, setLastName] = useState(user.data?.lastName || '');

  /* UI Updates */
  function onChangeFirstName(event: React.ChangeEvent<HTMLInputElement>) {
    setFirstName(event.target.value)
  }
  function onChangeLastName(event: React.ChangeEvent<HTMLInputElement>) {
    setLastName(event.target.value)
  }
  function showModalUpdateMobile() {
    console.log('showModalUpdateMobile')
  }

  /* Validation */
  function hasChanges() {
    const changed =
      stateFirstName !== user.data!.firstName ||
      stateLastName !== user.data!.lastName;

    return changed;
  }
  function validateFirstName() {
    return validate(stateFirstName, [VALIDATORS.length('first name', 2, 30)]);
  }
  function validateLastName() {
    return validate(stateLastName, [VALIDATORS.length('last name', 2, 30)]);
  }

  function validateForm() {
    const required: string[] = [];
    if (!stateFirstName) { required.push('First name is required') }
    if (!stateLastName) { required.push('Last name is required') }

    return [
      ...required,
      ...validateFirstName(),
      ...validateLastName(),
    ];
  }

  async function save() {
    try {
      setError("");
      setLoading(true);
      const newToken = await vigil.functions.userNameUpdate({
        firstName: stateFirstName,
        lastName: stateLastName
      });
      localStorage.setItem(Preferences.TOKEN, newToken);
      await user.refresh(false);
    }
    catch (error: any) {
      setError(error.message)
    }
    finally {
      setLoading(false);
    }
  }

  return (
    <div className='py-4'>
      <ModalUpdateEmail isOpen={modalEmailUpdate.isOpen} toggle={modalEmailUpdate.toggle} />
      <ModalUpdateMobile isOpen={modalMobileUpdate.isOpen} toggle={modalMobileUpdate.toggle} />

      <div className='rounded-t-xl bg-base-300 p-2'>
        <div className='flex items-center'>
          <div className='font-semibold'>Basic Information</div>
        </div>
      </div>

      <div className='rounded-b-xl bg-base-200 p-2'>
        <InputForm onSubmit={() => { console.log('submit') }}>
          <InputText errors={validateFirstName()} labelText='First Name' value={stateFirstName} onChange={onChangeFirstName} />
          <InputText errors={validateLastName()} labelText='Last Name' value={stateLastName} onChange={onChangeLastName} />
          <InputTextWithButton
            labelText='Email'
            errors={validateLastName()}
            propsInputText={{ value: user.data?.email || '', disabled: true }}
            propsInputButton={{ text: 'Modify', type: 'btn-primary', onClick: modalEmailUpdate.toggle }}
          />
          <InputTextWithButton
            labelText='Mobile'
            errors={validateLastName()}
            propsInputText={{ value: user.data?.mobile || '', disabled: true }}
            propsInputButton={{ text: 'Modify', type: 'btn-primary', onClick: modalMobileUpdate.toggle }}
          />
          <div className='py-2'>
            {stateError && <StatusAlert type='alert-error' message={stateError} />}
          </div>
          <InputButton type='btn-primary' text='Save' loading={stateLoading} disabled={hasChanges() == false || validateForm().length > 0} onClick={save} />
        </InputForm>
      </div>
    </div>
  );
};
