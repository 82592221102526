import { sqliteTable, text, uniqueIndex } from "drizzle-orm/sqlite-core";
import { schemaBase } from "./bean_base";
import { devices } from "./bean_device";
import { users } from "./bean_user";
import { relations } from "drizzle-orm";

/**
 * Links devices to users to track their logged in state.
 * If there exist a entry where deleted is 0 for a device and user, then the user is logged in to the device.
 * If there exist no entry where deleted is 0 for a device and user, then the user is not logged in to the device.
 * @property uuidDevice - UUID reference to the device
 * @property uuidUser - UUID reference to the user
 */
export const linkDeviceUserLoggedInStates = sqliteTable('link_device_user_logged_in_states', {
  ...schemaBase(),
  uuidDevice: text('uuid_device').notNull().references(() => devices.uuid),
  uuidUser: text('uuid_user').notNull().references(() => users.uuid),
}, (table) => ({
  deviceUserIdx: uniqueIndex('device_user_idx').on(table.uuidDevice, table.uuidUser)
}));

export const linkDeviceUserLoggedInStatesRelations = relations(linkDeviceUserLoggedInStates, ({ one }) => ({
  device: one(devices, {
    fields: [linkDeviceUserLoggedInStates.uuidDevice],
    references: [devices.uuid],
  }),
  user: one(users, {
    fields: [linkDeviceUserLoggedInStates.uuidUser],
    references: [users.uuid],
  }),
}));
