import React from 'react'
console.log(typeof React)
import { Event } from '../base/config_sdk_event';
import { Beacon, ConfigEventInstance, ConfigEventLog } from '../base/config';
import { SDKUserHomeUI } from '../base/config_sdk_user_home_ui';
import { SDKOrganization } from '../base/config_sdk_organization';
import { SDKDevice } from '../base/config_sdk_device';
import { SDKVigil } from '../base/config_sdk_vigil';

const SOUND_ID_START_REMINDER = "event_patrol_start_reminder"
const SOUND_ID_PATROL_FAILED = "event_patrol_failed"

const SCREEN_ID_START_REMINDER = "event_patrol_start_reminder|modal"
const SCREEN_ID_SCANNED_TO_EARLY = "event_patrol_scanned_to_early|modal"
const SCREEN_ID_SCANNED_WRONG_BEACON = "event_patrol_scanned_wrong_beacon|modal"
const SCREEN_ID_PATROL_FAILED = "event_patrol_failed|modal"

const TAG_PATROL_STARTED = "patrol_started"
const TAG_PATROL_SUCCESS = "patrol_success"
const TAG_PATROL_FAILED = "patrol_failed"
const TAG_REST_STARTED = "rest_started"
const TAG_BEACON_SCANNED = "beacon_scanned"
const TAG_PANIC = "panic"

const TAG_PATROL_STARTED_MISSING = "patrol_start_missing"

interface PatrolStartedLog {
  msg: string
  nextBeaconUuid: string
}
interface PatrolSuccessLog {
  msg: string
  successBeaconUuid: string
}
interface PatrolFailedLog {
  msg: string
  failedBeaconUuid: string
}
interface RestStartedLog {
  msg: string
}
interface BeaconScannedLog {
  msg: string
  beaconUuid: string;
}
interface PanicLog {
  msg: string
  userUuid: string
  userFirstName: string
  userLastName: string
  lat: number
  lng: number
  alt: number
  acc: number
}

interface PatrolStartedMissingLog {
  msg: string
}

const STATE_SCANNED_TO_EARLY = (now: Date, lastLogPatrolStarted: ConfigEventLog<PatrolStartedLog> | null, duration_minimum_to_beacon_minutes: number) => {
  if (!lastLogPatrolStarted) return false
  return now < new Date(lastLogPatrolStarted.datetime.valueOf() + duration_minimum_to_beacon_minutes * 60 * 1000)
}
const STATE_SCANNED_WRONG_BEACON = (lastLogPatrolStarted: ConfigEventLog<PatrolStartedLog> | null, uuidBeacon: string) => {
  if (!lastLogPatrolStarted) return false
  return lastLogPatrolStarted.data.nextBeaconUuid != uuidBeacon
}
const STATE_SCANNED_CORRECT_BEACON_AND_MORE_TO_SCAN = (lastLogPatrolStarted: ConfigEventLog<PatrolStartedLog> | null, beacon_order_uuids: string[]) => {
  if (!lastLogPatrolStarted) return false
  return lastLogPatrolStarted.data.nextBeaconUuid != beacon_order_uuids[beacon_order_uuids.length - 1]
}
const STATE_SCANNED_CORRECT_BEACON_AND_NO_MORE_TO_SCAN = (lastLogPatrolStarted: ConfigEventLog<PatrolStartedLog> | null, beacon_order_uuids: string[]) => {
  if (!lastLogPatrolStarted) return false
  return lastLogPatrolStarted.data.nextBeaconUuid == beacon_order_uuids[beacon_order_uuids.length - 1]
}
const STATE_TICK_START_PATROL = (now: Date, lastLogPatrolStarted: ConfigEventLog<PatrolStartedLog> | null, lastLogRestStarted: ConfigEventLog<RestStartedLog> | null, duration_rest_minutes: number) => {
  return !lastLogPatrolStarted || (lastLogRestStarted && now > new Date(lastLogRestStarted.datetime.valueOf() + duration_rest_minutes * 60 * 1000))
}
const STATE_TICK_MISSED_BEACON_AND_MORE_TO_SCAN = (now: Date, lastLogPatrolStarted: ConfigEventLog<PatrolStartedLog> | null, lastLogPatrolSuccess: ConfigEventLog<PatrolSuccessLog> | null, duration_minimum_to_beacon_minutes: number, duration_scan_beacon_minutes: number, beacon_order_uuids: string[]) => {
  if (!lastLogPatrolStarted) return false
  return (!lastLogPatrolSuccess || lastLogPatrolStarted.datetime > lastLogPatrolSuccess.datetime) &&
    now > new Date(lastLogPatrolStarted.datetime.valueOf() + duration_minimum_to_beacon_minutes * 60 * 1000 + duration_scan_beacon_minutes * 60 * 1000) &&
    lastLogPatrolStarted.data.nextBeaconUuid != beacon_order_uuids[beacon_order_uuids.length - 1]
}
const STATE_TICK_MISSED_BEACON_AND_NO_MORE_TO_SCAN = (now: Date, lastLogPatrolStarted: ConfigEventLog<PatrolStartedLog> | null, lastLogPatrolSuccess: ConfigEventLog<PatrolSuccessLog> | null, duration_minimum_to_beacon_minutes: number, duration_scan_beacon_minutes: number, beacon_order_uuids: string[]) => {
  if (!lastLogPatrolStarted) return false
  return (!lastLogPatrolSuccess || lastLogPatrolStarted.datetime > lastLogPatrolSuccess.datetime) &&
    now > new Date(lastLogPatrolStarted.datetime.valueOf() + duration_minimum_to_beacon_minutes * 60 * 1000 + duration_scan_beacon_minutes * 60 * 1000) &&
    lastLogPatrolStarted.data.nextBeaconUuid == beacon_order_uuids[beacon_order_uuids.length - 1]
}
const STATE_TICK_REST = (now: Date, lastLogRestStarted: ConfigEventLog<RestStartedLog> | null, duration_rest_minutes: number) => {
  if (!lastLogRestStarted) return false
  return now > new Date(lastLogRestStarted.datetime.valueOf() + duration_rest_minutes * 60 * 1000)
}

const STATE_TICK_PATROL_STARTED_MISSING = (now: Date, lastLogPatrolStarted: ConfigEventLog<PatrolStartedLog> | null, lastLogPatrolStartedMissing: ConfigEventLog<PatrolStartedMissingLog> | null) => {
  return !lastLogPatrolStarted && (!lastLogPatrolStartedMissing || now > new Date(lastLogPatrolStartedMissing.datetime.valueOf() + 10 * 60 * 1000))
}
const STATE_TICK_PATROL_STARTED_BUT_NO_REST_STARTED = (now: Date, lastLogPatrolStarted: ConfigEventLog<PatrolStartedLog> | null, lastLogRestStarted: ConfigEventLog<RestStartedLog> | null, duration_minimum_to_beacon_minutes: number, duration_scan_beacon_minutes: number) => {
  return lastLogPatrolStarted &&
    now > new Date(lastLogPatrolStarted.datetime.valueOf() + duration_minimum_to_beacon_minutes * 60 * 1000 + duration_scan_beacon_minutes * 60 * 1000 + 10 * 60 * 1000) &&
    (!lastLogRestStarted || lastLogRestStarted.datetime < lastLogPatrolStarted.datetime)
}
const STATE_TICK_REST_STARTED_BUT_NO_PATROL_STARTED = (now: Date, lastLogRestStarted: ConfigEventLog<RestStartedLog> | null, lastLogPatrolStarted: ConfigEventLog<PatrolStartedLog> | null, duration_rest_minutes: number) => {
  return lastLogRestStarted &&
    now > new Date(lastLogRestStarted.datetime.valueOf() + duration_rest_minutes * 60 * 1000 + 10 * 60 * 1000) &&
    (!lastLogPatrolStarted || lastLogPatrolStarted.datetime < lastLogRestStarted.datetime)
}

const startPatrol = async (eventInstance: ConfigEventInstance, userHomeUI: SDKUserHomeUI, nextBeacon: Beacon, duration_minimum_to_beacon_minutes: number, duration_scan_beacon_minutes: number) => {
  await eventInstance.createLog<PatrolStartedLog>({ msg: "Patrol started", nextBeaconUuid: nextBeacon.uuid }, [TAG_PATROL_STARTED])
  await userHomeUI.setStatusBar('Patrol\nNext Beacon: ' + nextBeacon.name + '\nWait for countdown before scanning', "warning")
  await userHomeUI.highlightBeacon(nextBeacon.uuid)
  await userHomeUI.setCountdown(duration_minimum_to_beacon_minutes * 60, async () => {
    // Start the time that the user has to scan the beacon
    await userHomeUI.setStatusBar('Patrol\nScan Beacon: ' + nextBeacon.name, "info")
    await userHomeUI.setCountdown(duration_scan_beacon_minutes * 60)
  })
}

const startRest = async (
  eventInstance: ConfigEventInstance,
  userHomeUI: SDKUserHomeUI,
  device: SDKDevice,
  organization: SDKOrganization,
  vigil: SDKVigil,
  duration_rest_minutes: number,
  duration_minimum_to_beacon_minutes: number,
  duration_scan_beacon_minutes: number,
  beacon_order_uuids: string[]
) => {
  await eventInstance.createLog<RestStartedLog>({ msg: "Rest started" }, [TAG_REST_STARTED])
  await userHomeUI.setStatusBar('Rest', "primary")
  await userHomeUI.clearHighlightBeacon()
  await userHomeUI.setCountdown(duration_rest_minutes * 60, async () => {
    device.setAudio({ id: SOUND_ID_START_REMINDER, base64Audio: vigil.audioBase64.alert_2, loop: 100 });
    device.setScreen({ id: SCREEN_ID_START_REMINDER });

    // Get the first beacon in the sequence
    const firstBeacon = await organization.getBeacon(beacon_order_uuids[0])
    if (!firstBeacon) {
      console.error("Beacon not found")
      return
    }

    await startPatrol(eventInstance, userHomeUI, firstBeacon, duration_minimum_to_beacon_minutes, duration_scan_beacon_minutes)
  })
}

export const EventPatrolSequenceBeacons: Event = {
  config: {
    id: "event_patrol_sequence_beacons",
    name: "Patrol Sequence Beacons",
    description: "Patrol event to remind user to start patrolling and then let them patrol the beacons in order",
    parameters: [
      {
        id: "start_cron",
        name: "Start Time",
        description: "The start time of the event",
        required: true,
        type: "cron"
      },
      {
        id: "duration_hours",
        name: "Duration (hours)",
        description: "For how long the event should run",
        required: true,
        type: "numberInt"
      },
      {
        id: "duration_rest_minutes",
        name: "Duration Rest (minutes)",
        description: "How long the user should rest before starting to patrol",
        required: true,
        type: "numberInt"
      },
      {
        id: "duration_minimum_to_beacon_minutes",
        name: "Minimum Time To Beacon (minutes)",
        description: "The duration in which the user are not able to scan a beacon and should patrol to it",
        required: true,
        type: "numberInt"
      },
      {
        id: "duration_scan_beacon_minutes",
        name: "Scan Beacon Duration (minutes)",
        description: "The duration in which the user can scan a beacon",
        required: true,
        type: "numberInt"
      },
      {
        id: "beacon_order_uuids",
        name: "Beacon Order",
        description: "The order in which the beacons should be scanned",
        required: true,
        type: "array"
      }
    ],
    screens: [
      {
        id: SCREEN_ID_START_REMINDER,
        code: (context) => {
          const c = context.components;
          return (<>
            <c.Modal isOpen={true} onClose={() => { }}>
              <c.Text variant="h3" margin={2} color="black" center={true}>Patrol Started</c.Text>
              <c.Text variant="p" margin={6} color="black" center={true}>You can now start patrolling the beacons in order.</c.Text>
              <c.Button
                text="OK"
                variant="btn-primary"
                margin={4}
                onPress={async () => {
                  context.device.setAudio(null)
                  context.device.setScreen(null)
                }}
              />
            </c.Modal>
          </>)
        }
      },
      {
        id: SCREEN_ID_SCANNED_TO_EARLY,
        code: (context) => {
          const c = context.components;
          return (<>
            <c.Modal isOpen={true} onClose={() => { }}>
              <c.Text variant="h3" margin={2} color="black" center={true}>Scanned To Early</c.Text>
              <c.Text variant="p" margin={6} color="black" center={true}>You scanned to early, please wait for the countdown to finish.</c.Text>
              <c.Button
                text="OK"
                variant="btn-primary"
                margin={4}
                onPress={async () => {
                  context.device.setAudio(null)
                  context.device.setScreen(null)
                }}
              />
            </c.Modal>
          </>)
        }
      },
      {
        id: SCREEN_ID_SCANNED_WRONG_BEACON,
        code: (context) => {
          const c = context.components;
          return (<>
            <c.Modal isOpen={true} onClose={() => { }}>
              <c.Text variant="h3" margin={2} color="black" center={true}>Scanned Wrong Beacon</c.Text>
              <c.Text variant="p" margin={6} color="black" center={true}>You scanned the wrong beacon, please scan the highlighted beacon.</c.Text>
              <c.Button
                text="OK"
                variant="btn-error"
                margin={4}
                onPress={async () => {
                  context.device.setAudio(null)
                  context.device.setScreen(null)
                }}
              />
            </c.Modal>
          </>)
        }
      },
      {
        id: SCREEN_ID_PATROL_FAILED,
        code: (context) => {
          const c = context.components;
          return (<>
            <c.Modal isOpen={true} onClose={() => { }}>
              <c.Text variant="h3" margin={2} color="black" center={true}>Patrol Failed</c.Text>
              <c.Text variant="p" margin={6} color="black" center={true}>You failed to scan the beacon in time, please follow status bar instructions.</c.Text>
              <c.Button
                text="OK"
                variant="btn-error"
                margin={4}
                onPress={async () => {
                  context.device.setAudio(null)
                  context.device.setScreen(null)
                }}
              />
            </c.Modal>
          </>)
        }
      }
    ],
    canLinkTo: ["device", "site"]
  },
  device: {
    beaconScanned: async (context, uuidBeacon) => {
      // Destructure context
      const { vigil, organization, device, event, userHomeUI } = context;

      // If user is not signed in, skip tick
      if (!await device.getUser()) return

      const now = new Date();
      const start_cron = event.getParameterValue<string>("start_cron")!;
      const duration_hours = event.getParameterValue<number>("duration_hours")!;
      const timeslot = vigil.getTimeslotFromCron(start_cron, duration_hours * 60 * 60 * 1000);
      const eventInstance = await event.getInstance(timeslot.start, timeslot.end, now)

      // If there is no active instance and there should not be one, exit early
      if (!eventInstance) return

      await eventInstance.createLog<BeaconScannedLog>({ msg: "Beacon scanned", beaconUuid: uuidBeacon }, [TAG_BEACON_SCANNED])

      const duration_minimum_to_beacon_minutes = event.getParameterValue<number>("duration_minimum_to_beacon_minutes")!;
      const duration_scan_beacon_minutes = event.getParameterValue<number>("duration_scan_beacon_minutes")!;
      const duration_rest_minutes = event.getParameterValue<number>("duration_rest_minutes")!;
      const beacon_order_uuids = event.getParameterValue<string[]>("beacon_order_uuids")!;

      const lastLogPatrolStarted = await eventInstance.getDeviceLastCreatedLog<PatrolStartedLog>([TAG_PATROL_STARTED], [(await device.getDevice()).uuid])

      if (STATE_SCANNED_TO_EARLY(now, lastLogPatrolStarted, duration_minimum_to_beacon_minutes)) {
        device.setScreen({ id: SCREEN_ID_SCANNED_TO_EARLY })
        return
      }

      if (STATE_SCANNED_WRONG_BEACON(lastLogPatrolStarted, uuidBeacon)) {
        device.setScreen({ id: SCREEN_ID_SCANNED_WRONG_BEACON })
        return
      }

      if (STATE_SCANNED_CORRECT_BEACON_AND_MORE_TO_SCAN(lastLogPatrolStarted, beacon_order_uuids)) {
        const nextBeacon = await organization.getBeacon(beacon_order_uuids[beacon_order_uuids.indexOf(uuidBeacon) + 1])
        if (!nextBeacon) {
          console.error("Beacon not found")
          return
        }

        await eventInstance.createLog<PatrolSuccessLog>({ msg: "Patrol success", successBeaconUuid: uuidBeacon }, [TAG_PATROL_SUCCESS])
        await startPatrol(eventInstance, userHomeUI, nextBeacon, duration_minimum_to_beacon_minutes, duration_scan_beacon_minutes)
        return
      }

      if (STATE_SCANNED_CORRECT_BEACON_AND_NO_MORE_TO_SCAN(lastLogPatrolStarted, beacon_order_uuids)) {
        await eventInstance.createLog<PatrolSuccessLog>({ msg: "Patrol success", successBeaconUuid: uuidBeacon }, [TAG_PATROL_SUCCESS])
        await startRest(eventInstance, userHomeUI, device, organization, vigil, duration_rest_minutes, duration_minimum_to_beacon_minutes, duration_scan_beacon_minutes, beacon_order_uuids)
        return
      }
    },
    panic: async (context) => {
      // Destructure context
      const { vigil, device, event } = context;

      // If user is not signed in, skip tick
      if (!await device.getUser()) return

      const now = new Date();
      const start_cron = event.getParameterValue<string>("start_cron")!;
      const duration_hours = event.getParameterValue<number>("duration_hours")!;
      const timeslot = vigil.getTimeslotFromCron(start_cron, duration_hours * 60 * 60 * 1000);
      const eventInstance = await event.getInstance(timeslot.start, timeslot.end, now)

      // If there is no active instance and there should not be one, exit early
      if (!eventInstance) return

      const user = await device.getUser()
      if (!user) return

      const location = await device.getLocation()
      if (!location) return

      await eventInstance.createLog<PanicLog>({
        msg: `${user.firstName} ${user.lastName} panicked`,
        userUuid: user.uuid,
        userFirstName: user.firstName,
        userLastName: user.lastName,
        lat: location.latitude,
        lng: location.longitude,
        alt: location.altitude,
        acc: location.accuracy
      }, [TAG_PANIC])
    },
    tick: async (context) => {
      // Destructure context
      const { vigil, organization, device, event, userHomeUI } = context;

      // If user is not signed in, skip tick
      if (!await device.getUser()) return

      const now = new Date();
      const start_cron = event.getParameterValue<string>("start_cron")!;
      const duration_hours = event.getParameterValue<number>("duration_hours")!;
      const timeslot = vigil.getTimeslotFromCron(start_cron, duration_hours * 60 * 60 * 1000);
      const eventInstance = await event.getInstance(timeslot.start, timeslot.end, now)

      // If there is no active instance and there should not be one, exit early
      if (!eventInstance) return

      const duration_rest_minutes = event.getParameterValue<number>("duration_rest_minutes")!;
      const duration_minimum_to_beacon_minutes = event.getParameterValue<number>("duration_minimum_to_beacon_minutes")!;
      const duration_scan_beacon_minutes = event.getParameterValue<number>("duration_scan_beacon_minutes")!;
      const beacon_order_uuids = event.getParameterValue<string[]>("beacon_order_uuids")!;

      const lastLogPatrolStarted = await eventInstance.getDeviceLastCreatedLog<PatrolStartedLog>([TAG_PATROL_STARTED], [(await device.getDevice()).uuid])
      const lastLogRestStarted = await eventInstance.getDeviceLastCreatedLog<RestStartedLog>([TAG_REST_STARTED], [(await device.getDevice()).uuid])
      const lastLogPatrolSuccess = await eventInstance.getDeviceLastCreatedLog<PatrolSuccessLog>([TAG_PATROL_SUCCESS], [(await device.getDevice()).uuid])

      if (STATE_TICK_START_PATROL(now, lastLogPatrolStarted, lastLogRestStarted, duration_rest_minutes)) {
        device.setAudio({ id: SOUND_ID_START_REMINDER, base64Audio: context.vigil.audioBase64.alert_2, loop: 100 });
        device.setScreen({ id: SCREEN_ID_START_REMINDER });

        // Get the first beacon in the sequence
        const firstBeacon = await organization.getBeacon(beacon_order_uuids[0])
        if (!firstBeacon) {
          console.error("Beacon not found")
          return
        }

        await startPatrol(eventInstance, userHomeUI, firstBeacon, duration_minimum_to_beacon_minutes, duration_scan_beacon_minutes)
        return
      }

      if (STATE_TICK_MISSED_BEACON_AND_MORE_TO_SCAN(now, lastLogPatrolStarted, lastLogPatrolSuccess, duration_minimum_to_beacon_minutes, duration_scan_beacon_minutes, beacon_order_uuids)) {
        const missedBeaconUuid = lastLogPatrolStarted!.data.nextBeaconUuid
        const missedBeaconIndex = beacon_order_uuids.indexOf(missedBeaconUuid)
        device.setAudio({ id: SOUND_ID_PATROL_FAILED, base64Audio: context.vigil.audioBase64.alert_3, loop: 100 });
        device.setScreen({ id: SCREEN_ID_PATROL_FAILED });

        // Create log
        await eventInstance.createLog<PatrolFailedLog>({ msg: "Patrol failed", failedBeaconUuid: missedBeaconUuid }, [TAG_PATROL_FAILED])

        const nextBeacon = await organization.getBeacon(beacon_order_uuids[missedBeaconIndex + 1])
        if (!nextBeacon) {
          console.error("Beacon not found")
          return
        }

        await startPatrol(eventInstance, userHomeUI, nextBeacon, duration_minimum_to_beacon_minutes, duration_scan_beacon_minutes)
        return
      }

      if (STATE_TICK_MISSED_BEACON_AND_NO_MORE_TO_SCAN(now, lastLogPatrolStarted, lastLogPatrolSuccess, duration_minimum_to_beacon_minutes, duration_scan_beacon_minutes, beacon_order_uuids)) {
        const missedBeaconUuid = lastLogPatrolStarted!.data.nextBeaconUuid
        device.setAudio({ id: SOUND_ID_PATROL_FAILED, base64Audio: context.vigil.audioBase64.alert_3, loop: 100 });
        device.setScreen({ id: SCREEN_ID_PATROL_FAILED });

        await eventInstance.createLog<PatrolFailedLog>({ msg: "Patrol failed", failedBeaconUuid: missedBeaconUuid }, [TAG_PATROL_FAILED])
        await startRest(eventInstance, userHomeUI, device, organization, vigil, duration_rest_minutes, duration_minimum_to_beacon_minutes, duration_scan_beacon_minutes, beacon_order_uuids)
        return
      }

      if (STATE_TICK_REST(now, lastLogRestStarted, duration_rest_minutes)) {
        return
      }
    },
  },
  server: {
    tick: async (context) => {
      // Destructure context
      const { vigil, event } = context;

      // If user should not be signed in, skip tick
      const now = new Date();
      const start_cron = event.getParameterValue<string>("start_cron")!;
      const duration_hours = event.getParameterValue<number>("duration_hours")!;
      const timeslot = vigil.getTimeslotFromCron(start_cron, duration_hours * 60 * 60 * 1000);
      const eventInstance = await event.getInstance(timeslot.start, timeslot.end, now)

      // If there is no active instance and there should not be one, exit early
      if (!eventInstance) return

      const duration_rest_minutes = event.getParameterValue<number>("duration_rest_minutes")!;
      const duration_minimum_to_beacon_minutes = event.getParameterValue<number>("duration_minimum_to_beacon_minutes")!;
      const duration_scan_beacon_minutes = event.getParameterValue<number>("duration_scan_beacon_minutes")!;

      const lastLogPatrolStarted = await eventInstance.getDeviceLastCreatedLog<PatrolStartedLog>([TAG_PATROL_STARTED])
      const lastLogRestStarted = await eventInstance.getDeviceLastCreatedLog<RestStartedLog>([TAG_REST_STARTED])
      const lastLogPatrolStartedMissing = await eventInstance.getServerLastCreatedLog<PatrolStartedMissingLog>([TAG_PATROL_STARTED_MISSING])

      // If there has not been 10 minutes since the event started, skip
      if (now < new Date(timeslot.start.valueOf() + 10 * 60 * 1000)) return

      if (
        STATE_TICK_PATROL_STARTED_MISSING(now, lastLogPatrolStarted, lastLogPatrolStartedMissing) ||
        STATE_TICK_PATROL_STARTED_BUT_NO_REST_STARTED(now, lastLogPatrolStarted, lastLogRestStarted, duration_minimum_to_beacon_minutes, duration_scan_beacon_minutes) ||
        STATE_TICK_REST_STARTED_BUT_NO_PATROL_STARTED(now, lastLogRestStarted, lastLogPatrolStarted, duration_rest_minutes)
      ) {
        await eventInstance.createLog<PatrolStartedMissingLog>({ msg: "Patrol started missing" }, [TAG_PATROL_STARTED_MISSING])
      }
    }
  }
}
