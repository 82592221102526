import { schemaBase } from "./bean_base";

type BaseSchemaType = keyof ReturnType<typeof schemaBase>

export const schemaBaseKeys = Object.keys(schemaBase()) as (keyof ReturnType<typeof schemaBase>)[];

import { organizations } from "./bean_organization";
export type IOrganizationInsert = Omit<typeof organizations.$inferInsert, BaseSchemaType>;
export type IOrganizationUpdate = IOrganizationInsert;
export type IOrganizationSelect = typeof organizations.$inferSelect;

import { organizationRoles } from "./bean_organization_role";
export type IOrganizationRoleInsert = Omit<typeof organizationRoles.$inferInsert, BaseSchemaType>;
export type IOrganizationRoleUpdate = Omit<IOrganizationRoleInsert, 'mutable'>;
export type IOrganizationRoleSelect = typeof organizationRoles.$inferSelect;

import { organizationRoleUserInvite } from "./bean_organization_role_user_invite";
export type IOrganizationRoleUserInviteInsert = Omit<typeof organizationRoleUserInvite.$inferInsert, BaseSchemaType>;
export type IOrganizationRoleUserInviteUpdate = IOrganizationRoleUserInviteInsert;
export type IOrganizationRoleUserInviteSelect = typeof organizationRoleUserInvite.$inferSelect;

import { users } from "./bean_user";
export type IUserInsert = Omit<typeof users.$inferInsert, BaseSchemaType>;
export type IUserUpdate = IUserInsert;
export type IUserSelect = typeof users.$inferSelect;

import { sites } from "./bean_site";
export type ISiteInsert = Omit<typeof sites.$inferInsert, BaseSchemaType>;
export type ISiteUpdate = ISiteInsert;
export type ISiteSelect = typeof sites.$inferSelect;

import { devices } from "./bean_device";
export type IDeviceInsert = Omit<typeof devices.$inferInsert, BaseSchemaType>;
export type IDeviceUpdate = IDeviceInsert;
export type IDeviceSelect = typeof devices.$inferSelect;

import { beacons } from "./bean_beacon";
export type IBeaconInsert = Omit<typeof beacons.$inferInsert, Exclude<BaseSchemaType, 'uuid'>>;
export type IBeaconUpdate = Omit<IBeaconInsert, 'uuid'>;
export type IBeaconSelect = typeof beacons.$inferSelect;

import { notifications } from "./bean_notification";
export type INotificationInsert = Omit<typeof notifications.$inferInsert, BaseSchemaType>;
export type INotificationUpdate = INotificationInsert;
export type INotificationSelect = typeof notifications.$inferSelect;

import { notificationLogs } from "./bean_notification_log";
export type INotificationLogInsert = Omit<typeof notificationLogs.$inferInsert, BaseSchemaType>;
export type INotificationLogUpdate = INotificationLogInsert;
export type INotificationLogSelect = typeof notificationLogs.$inferSelect;

import { linkDeviceBehaviourDevices } from "./bean_link_device_behaviour_device";
export type ILinkDeviceBehaviourDeviceInsert = Omit<typeof linkDeviceBehaviourDevices.$inferInsert, BaseSchemaType>;
export type ILinkDeviceBehaviourDeviceUpdate = ILinkDeviceBehaviourDeviceInsert;
export type ILinkDeviceBehaviourDeviceSelect = typeof linkDeviceBehaviourDevices.$inferSelect;

import { linkDeviceBehaviourSites } from "./bean_link_device_behaviour_site";
export type ILinkDeviceBehaviourSiteInsert = Omit<typeof linkDeviceBehaviourSites.$inferInsert, BaseSchemaType>;
export type ILinkDeviceBehaviourSiteUpdate = ILinkDeviceBehaviourSiteInsert;
export type ILinkDeviceBehaviourSiteSelect = typeof linkDeviceBehaviourSites.$inferSelect;

import { linkOrganizationUsers } from "./bean_link_organization_user";
export type ILinkOrganizationUserInsert = Omit<typeof linkOrganizationUsers.$inferInsert, BaseSchemaType>;
export type ILinkOrganizationUserUpdate = ILinkOrganizationUserInsert;
export type ILinkOrganizationUserSelect = typeof linkOrganizationUsers.$inferSelect;

import { linkOrganizationSites } from "./bean_link_organization_site";
export type ILinkOrganizationSiteInsert = Omit<typeof linkOrganizationSites.$inferInsert, BaseSchemaType>;
export type ILinkOrganizationSiteUpdate = ILinkOrganizationSiteInsert;
export type ILinkOrganizationSiteSelect = typeof linkOrganizationSites.$inferSelect;

import { linkOrganizationDevices } from "./bean_link_organization_device";
export type ILinkOrganizationDeviceInsert = Omit<typeof linkOrganizationDevices.$inferInsert, BaseSchemaType>;
export type ILinkOrganizationDeviceUpdate = ILinkOrganizationDeviceInsert;
export type ILinkOrganizationDeviceSelect = typeof linkOrganizationDevices.$inferSelect;

import { linkOrganizationBeacons } from "./bean_link_organization_beacon";
export type ILinkOrganizationBeaconInsert = Omit<typeof linkOrganizationBeacons.$inferInsert, BaseSchemaType>;
export type ILinkOrganizationBeaconUpdate = ILinkOrganizationBeaconInsert;
export type ILinkOrganizationBeaconSelect = typeof linkOrganizationBeacons.$inferSelect;

import { linkOrganizationRoleUser } from "./bean_link_organization_role_user";
export type ILinkOrganizationRoleUserInsert = Omit<typeof linkOrganizationRoleUser.$inferInsert, BaseSchemaType>;
export type ILinkOrganizationRoleUserUpdate = ILinkOrganizationRoleUserInsert;
export type ILinkOrganizationRoleUserSelect = typeof linkOrganizationRoleUser.$inferSelect;

import { linkDeviceUserLoggedInStates } from "./bean_link_device_user_logged_in_state";
export type ILinkDeviceUserLoggedInStateInsert = Omit<typeof linkDeviceUserLoggedInStates.$inferInsert, BaseSchemaType>;
export type ILinkDeviceUserLoggedInStateUpdate = ILinkDeviceUserLoggedInStateInsert;
export type ILinkDeviceUserLoggedInStateSelect = typeof linkDeviceUserLoggedInStates.$inferSelect;

import { linkSiteBeacons } from "./bean_link_site_beacon";
export type ILinkSiteBeaconInsert = Omit<typeof linkSiteBeacons.$inferInsert, BaseSchemaType>;
export type ILinkSiteBeaconUpdate = ILinkSiteBeaconInsert;
export type ILinkSiteBeaconSelect = typeof linkSiteBeacons.$inferSelect;

import { linkOrganizationEvents } from "./bean_link_organization_event";
export type ILinkOrganizationEventInsert = Omit<typeof linkOrganizationEvents.$inferInsert, BaseSchemaType>;
export type ILinkOrganizationEventUpdate = ILinkOrganizationEventInsert;
export type ILinkOrganizationEventSelect = typeof linkOrganizationEvents.$inferSelect;

import { dashSessions } from "./bean_dash_session";
export type IDashSessionInsert = Omit<typeof dashSessions.$inferInsert, BaseSchemaType>;
export type IDashSessionUpdate = IDashSessionInsert;
export type IDashSessionSelect = typeof dashSessions.$inferSelect;

import { dashActivityLogs } from "./bean_dash_activity_log";
export type IDashActivityLogInsert = Omit<typeof dashActivityLogs.$inferInsert, BaseSchemaType>;
export type IDashActivityLogUpdate = IDashActivityLogInsert;
export type IDashActivityLogSelect = typeof dashActivityLogs.$inferSelect;

import { dashStateLogs } from "./bean_dash_state_log";
export type IDashStateLogInsert = Omit<typeof dashStateLogs.$inferInsert, BaseSchemaType>;
export type IDashStateLogUpdate = IDashStateLogInsert;
export type IDashStateLogSelect = typeof dashStateLogs.$inferSelect;

import { deviceActivityLogs } from "./bean_device_activity_log";
export type IDeviceActivityLogInsert = Omit<typeof deviceActivityLogs.$inferInsert, BaseSchemaType>;
export type IDeviceActivityLogUpdate = IDeviceActivityLogInsert;
export type IDeviceActivityLogSelect = typeof deviceActivityLogs.$inferSelect;

import { deviceStateLogs } from "./bean_device_state_log";
export type IDeviceStateLogInsert = Omit<typeof deviceStateLogs.$inferInsert, BaseSchemaType>;
export type IDeviceStateLogUpdate = IDeviceStateLogInsert;
export type IDeviceStateLogSelect = typeof deviceStateLogs.$inferSelect;

import { deviceBehaviours } from "./bean_device_behaviour";
export type IDeviceBehaviourInsert = Omit<typeof deviceBehaviours.$inferInsert, BaseSchemaType>;
export type IDeviceBehaviourUpdate = IDeviceBehaviourInsert;
export type IDeviceBehaviourSelect = typeof deviceBehaviours.$inferSelect;

import { deviceStockStatusLogs } from "./bean_device_stock_status_log";
export type IDeviceStockStatusLogInsert = Omit<typeof deviceStockStatusLogs.$inferInsert, BaseSchemaType>;
export type IDeviceStockStatusLogUpdate = IDeviceStockStatusLogInsert;
export type IDeviceStockStatusLogSelect = typeof deviceStockStatusLogs.$inferSelect;

import { deviceBehaviourInstances } from "./bean_device_behaviour_instance";
export type IDeviceBehaviourInstanceInsert = Omit<typeof deviceBehaviourInstances.$inferInsert, BaseSchemaType>;
export type IDeviceBehaviourInstanceUpdate = IDeviceBehaviourInstanceInsert;
export type IDeviceBehaviourInstanceSelect = typeof deviceBehaviourInstances.$inferSelect;

import { deviceBehaviourInstanceLogs } from "./bean_device_behaviour_instance_log";
export type IDeviceBehaviourInstanceLogInsert = Omit<typeof deviceBehaviourInstanceLogs.$inferInsert, BaseSchemaType>;
export type IDeviceBehaviourInstanceLogUpdate = IDeviceBehaviourInstanceLogInsert;
export type IDeviceBehaviourInstanceLogSelect = typeof deviceBehaviourInstanceLogs.$inferSelect;

import { authChallenges } from "./bean_auth_challenge";
export type IAuthChallengeInsert = Omit<typeof authChallenges.$inferInsert, BaseSchemaType>;
export type IAuthChallengeUpdate = IAuthChallengeInsert;
export type IAuthChallengeSelect = typeof authChallenges.$inferSelect;

import { actions } from "./bean_action";
export type IActionInsert = Omit<typeof actions.$inferInsert, BaseSchemaType>;
export type IActionUpdate = IActionInsert;
export type IActionSelect = typeof actions.$inferSelect;

import { reports } from "./bean_report";
export type IReportInsert = Omit<typeof reports.$inferInsert, BaseSchemaType>;
export type IReportUpdate = IReportInsert;
export type IReportSelect = typeof reports.$inferSelect;

import { events } from "./bean_event";
export type IEventInsert = Omit<typeof events.$inferInsert, BaseSchemaType>;
export type IEventUpdate = IEventInsert;
export type IEventSelect = typeof events.$inferSelect;

import { eventInstanceLogs } from "./bean_event_instance_log";
export type IEventInstanceLogInsert = Omit<typeof eventInstanceLogs.$inferInsert, BaseSchemaType>;
export type IEventInstanceLogUpdate = IEventInstanceLogInsert;
export type IEventInstanceLogSelect = typeof eventInstanceLogs.$inferSelect;

import { eventInstances } from "./bean_event_instance";
export type IEventInstanceInsert = Omit<typeof eventInstances.$inferInsert, BaseSchemaType>;
export type IEventInstanceUpdate = IEventInstanceInsert;
export type IEventInstanceSelect = typeof eventInstances.$inferSelect;

import * as allTables from './beans_sqlite';
import { SQLiteTable } from "drizzle-orm/sqlite-core";

type TableTypes = {
  [K in keyof typeof allTables]: typeof allTables[K] extends SQLiteTable ? typeof allTables[K] : never
};

export type IAllTables = {
  [K in keyof TableTypes as typeof allTables[K] extends SQLiteTable ? K : never]: TableTypes[K]
};

export type IAllTablesSelect = {
  [K in keyof IAllTables]: IAllTables[K] extends { $inferSelect: any }
  ? IAllTables[K]['$inferSelect']
  : never
};

export type IAllTablesSelects = {
  [K in keyof IAllTables]: IAllTables[K] extends { $inferSelect: any }
  ? IAllTables[K]['$inferSelect'][]
  : never
};

export type IAllTablesInsert = {
  [K in keyof IAllTables]: IAllTables[K] extends { $inferInsert: any }
  ? IAllTables[K]['$inferInsert']
  : never
};

export const AllTables: IAllTables = Object.fromEntries(
  Object.entries(allTables).filter(
    ([_, value]) => value instanceof SQLiteTable
  )
) as IAllTables;

export const AllTablesColumns = Object.fromEntries(
  Object.entries(AllTables).map(([tableName, table]) => [
    tableName,
    (table as any)[Symbol.for('drizzle:Columns')]
  ])
)

export function removeSchemaBaseKeys<T extends object>(obj: T): Omit<T, BaseSchemaType> {
  const newObj = { ...obj };
  for (const key of schemaBaseKeys) {
    delete newObj[key as keyof T];
  }
  return newObj;
}