import React, { useContext, useState } from 'react';
import { IconPlusSolid, IconTrashSolid } from '../../components/icons';
import { useModal } from '../../hooks/use_modal';
import { ContextOrganization } from '../../providers/provider_organization';
import { ContextVigilClient } from '../../providers/provider_vigil_client';
import { useNavigate } from 'react-router-dom';
import { IDirectoryEvent, Permissions } from 'vigil-datamodel';
import { ModalEventCreate } from './func_event/modal_event_create';
import { EventsTable } from '../shared/events_table';
import { useTableData } from '../../hooks/use_table_data';
import { ContextRoles } from '../../providers/provider_roles';
import { TTActions } from 'tt-permissions';
import { ROUTES } from '../../router/routes';

interface ScreenHomeEventsProps { }

export const ScreenHomeEvents: React.FC<ScreenHomeEventsProps> = (props) => {
  const navigate = useNavigate()
  const organization = useContext(ContextOrganization);
  const contextRoles = useContext(ContextRoles);
  const vigil = useContext(ContextVigilClient);

  const { isOpen: isOpenModalCreate, toggle: toggleModalCreate } = useModal();
  const { isOpen: isOpenModalDelete, toggle: toggleModalDelete } = useModal();

  const [selectedEventsState, setSelectedEvents] = useState<IDirectoryEvent[]>([]);

  const { data: events, loading, error, filters, sorting, paging, handleFiltersChange, handleSortingChange, handlePagingChange, forceRefetch } = useTableData<IDirectoryEvent>({
    fetchData: async ({ filters, sorting, paging }) => {
      if (!organization.data) return;
      const response = await vigil.functions.directoryEvents({
        uuidOrganization: organization.data.uuid,
        filters: filters,
        ordering: { field: sorting.id as any, direction: sorting.direction },
        pagination: { page: paging.currentPage, count: paging.itemsPerPage }
      });
      return { items: response.items, totalPages: response.pagination.pages, totalItems: response.pagination.count };
    },
    defaultSorting: { id: 'uuid', direction: 'descending' },
    refreshInterval: 5000,
    dependencies: [organization.data],
  });

  function navigateEvent(selectedEvent: IDirectoryEvent) {
    navigate(ROUTES.ROUTE_HOME_EVENT.replace(':uuid', selectedEvent.uuid));
  }

  function deleteEvents(selectedEvents: IDirectoryEvent[]) {
    setSelectedEvents(selectedEvents);
    toggleModalDelete();
  }

  return (
    <div className='px-4 py-4 h-full w-full'>
      <ModalEventCreate isOpen={isOpenModalCreate} toggle={toggleModalCreate} onSubmit={forceRefetch} />
      {/* TODO: Add delete event */}
      {/* <ModalDeleteMany 
        isOpen={isOpenModalDelete}
        toggle={toggleModalDelete}
        type='events'
        data={selectedEventsState.map(event => ({ uuid: event.uuid, label: event.name }))}
        onSubmit={forceRefetch}      
      /> */}

      <EventsTable
        events={events}
        loading={loading}
        error={error}
        onEventClick={navigateEvent}
        onFiltersChange={handleFiltersChange}
        onSortingChange={handleSortingChange}
        onPagingChange={handlePagingChange}
        tablePagingSelected={paging}
        selectable={true}
        unSelectedActions={[
          {
            buttonChildren: <div className='flex justify-center items-center'><IconPlusSolid height='20px' className='mr-1' />add new event</div>,
            color: 'primary',
            disabled: !contextRoles.hasUserPermission(TTActions.C, Permissions.REvent),
            onClick: () => toggleModalCreate(),
          }
        ]}
        selectedActions={[
          {
            buttonChildren: <div className='flex justify-center items-center'><IconTrashSolid height='20px' />delete</div>,
            color: 'error',
            disabled: !contextRoles.hasUserPermission(TTActions.D, Permissions.REvent),
            onClick: (selectedItems) => deleteEvents(selectedItems)
          }
        ]}
        hoverActions={[
          {
            buttonChildren: <div className='flex justify-center items-center'><IconTrashSolid height='20px' />delete</div>,
            color: 'error',
            disabled: !contextRoles.hasUserPermission(TTActions.D, Permissions.REvent),
            onClick: (selectedItems) => deleteEvents(selectedItems)
          }
        ]}
      />
    </div>
  );
};