import React, { useContext, useState } from 'react';
import { EventInstanceLogType, IEventInstanceLogBeacon, IEventInstanceLogPanic, IEventInstanceLogSelect, IUserSelect } from 'vigil-datamodel';
import { ContextVigilClient } from '../../providers/provider_vigil_client';
import { Table } from '../../components/table/table';
import { TextFilter } from '../../components/table/table_filters/table_filter_text';
import { TTuuid } from 'tt-uuid';
import { DateFilter } from '../../components/table/table_filters/table_filter_date';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from '../../router/routes';
import { useTableData } from '../../hooks/use_table_data';
import { useCaller } from '../../hooks/use_caller';
import { ContextOrganization } from '../../providers/provider_organization';

interface ScreenHomeEventRawLogsProps { }

export const ScreenHomeEventRawLogs: React.FC<ScreenHomeEventRawLogsProps> = (props) => {
  const organization = useContext(ContextOrganization);
  const vigil = useContext(ContextVigilClient);
  const params = useParams();
  const uuidEvent = params['uuid'] || '';
  const navigate = useNavigate();

  const { data: eventInstanceLogs, loading, error, filters, sorting, paging, handleFiltersChange, handleSortingChange, handlePagingChange, forceRefetch } = useTableData<IEventInstanceLogSelect>({
    fetchData: async ({ filters, sorting, paging }) => {
      const response = await vigil.functions.eventInstanceLogs({
        uuidEvent,
        filters: filters,
        ordering: { field: sorting.id as any, direction: sorting.direction },
        pagination: { page: paging.currentPage, count: paging.itemsPerPage }
      });
      return { items: response.items, totalPages: response.pagination.pages, totalItems: response.pagination.count };
    },
    defaultSorting: { id: 'uuid', direction: 'descending' },
    refreshInterval: 5000,
    dependencies: [uuidEvent],
  });

  const { result: users, loading: loadingUsers, error: errorUsers } = useCaller<IUserSelect[]>({
    callback: async () => {
      if (!organization.data) throw new Error('Organization not found');
      const uniqueUserUuids = Array.from(new Set(eventInstanceLogs.map(log => {
        if (log.type === EventInstanceLogType.Beacon) {
          return (log.data.data as IEventInstanceLogBeacon).userUuid;
        }
        return '';
      })));
      return [];
      // TODO: FIX
      // return await vigil.functions.findManyLinkedToOrganization({ type: 'users', uuidOrganization: organization.data.uuid, filters: [{ id: 'uuid', query: 'inList', value: uniqueUserUuids }] });
    },
    dependencies: { normal: [eventInstanceLogs], background: [] },
  });

  function navigateDevice(uuid: string) {
    navigate(ROUTES.ROUTE_HOME_DEVICE.replace(':uuid', uuid));
  }

  function navigateBeacon(uuid: string) {
    navigate(ROUTES.ROUTE_HOME_BEACON.replace(':uuid', uuid));
  }

  function navigateUser(uuid: string) {
    navigate(ROUTES.ROUTE_HOME_USER.replace(':uuid', uuid));
  }

  function navigateGoogleMaps(lat: number, lng: number) {
    window.open(`https://www.google.com/maps?q=${lat},${lng}`, '_blank');
  }

  function ClickableText(props: { text: string, onClick: () => void }) {
    return <div className='hover:underline cursor-pointer text-blue-500' onClick={props.onClick}>{props.text}</div>;
  }

  return (
    <div className='py-2 h-full w-full'>
      <Table<IEventInstanceLogSelect>
        className='h-full flex flex-col drop-shadow'
        name='Raw Logs'
        data={eventInstanceLogs}
        loading={loading || loadingUsers}
        error={error || errorUsers}
        columns={[
          {
            id: 'type',
            header: 'Type',
            sortable: true,
            value: (item) => <div>{item.type}</div>,
          },
          {
            id: 'uuid',
            header: 'Date Created',
            sortable: true,
            value: (item) => {
              const decodedUuid = TTuuid.decodeCuuid(item.uuid);
              return <div>{decodedUuid.time.toLocaleDateString() + " " + decodedUuid.time.toLocaleTimeString()}</div>;
            },
          },
          {
            id: 'creator',
            header: 'Creator',
            sortable: true,
            value: (item) => {
              if (item.creator.type === 'server') return <div>Server</div>;
              return <ClickableText text={`Device: ${item.creator.uuidDevice}`} onClick={() => navigateDevice(item.uuid)} />;
            },
          },
          {
            id: 'data',
            header: 'Data',
            sortable: false,
            value: (item) => {
              if (item.type === EventInstanceLogType.Beacon) {
                const data = item.data.data as IEventInstanceLogBeacon;
                const user = users?.find(user => user.uuid === data.userUuid);
                return (
                  <div className='flex space-x-1'>
                    <ClickableText text={`Beacon: ${data.beaconName}`} onClick={() => navigateBeacon(data.beaconUuid)} />
                    <ClickableText text={`User: ${user?.firstName} ${user?.lastName}`} onClick={() => navigateUser(data.userUuid)} />
                  </div>
                );
              }
              if (item.type === EventInstanceLogType.Panic) {
                const data = item.data.data as IEventInstanceLogPanic;
                return (
                  <div className='flex space-x-1'>
                    <ClickableText text='User Location' onClick={() => navigateGoogleMaps(data.lat, data.lng)} />
                    <ClickableText text={`User: ${data.userFirstName} ${data.userLastName}`} onClick={() => navigateUser(data.userUuid)} />
                  </div>
                );
              }
              return <div>{JSON.stringify(item.data)}</div>;
            },
          }
        ]}
        filtersOptions={[
          new TextFilter('id', 'ID', 'contains'),
          new DateFilter('uuid', 'Date Created', 'between'),
          new TextFilter('creator.uuidDevice', 'Creator Device UUID', 'contains'),
        ]}
        filtersSelected={filters}
        onFiltersChange={handleFiltersChange}
        sortingOptions={null}
        sortingSelected={sorting}
        onSortingChange={handleSortingChange}
        pagingOptions={null}
        pagingSelected={paging}
        onPagingChange={handlePagingChange}
      />
    </div>
  );
};
