import { IBaseSyncable } from "tt-coms";
import { sqliteTable, text } from "drizzle-orm/sqlite-core";
import { customJsonColumn, schemaBase } from "./bean_base";
import { linkDeviceBehaviourDevices } from "./bean_link_device_behaviour_device";
import { linkDeviceBehaviourSites } from "./bean_link_device_behaviour_site";
import { relations } from "drizzle-orm";
import { deviceBehaviourInstances } from "./bean_device_behaviour_instance";
import { organizations } from "./bean_organization";

export interface IDeviceBehaviour extends IBaseSyncable {
  uuidOrganization: string;
  name: string;
  id: string;
  parameterValues: Record<string, string>;
}

export const deviceBehaviours = sqliteTable('device_behaviours', {
  ...schemaBase(),
  uuidOrganization: text('uuid_organization').notNull().references(() => organizations.uuid),
  name: text('name').notNull(),
  id: text('id').notNull(),
  parameterValues: customJsonColumn<Record<string, string>>('parameter_values').notNull(),
});

export const deviceBehavioursRelations = relations(deviceBehaviours, ({ one, many }) => ({
  organization: one(organizations, {
    fields: [deviceBehaviours.uuidOrganization],
    references: [organizations.uuid],
  }),
  deviceBehavioursToDevices: many(linkDeviceBehaviourDevices),
  deviceBehavioursToSites: many(linkDeviceBehaviourSites),
  deviceBehaviourInstances: many(deviceBehaviourInstances),
}));
