import { useContext, useState } from "react"
import { IconArrowRightRectangle, IconChevronDownOutline, IconHome, IconUser } from "../icons"
import { ContextUser } from "../../providers/provider_user"
import { useNavigate } from "react-router-dom"
import useBreakpoints from "../../hooks/use_breakpoints"
import { ContextSession } from "../../providers/provider_session"
import { DashActivityLogType } from "vigil-datamodel"
import { Preferences } from "../../preference_keys"
import { ROUTES } from "../../router/routes"

interface NavbarUserProps {
  onClickMenu?: () => void
}

export const NavbarUser: React.FC<NavbarUserProps> = (props) => {
  const breakpoints = useBreakpoints();
  const user = useContext(ContextUser);
  const session = useContext(ContextSession);
  const navigate = useNavigate();

  const [stateOpen, setOpen] = useState(false);

  async function actionSignOut() {
    // Log event
    session?.logEvent({
      type: DashActivityLogType.UserLogout,
      data: { userUuid: user.data?.uuid ?? '' }
    });
    localStorage.removeItem(Preferences.TOKEN);
    localStorage.removeItem(Preferences.SELECTED_ORGANIZATION);
    await user.refresh(false);
  }

  return (
    <button className={`dropdown dropdown-end ${stateOpen ? 'dropdown-open' : ''}`}>
      <label className="btn btn-outline btn-neutral" onClick={() => setOpen(!stateOpen)}>
        <img src={`https://ui-avatars.com/api/?name=${user.data?.firstName[0]}&background=random`} className="w-6 h-6 rounded-full" alt="" />
        {breakpoints['MD'] && <span className="">{user.data?.firstName}</span>}
        <IconChevronDownOutline className='w-3 mr-1' />
      </label>
      <div className="mt-1 shadow dropdown-content z-[1] bg-base-100 rounded-box w-48 outline outline-1 outline-neutral-content">
        <ul className="menu">
          <li><a onClick={() => { navigate(ROUTES.ROUTE_HOME) }}> <IconHome className="w-6" /> Dashboard </a></li>
          <li><a onClick={() => { navigate(ROUTES.ROUTE_PROFILE) }}> <IconUser className="w-6" /> Profile </a></li>
          <hr className="my-1"></hr>
          <li><a className="text-error" onClick={actionSignOut}> <IconArrowRightRectangle className="w-6" /> Sign Out</a></li>
        </ul>
      </div>
    </button>
  )
}