import useBreakpoints from "../../hooks/use_breakpoints";
import { IconChevronDoubleLeftSolid, IconChevronLeftOutline, IconChevronRightSolid, IconChevronDoubleRightSolid } from "../icons";
import { Paging } from "./table_types";

interface TableHeaderProps {
  pagingOptions: null;
  pagingSelected: Paging;
  onPagingChange: (paging: Paging) => void;
}

export const TableFooter: React.FC<TableHeaderProps> = (props) => {
  const breakpoints = useBreakpoints();

  function onItemsPerPageChange(e: React.ChangeEvent<HTMLSelectElement>) {
    if (props.pagingSelected) {
      props.onPagingChange({
        ...props.pagingSelected,
        itemsPerPage: parseInt(e.target.value) as any
      })
    }
  }

  function previousPage() {
    if (props.pagingSelected.currentPage > 1) {
      props.onPagingChange({
        ...props.pagingSelected,
        currentPage: props.pagingSelected.currentPage - 1
      })
    }
  }

  function nextPage() {
    if (props.pagingSelected.currentPage < props.pagingSelected.totalPages) {
      props.onPagingChange({
        ...props.pagingSelected,
        currentPage: props.pagingSelected.currentPage + 1
      })
    }
  }

  function goToFirstPage() {
    props.onPagingChange({
      ...props.pagingSelected,
      currentPage: 1
    })
  }

  function goToLastPage() {
    props.onPagingChange({
      ...props.pagingSelected,
      currentPage: props.pagingSelected.totalPages
    })
  }

  return (
    <div className='w-full h-10 py-2 px-4 bg-base-200 rounded-b-xl flex items-center justify-between'>
        <>
          <div className='flex flex-grow items-center'>
            <div>
              {breakpoints['MD'] ? 'Rows per page:' : 'Rows:'}
              <select className="select-xs ml-2" value={props.pagingSelected.itemsPerPage} onChange={onItemsPerPageChange}>
                <option value='10'>10</option>
                <option value='20'>20</option>
                <option value='30'>30</option>
                <option value='40'>40</option>
                <option value='50'>50</option>
                <option value='100'>100</option>
              </select>
            </div>
          </div>
          <div className='flex items-center'>
            <button onClick={goToFirstPage} disabled={props.pagingSelected.currentPage === 1}>
              <IconChevronDoubleLeftSolid className={`h-5 ${props.pagingSelected.currentPage === 1 ? 'opacity-50' : ''}`} />
            </button>
            <button onClick={previousPage} disabled={props.pagingSelected.currentPage === 1}>
              <IconChevronLeftOutline className={`h-5 ${props.pagingSelected.currentPage === 1 ? 'opacity-50' : ''}`} />
            </button>
            <div className='mx-2'>
              {breakpoints['MD'] ? 'Page' : ''} {props.pagingSelected.currentPage} of {props.pagingSelected.totalPages} | {props.pagingSelected.totalItems} items
            </div>
            <button onClick={nextPage} disabled={props.pagingSelected.currentPage === props.pagingSelected.totalPages}>
              <IconChevronRightSolid className={`h-5 ${props.pagingSelected.currentPage === props.pagingSelected.totalPages ? 'opacity-50' : ''}`} />
            </button>
            <button onClick={goToLastPage} disabled={props.pagingSelected.currentPage === props.pagingSelected.totalPages}>
              <IconChevronDoubleRightSolid className={`h-5 ${props.pagingSelected.currentPage === props.pagingSelected.totalPages ? 'opacity-50' : ''}`} />
            </button>
          </div>
        </>
    </div>
  );
};