import React, { useContext, useEffect, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { ContextVigilClient } from '../../providers/provider_vigil_client';
import { Breadcrumbs } from '../../components/breadcrumbs';
import { ROUTES } from '../../router/routes';
import { FullPageLoader } from '../../components/full_page_loader';
import { StatusAlert } from '../../components/status_alert';
import { IDeviceBehaviourSelect } from 'vigil-datamodel';
import { ContextOrganization } from '../../providers/provider_organization';

interface ScreenHomeDeviceBehaviourProps { }

export const ScreenHomeDeviceBehaviour: React.FC<ScreenHomeDeviceBehaviourProps> = (props) => {
  const navigate = useNavigate();
  const organization = useContext(ContextOrganization);
  const vigil = useContext(ContextVigilClient);
  const params = useParams();
  const uuidBehaviour = params['uuid'] || '';

  const [stateError, setError] = useState<string>('');
  const [stateBehaviourLoading, setBehaviourLoading] = useState(true);
  const [stateBehaviour, setBehaviour] = useState<IDeviceBehaviourSelect>();

  useEffect(() => { fetchBehaviour(); }, [uuidBehaviour]);

  async function fetchBehaviour() {
    try {
      if (!organization.data?.uuid) throw new Error('Organization not found');
      setBehaviourLoading(true);
      const behaviour = await vigil.functions.findOneDeviceBehaviour({ uuidOrganization: organization.data.uuid, uuid: uuidBehaviour });
      setBehaviour(behaviour);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setBehaviourLoading(false);
    }
  }

  function getSelectedId() {
    return location.pathname.split('/').pop() || '';
  }

  if (stateBehaviourLoading || !stateBehaviour) return <FullPageLoader />;
  if (stateError) return <div className='px-8 py-4 h-full w-full'><StatusAlert message={stateError} type='alert-error' /></div>;

  return (
    <div className='px-4 pb-2 w-full h-full'>
      <Breadcrumbs
        crumbs={[
          {
            id: 'behaviours',
            onClick: () => navigate(ROUTES.ROUTE_HOME_DEVICES),
            text: 'Device Behaviours'
          },
          {
            id: uuidBehaviour,
            onClick: () => { },
            text: stateBehaviour.name
          }
        ]}
      />

      <div className='w-full flex-grow'>
        <Outlet context={{ behaviourParent: stateBehaviour, fetchBehaviourParent: fetchBehaviour }} />
      </div>
    </div>
  )
};
