import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Table } from '../../components/table/table';
import { IDeviceStateLogSelect } from 'vigil-datamodel';
import { TTuuid } from 'tt-uuid';
import { ContextVigilClient } from '../../providers/provider_vigil_client';
import { DateFilter } from '../../components/table/table_filters/table_filter_date';
import { useTableData } from '../../hooks/use_table_data';

interface ScreenHomeDeviceLogsStateProps { }

export const ScreenHomeDeviceLogsState: React.FC<ScreenHomeDeviceLogsStateProps> = (props) => {
  const vigil = useContext(ContextVigilClient);
  const params = useParams();
  const uuidDevice = params['uuid'] || '';

  const { data, loading, error, filters, sorting, paging, handleFiltersChange, handleSortingChange, handlePagingChange } = useTableData<IDeviceStateLogSelect>({
    fetchData: async ({ filters, sorting, paging }) => {
      const response = await vigil.functions.deviceStateLogs({
        filters: [
          { id: 'uuidDevice', query: 'equals', value: uuidDevice },
          ...filters,
        ],
        ordering: { field: sorting.id as any, direction: sorting.direction },
        pagination: { page: paging.currentPage, count: paging.itemsPerPage }
      });
      return { items: response.items, totalPages: response.pagination.pages, totalItems: response.pagination.count };
    },
    defaultSorting: { id: 'uuid', direction: 'descending' },
    refreshInterval: 5000,
    dependencies: [uuidDevice]
  });

  return (
    <div className='flex flex-col h-full'>
      <Table<IDeviceStateLogSelect>
        className='h-full flex flex-col drop-shadow my-2'
        name='Device State Logs'
        data={data}
        selectable={false}
        loading={loading}
        error={error}
        columns={[
          {
            id: 'chargeLevel',
            header: 'Charge Level',
            sortable: true,
            value(item) {
              return <div> {item.chargeLevel}% </div>;
            },
          },
          {
            id: 'chargeState',
            header: 'Charge State',
            sortable: true,
            value(item) {
              return <div> {item.chargeState} </div>;
            },
          },
          {
            id: 'location',
            header: 'Location',
            sortable: false,
            value(item) {
              return <a className="text-blue-500 underline" target="_blank" rel="noopener noreferrer" href={`https://www.google.com/maps?q=${item.locationLat},${item.locationLng}`}>Location</a>
            },
          },
          {
            id: 'uuid',
            header: 'Date Created',
            sortable: true,
            value(item) {
              const decodedUuid = TTuuid.decodeCuuid(item.uuid);
              return <div>{decodedUuid.time.toLocaleDateString() + " " + decodedUuid.time.toLocaleTimeString()}</div>;
            },
          }
        ]}
        filtersOptions={[
          new DateFilter('uuid', 'Date Created', 'between'),
        ]}
        filtersSelected={filters}
        onFiltersChange={handleFiltersChange}

        sortingOptions={null}
        sortingSelected={sorting}
        onSortingChange={handleSortingChange}

        pagingOptions={null}
        pagingSelected={paging}
        onPagingChange={handlePagingChange}
      />
    </div>
  );
};
