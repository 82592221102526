import { useContext, useState } from "react";
import { InputButton } from "../../../components/input_button";
import { ContextVigilClient } from "../../../providers/provider_vigil_client";
import { Modal, ModalProps } from "../../../components/modal";
import { StatusAlert } from "../../../components/status_alert";
import { IReportSelect } from "vigil-datamodel";
import { ContextOrganization } from "../../../providers/provider_organization";

interface ModalReportUpdateProps extends ModalProps {
  report: IReportSelect;
  onSubmit?: () => Promise<void>;
}

export const ModalReportUpdate: React.FC<ModalReportUpdateProps> = (props) => {
  const organization = useContext(ContextOrganization);
  const vigil = useContext(ContextVigilClient);

  const [stateError, setError] = useState<string>('');
  const [stateSubmitLoading, setSubmitLoading] = useState(false);

  /* DB Functions */
  async function updateReport() {
    try {
      if (!organization.data) throw new Error('Organization not found');
      setError('');
      setSubmitLoading(true);
      // TODO: FIX
      // await vigil.functions.updateOneOnOrganization({
      //   type: 'reports',
      //   uuidOrganization: organization.data.uuid,
      //   uuid: props.report.uuid,
      //   data: props.report
      // });
      props.onSubmit && await props.onSubmit();
      props.toggle();
    } catch (error: any) {
      setError(error.message);
    } finally {
      setSubmitLoading(false);
    }
  }

  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle} closeOnBackgroundClick={false} className="w-128">
      <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2" onClick={() => props.toggle()}>✕</button>
      <h3 className="font-bold text-lg pb-2">Save Report</h3>
      <div>Are you sure you want to save your changes?</div>
      {stateError && <StatusAlert type='alert-error' message={stateError} />}
      <div className="flex justify-end pt-4">
        <InputButton text='Save' loading={stateSubmitLoading} disabled={false} type='btn-primary' onClick={async () => { await updateReport() }}></InputButton>
      </div>
    </Modal>
  )
}