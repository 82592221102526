import React, { useState, useEffect } from 'react';
import { InputBase, InputBaseProps } from './input_base';

export interface TimeState {
  hour: number;
  minute: number;
  period: 'AM' | 'PM';
}

interface InputTimeProps extends InputBaseProps {
  value: TimeState;
  onChange?: (newTime: TimeState) => void;
}

export const InputTime: React.FC<InputTimeProps> = (props) => {
  const [time, setTime] = useState(props.value);

  const handleHourChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setTime({ ...time, hour: parseInt(event.target.value) });
    if (props.onChange) props.onChange({ ...time, hour: parseInt(event.target.value) })
  };

  const handleMinuteChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setTime({ ...time, minute: parseInt(event.target.value) });
    if (props.onChange) props.onChange({ ...time, minute: parseInt(event.target.value) })
  };

  const handlePeriodChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setTime({ ...time, period: (event.target.value as any) });
    if (props.onChange) props.onChange({ ...time, period: (event.target.value as any) });
  };

  return (
    <InputBase {...props} >
      <div className={`p-2 inline-flex bg-base border-2 rounded-lg ${props.errors.length > 0 ? 'border-error' : 'border-neutral-content'}`}>
        {/* Hour select */}
        <select className="bg-transparent text-lg" value={props.value.hour} onChange={handleHourChange}>
          {[...Array(12)].map((_, i) => (
            <option key={i} value={i + 1}>{`${i + 1}`.padStart(2, '0')}</option>
          ))}
        </select>
        <span className="text-lg mr-1">:</span>
        {/* Minute select */}
        <select className="bg-transparent text-lg mr-1" value={props.value.minute} onChange={handleMinuteChange}>
          {[...Array(6)].map((_, i) => (
            <option key={i} value={i * 10}>{`${i * 10}`.padStart(2, '0')}</option>
          ))}
        </select>
        {/* Period select */}
        <select className="bg-transparent text-lg" value={props.value.period} onChange={handlePeriodChange}>
          <option value="AM">AM</option>
          <option value="PM">PM</option>
        </select>
      </div>
    </InputBase>
  );
};
